import {
  Firestore,
  Database,
  CertificationsDoc,
  CertificationsWhitelistDoc,
  NotificationsCollection,
  NotificationsDoc,
  UsersDoc,
  GetDocFromReference,
  RecordsDoc,
  RecordsWhitelistDoc,
  RecordFilesWhitelistDoc,
  RecordFilesDoc,
} from '@/firebase-exports'
import store from '@/store'

export async function createNotification(notification, attachmentType) {
  notification.createdAt = Firestore.serverTimestamp()
  notification.attachmentType = attachmentType
  notification.attachmentRef =
    attachmentType == 'certification'
      ? CertificationsDoc(notification.attachmentRef)
      : attachmentType == 'record'
      ? RecordsDoc(notification.attachmentRef)
      : undefined

  notification.issuerRef = UsersDoc(notification.issuerRef)
  notification.receiverRef = UsersDoc(notification.receiverRef)

  const docRef =
    attachmentType == 'certification'
      ? CertificationsWhitelistDoc(
          notification.attachmentRef.id,
          notification.receiverRef.id
        )
      : attachmentType == 'record'
      ? RecordsWhitelistDoc(
          notification.attachmentRef.id,
          notification.receiverRef.id
        )
      : undefined

  const docSnap = await Firestore.getDoc(docRef)
  const whitelistDoc = docSnap.data()

  const batch = Firestore.writeBatch(Database)

  if (!whitelistDoc) {
    batch.set(docRef, {
      userRef: notification.receiverRef,
      read: false,
    })
  }

  if (attachmentType == 'record' && notification.subAttachmentsRef) {
    for (const [index, element] of notification.subAttachmentsRef.entries()) {
      notification.subAttachmentsRef[index] = RecordFilesDoc(
        notification.attachmentRef.id,
        element
      )

      const docRef = RecordFilesWhitelistDoc(
        notification.attachmentRef.id,
        element,
        notification.receiverRef.id
      )

      const docSnap = await Firestore.getDoc(docRef)
      const whitelistDoc = docSnap.data()

      if (!whitelistDoc) {
        batch.set(docRef, {
          userRef: notification.receiverRef,
          read: false,
        })

        // Add to record whitelist the allowed file ids (sharedFiles array)
        batch.update(
          RecordsWhitelistDoc(
            notification.attachmentRef.id,
            notification.receiverRef.id
          ),
          {
            sharedFiles: Firestore.arrayUnion(
              notification.subAttachmentsRef[index]
            ),
          }
        )
      }
    }
  }

  batch.set(Firestore.doc(NotificationsCollection), notification)

  return await batch.commit()
}

export async function readNotification(notification) {
  const read = true
  const batch = Firestore.writeBatch(Database)

  const attachmentWhitelistDocRef =
    notification.attachmentType == 'certification'
      ? CertificationsWhitelistDoc(
          notification.attachment.id,
          store.state.user.id
        )
      : notification.attachmentType == 'record'
      ? RecordsWhitelistDoc(notification.attachment.id, store.state.user.id)
      : 'undefined'

  batch.update(NotificationsDoc(notification.id), { read })
  batch.update(attachmentWhitelistDocRef, { read })

  if (
    notification.attachmentType == 'record' &&
    notification.subAttachmentsRef
  ) {
    notification.subAttachmentsRef.forEach(async (recordFile) => {
      const subAttachmentWhitelistDocRef = RecordFilesWhitelistDoc(
        notification.attachment.id,
        recordFile.id,
        store.state.user.id
      )
      batch.update(subAttachmentWhitelistDocRef, { read })
    })
  }

  return await batch.commit()
}

export async function listNotificationsSubscription(userId, filter) {
  var userfield
  var vuexSetCommit
  if (filter === 'received') {
    userfield = 'receiverRef'
    vuexSetCommit = 'setReceivedNotification'
  } else if (filter === 'issued') {
    userfield = 'issuerRef'
    vuexSetCommit = 'setIssuedNotification'
  } else {
    throw new Error('notification filter param invalid')
  }
  const q = Firestore.query(
    NotificationsCollection,
    Firestore.where(userfield, '==', UsersDoc(userId)),
    Firestore.orderBy('read', 'asc'),
    Firestore.orderBy('createdAt', 'desc')
  )

  var notificationsUnsubscribe = Firestore.onSnapshot(
    q,
    async (querySnapshot) => {
      const response = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          document.createdAt = new Date(document.createdAt.toDate())
          document.attachment = await GetDocFromReference(
            document.attachmentRef
          )
          document.issuer = await GetDocFromReference(document.issuerRef)
          document.receiver = await GetDocFromReference(document.receiverRef)
          return { id: doc.id, ...document }
        })
      )
      store.commit(vuexSetCommit, response)
    },
    (error) => {
      throw error
    }
  )
  return notificationsUnsubscribe
}
