<template>
  <v-fade-transition mode="out-in">
    <v-card
      flat
      max-width="450"
      class="mx-auto pa-3"
      v-if="history && initialDelay"
    >
      <v-list-item-title v-if="showTitle" class="primary--text text-h5 mb-5">
        {{ $t('historyDetail') }}
      </v-list-item-title>
      <template v-if="!history.length">{{ $t('historyEmpty') }}</template>
      <v-timeline v-else dense align-top>
        <v-timeline-item
          color="primary"
          :icon="certificationHistoryIcon[item.actionType]"
          icon-color="secondary"
          fill-dot
          large
          v-for="item in history"
          :key="item.index"
        >
          <template v-slot:icon>
            <template v-if="item.user && item.user.avatarURL">
              <AppAvatar
                :userAvatar="item.user.avatarURL"
                :userName="item.customName"
                :userRole="item.user.roleRef.id"
                :userCollegiateNumber="item.user.collegiateNumber"
                avatarSize="52"
                iconSize="24"
              />

              <v-avatar
                color="secondary"
                size="20"
                style="margin-left: -35px; margin-top: 45px; position: absolute"
              >
                <v-icon style="font-size: 15px" color="primary">
                  {{ certificationHistoryIcon[item.actionType] }}
                </v-icon>
              </v-avatar>
            </template>
          </template>
          <v-card color="primary">
            <v-card-title>
              <h6 style="color: white" v-if="isMobile">
                {{ $t(`history.${item.actionType}`) }}
              </h6>
              <h5 style="color: white" v-else>
                {{ $t(`history.${item.actionType}`) }}
              </h5>
              <v-spacer></v-spacer>
              <template
                v-if="item.blockchain && item.blockchain.registeredBlockchain"
              >
                <v-tooltip
                  bottom
                  color="secondary"
                  :open-on-click="false"
                  :open-on-focus="isMobile ? false : true"
                  :open-on-hover="isMobile ? false : true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <a
                        :href="getBlockchainTransactionLink(item)"
                        target="blank"
                        style="text-decoration: none; color: white"
                        ><v-icon dark> mdi-link-lock </v-icon></a
                      >
                    </v-btn>
                  </template>
                  <span>{{ $t('viewOnBlockchain') }}</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  color="secondary"
                  :open-on-click="false"
                  :open-on-focus="isMobile ? false : true"
                  :open-on-hover="isMobile ? false : true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        $router.push({
                          name: 'BlockchainVerifierView',
                          params: {
                            actionType: item.blockchain.data.actionType,
                            createdAt: item.blockchain.data.createdAt,
                            creatorId: item.blockchain.data.creatorId,
                            documentHash: item.blockchain.data.documentHash,
                            receiverId: item.blockchain.data.receiverId,
                            secret: item.blockchain.data.secret,
                          },
                        })
                      "
                    >
                      <v-icon dark> mdi-pound </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('hashVerify') }}</span>
                </v-tooltip>
              </template>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-list dense>
                <template v-for="historyEntry in historyEntryItems">
                  <v-list-item
                    v-if="historyEntry.value(item)"
                    :key="historyEntry.title"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary"> {{ historyEntry.icon }} </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ historyEntry.value(item) }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-fade-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTransactionLink } from '@/utils/blockchain-utils'

export default {
  props: {
    history: Array,
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initialDelay: false,
      certificationHistoryIcon: {
        created: 'mdi-plus',
        notificationSent: 'mdi-email-fast-outline',
        notificationRead: 'mdi-check-all',
      },
      historyEntryItems: [
        {
          icon: 'mdi-card-account-details-outline',
          value: this.getHistoryUserId,
        },
        {
          icon: 'mdi-office-building-outline',
          value: this.getHistoryUserAssociation,
        },
        {
          icon: 'mdi-account',
          value: this.getHistoryUserName,
        },
        {
          icon: 'mdi-alarm',
          value: this.getHistoryDate,
        },
      ],
    }
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.initialDelay = true
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  methods: {
    getHistoryUserId(item) {
      if (!item.user) return

      return item.user.collegiateNumber
        ? this.$t('collegiateHistory', [item.user.collegiateNumber])
        : item.user.idNumber
    },
    getHistoryUserAssociation(item) {
      if (!item.user || !item.user.association) return
      else return item.user.association.name
    },
    getHistoryUserName(item) {
      if (!item.user) return
      else return item.user.name
    },
    getHistoryDate(item) {
      return this.$d(item.createdAt, 'i18nDateAndHourSeconds')
    },
    getBlockchainTransactionLink(item) {
      return getTransactionLink(
        item.blockchain.chainId,
        item.blockchain.transactionHash
      )
    },
  },
}
</script>

<style>
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
</style>
