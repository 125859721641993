export default function (userRole) {
  const icons = {
    group: 'mdi-account-group',
    admin: 'mdi-crown',
    lawyer: 'mdi-scale-balance',
    solicitor: 'mdi-gavel',
    associationSolicitor: 'mdi-office-building',
    association: 'mdi-office-building',
    client: 'mdi-account',
  }
  return icons[userRole]
}
