<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="9"
        md="7"
        lg="5"
        xl="4"
        align="center"
        justify="center"
      >
        <v-card outlined color="transparent">
          <v-form ref="loginForm">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" v-if="!isMobile">
                  <v-img
                    class="mx-auto"
                    src="@/assets/login-logo.png"
                    max-width="300"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="username"
                    :disabled="loading"
                    :rules="userRules"
                    :label="$t('username')"
                    :dark="isMobile"
                  />
                </v-col>
                <v-col cols="12" v-if="!isResetPassword">
                  <v-text-field
                    v-model="password"
                    :disabled="loading"
                    :rules="passwordRules"
                    :label="$t('password')"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="on"
                    :append-icon="passwordIcons[showPassword]"
                    @click:append="showPassword = !showPassword"
                    :dark="isMobile"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    :class="
                      isMobile
                        ? 'secondary primary--text'
                        : 'primary secondary--text'
                    "
                    block
                    @click="isResetPassword ? userResetPassword() : userLogin()"
                    :dark="isMobile"
                  >
                    {{ $t(isResetPassword ? 'resetPassword' : 'login') }}
                    <template v-slot:loader>
                      <v-progress-circular indeterminate size="20" width="2" />
                      <span class="ml-2">
                        {{
                          $t(
                            isResetPassword ? 'resettingPassword' : 'loggingIn'
                          )
                        }}
                      </span>
                    </template>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <span
                    @click="changeIsResetPassword"
                    :class="loading ? 'grey--text' : 'secondary--text'"
                    :style="{
                      cursor: loading ? 'default' : 'pointer',
                    }"
                  >
                    {{ $t(isResetPassword ? 'login' : 'forgotPassword') }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from '@/firebase-exports'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      isResetPassword: false,
      username: '',
      password: '',
      showPassword: false,
      userRules: [rules.required, rules.email],
      passwordRules: [rules.required],
      passwordIcons: { true: 'mdi-eye', false: 'mdi-eye-off' },
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async userLogin() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true

      try {
        await Auth.signInWithEmailAndPassword(
          Auth.getAuth(),
          this.username,
          this.password
        )
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })
        this.loading = false
      }
    },
    async userResetPassword() {
      if (!this.$refs.loginForm.validate()) return

      this.loading = true
      this.password = ''

      try {
        const authData = Auth.getAuth()
        authData.languageCode = this.$i18n.locale

        await Auth.sendPasswordResetEmail(authData, this.username)
        this.loading = false
        this.isResetPassword = false
        const message = this.$t('passwordResetSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })
        this.loading = false
      }
    },
    changeIsResetPassword() {
      if (this.loading) return
      this.$refs.loginForm.resetValidation()
      this.isResetPassword = !this.isResetPassword
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
