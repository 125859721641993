<template>
  <v-container>
    <v-form ref="hashForm">
      <v-row align="center" justify="center">
        <h1>{{ $t('blockchainHashVerify') }}</h1>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="blockchainData.actionType"
            :items="[
              { value: 'created', text: `${$t('history.created')} (created)` },
              {
                value: 'notificationSent',
                text: `${$t('history.notificationSent')} (notificationSent)`,
              },
              {
                value: 'notificationRead',
                text: `${$t('history.notificationRead')} (notificationRead)`,
              },
            ]"
            item-value="value"
            item-text="text"
            prepend-icon="mdi-call-merge"
            :label="$t('actionType')"
            :disabled="disabled"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="blockchainData.createdAt"
            type="Number"
            min="1"
            prepend-icon="mdi-clock-time-eight-outline"
            :label="$t('createdAt')"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="blockchainData.creatorId"
            prepend-icon="mdi-account-arrow-right-outline"
            :label="$t('creatorId')"
            :disabled="disabled"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="blockchainData.receiverId"
            prepend-icon="mdi-account-arrow-left-outline"
            :label="$t('receiverId')"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="blockchainData.documentHash"
            prepend-icon="mdi-file-sign"
            :label="$t('documentHash')"
            :disabled="disabled"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="blockchainData.secret"
            prepend-icon="mdi-ab-testing"
            :label="$t('secret')"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center" v-if="blockchainHash">
          <p>
            {{ $t('blockchainHash') }} →
            <span style="font-size: 20px; word-wrap: break-word"
              ><strong>{{ blockchainHash }}</strong></span
            >
            <v-btn
              :disabled="copiedFlag"
              @click="copyClipboard(blockchainHash)"
              icon
            >
              <v-icon>
                {{ copiedFlag ? 'mdi-check' : 'mdi-content-copy' }}
              </v-icon>
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" align="center" justify="center" v-if="disabled">
          <p>
            <PrintHashInfo :hashDetails="$route.params" />
            <br />
          </p>
        </v-col>
      </v-row>
    </v-form>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="secondary"> $expand </v-icon>
          </template>
          <b> {{ $t('hashFaq.question1') }} </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ $t('hashFaq.question1_par1') }}
          <br /><br />
          {{ $t('hashFaq.question1_par2') }}
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="secondary"> $expand </v-icon>
          </template>
          <b> {{ $t('hashFaq.question2') }} </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ $t('hashFaq.question2_par1') }}
          <br /><br />
          {{ $t('hashFaq.question2_par2') }}
          <br /><br />
          <v-img
            class="mx-auto"
            src="@/assets/txdetail-hash.png"
            max-width="100%"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon color="secondary"> $expand </v-icon>
          </template>
          <b> {{ $t('hashFaq.question3') }} </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ $t('hashFaq.question3_par1') }}
          <br /><br />
          {{ $t('hashFaq.question3_par2') }}
          <ul>
            <li>{{ $t('hashFaq.question3_par2_actionType') }}</li>
            <li>{{ $t('hashFaq.question3_par2_createdAt') }}</li>
            <li>{{ $t('hashFaq.question3_par2_creatorId') }}</li>
            <li>{{ $t('hashFaq.question3_par2_documentHash') }}</li>
            <li>{{ $t('hashFaq.question3_par2_receiverId') }}</li>
            <li>{{ $t('hashFaq.question3_par2_secret') }}</li>
          </ul>
          <code style="word-wrap: break-word">
            { <br />
            actionType: "created", <br />
            createdAt: 1662540584411, <br />
            creatorId: "ABOG-123", <br />
            documentHash: "4e6bea832a066335b2d930be66ccadf3", <br />
            receiverId: "98765432M", <br />
            secret: "bb509ae7-02f7-4fac-b0b4-a59ba00334d3", <br />
            }
          </code>
          <br /><br />
          {{ $t('hashFaq.question3_par3') }}
          <a
            href="https://www.npmjs.com/package/json-canonicalize"
            target="blank"
            >json-canonicalize</a
          >:
          <br />
          <code style="word-wrap: break-word">
            {"actionType":"created","createdAt":1662540584411,"creatorId":"ABOG-123","documentHash":"4e6bea832a066335b2d930be66ccadf3","receiverId":"98765432M","secret":"bb509ae7-02f7-4fac-b0b4-a59ba00334d3"}
          </code>
          <br /><br />
          {{ $t('hashFaq.question3_par4') }}
          <br />
          <code style="word-wrap: break-word">
            ee30d98b975697a13631b26d0876c26f397888214570f5a3eca73df0cc29ef87
          </code>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { generateHash } from '@/utils/generate-hash'
import { canonicalize } from 'json-canonicalize'
import { mapMutations } from 'vuex'
import PrintHashInfo from './PrintHashInfo.vue'

export default {
  async mounted() {
    if (Object.keys(this.$route.params).length === 0) this.disabled = false
    else
      Object.entries(this.$route.params).forEach((entry) => {
        const [key, value] = entry
        this.blockchainData[key] = value
      })
  },
  watch: {
    blockchainData: {
      handler: function () {
        return this.generateBlockchainHash()
      },
      deep: true,
    },
  },
  data() {
    return {
      disabled: true,
      copiedFlag: false,
      blockchainHash: '',
      blockchainData: {
        actionType: '',
        createdAt: '',
        creatorId: '',
        documentHash: '',
        receiverId: '',
        secret: '',
      },
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    generateBlockchainHash() {
      var blockchainObject = {
        actionType: this.blockchainData.actionType,
        createdAt: parseInt(this.blockchainData.createdAt),
        creatorId: this.blockchainData.creatorId,
        documentHash: this.blockchainData.documentHash,
        receiverId: this.blockchainData.receiverId,
        secret: this.blockchainData.secret,
      }
      this.blockchainHash = generateHash(canonicalize(blockchainObject))
    },
    async copyClipboard(value) {
      await navigator.clipboard.writeText(value)
      this.setSnackbar({
        position: 'top',
        color: 'green',
        message: `${this.$t('blockchainHash')} ${this.$t(
          'copiedClipboard'
        ).toLowerCase()}`,
      })
      this.copiedFlag = true
      setTimeout(() => {
        this.copiedFlag = false
      }, 5000)
    },
  },
  components: { PrintHashInfo },
}
</script>

<style lang="scss" scoped></style>
