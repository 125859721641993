<template>
  <v-row align="center" justify="center">
    <v-col cols="12" :lg="6">
      <v-card>
        <v-list-item>
          <v-list-item-title>
            <div class="overline">
              {{ $t('clientInformation') }}
            </div>
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">
              {{ clientName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ clientIdNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-icon>
            <AppAvatar
              :userName="clientName"
              :userAvatar="clientAvatar"
              :userRole="clientRole"
              :userCollegiateNumber="clientCollegiateNumber"
              avatarSize="40"
              iconSize="24"
            />
          </v-list-item-icon>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    clientName: String,
    clientAvatar: String,
    clientIdNumber: String,
    clientRole: String,
    clientCollegiateNumber: String,
  },
}
</script>
