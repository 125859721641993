import {
  Firestore,
  RecordSubjectTypesCollection,
  UsersRecordSubjectTypesCollection,
  UsersRecordSubjectTypesDoc,
  RecordJurisdictionTypesCollection,
  RecordFileTypesCollection,
  UsersRecordFileTypesCollection,
  UsersRecordFileTypesDoc,
} from '@/firebase-exports'
import store from '@/store'

export async function createCustomRecordSubjectType(customRecordSubjectType) {
  return await Firestore.addDoc(
    UsersRecordSubjectTypesCollection(store.state.user.id),
    customRecordSubjectType
  )
}

export async function listRecordSubjectTypes() {
  const collections = [
    RecordSubjectTypesCollection,
    UsersRecordSubjectTypesCollection(store.state.user.id),
  ]
  var response = await Promise.all(
    collections.map(async (collection) => {
      const querySnapshot = await Firestore.getDocs(collection)
      return querySnapshot.docs.map((doc) => doc.data().name)
    })
  )
  return [...response[0], ...response[1]].sort((a, b) => a.localeCompare(b))
}

export async function listUserRecordSubjectTypes() {
  const q = Firestore.query(
    UsersRecordSubjectTypesCollection(store.state.user.id),
    Firestore.orderBy('name', 'asc')
  )
  const querySnapshotCustom = await Firestore.getDocs(q)
  return querySnapshotCustom.docs.map((doc) => {
    const document = doc.data()
    return { id: doc.id, ...document }
  })
}

export async function updateCustomRecordSubjectType(id, data) {
  return await Firestore.updateDoc(
    UsersRecordSubjectTypesDoc(store.state.user.id, id),
    data
  )
}

export async function deleteCustomRecordSubjectType(id) {
  return await Firestore.deleteDoc(
    UsersRecordSubjectTypesDoc(store.state.user.id, id)
  )
}

export async function listRecordJurisdictionTypes() {
  const q = Firestore.query(
    RecordJurisdictionTypesCollection,
    Firestore.orderBy('name', 'asc')
  )
  const querySnapshot = await Firestore.getDocs(q)
  return querySnapshot.docs.map((doc) => doc.data().name)
}

export async function createCustomRecordFileType(customRecordFileType) {
  return await Firestore.addDoc(
    UsersRecordFileTypesCollection(store.state.user.id),
    customRecordFileType
  )
}

export async function listRecordFileTypes() {
  const collections = [
    RecordFileTypesCollection,
    UsersRecordFileTypesCollection(store.state.user.id),
  ]
  var response = await Promise.all(
    collections.map(async (collection) => {
      const querySnapshot = await Firestore.getDocs(collection)
      return querySnapshot.docs.map((doc) => doc.data().name)
    })
  )
  return [...response[0], ...response[1]].sort((a, b) => a.localeCompare(b))
}

export async function listUserRecordFileTypes() {
  const q = Firestore.query(
    UsersRecordFileTypesCollection(store.state.user.id),
    Firestore.orderBy('name', 'asc')
  )
  const querySnapshotCustom = await Firestore.getDocs(q)
  return querySnapshotCustom.docs.map((doc) => {
    const document = doc.data()
    return { id: doc.id, ...document }
  })
}

export async function updateCustomRecordFileType(id, data) {
  return await Firestore.updateDoc(
    UsersRecordFileTypesDoc(store.state.user.id, id),
    data
  )
}

export async function deleteCustomRecordFileType(id) {
  return await Firestore.deleteDoc(
    UsersRecordFileTypesDoc(store.state.user.id, id)
  )
}
