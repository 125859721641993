import routes from '@/router/routes'

export default function () {
  var navPages = {
    procedures: {
      title: 'procedures',
      icon: 'mdi-file-document-outline',
      disabled: true,
      available: false,
      subPages: [],
    },
    certifications: {
      title: 'certifications',
      icon: 'mdi-certificate-outline',
      disabled: true,
      available: false,
      subPages: [],
    },
    records: {
      title: 'records',
      icon: 'mdi-folder-multiple',
      disabled: true,
      available: false,
      subPages: [],
    },
    contacts: undefined,
    users: {
      title: 'users',
      icon: 'mdi-account-outline',
      disabled: true,
      available: false,
      subPages: [],
    },
    receivedNotifications: undefined,
    profile: {
      title: 'profile',
      icon: 'mdi-account-cog-outline',
      disabled: true,
      available: false,
      subPages: [],
    },
  }

  routes.forEach((routeInfo) => {
    const userCanAccess = routeInfo.meta.userCanAccess
    var navigationConfig = []
    if (routeInfo.name === 'NotificationListView') {
      const filters = [{ filter: 'issued' }, { filter: 'received' }]
      for (const filter of filters) {
        navigationConfig = navigationConfig.concat(
          routeInfo.meta.navigationConfig(filter)
        )
      }
    } else if (routeInfo.name === 'ProcedureListView') {
      const filters = [{ filter: 'my' }, { filter: 'assigned' }]
      for (const filter of filters) {
        navigationConfig = navigationConfig.concat(
          routeInfo.meta.navigationConfig(filter)
        )
      }
    } else {
      navigationConfig = routeInfo.meta.navigationConfig()
    }
    navigationConfig.forEach((navItem) => {
      var valueToPush = {
        title: navItem.title,
        icon: navItem.icon,
        path: navItem.path || routeInfo.path,
        routerData: navItem.routerData || { name: routeInfo.name },
        disabled: false,
        includeBadge: !!navItem.includeBadge,
        includeTitle: !!navItem.includeTitle,
      }
      valueToPush.available = userCanAccess(valueToPush.routerData.params)

      if (!navItem.parent) navPages[valueToPush.title] = valueToPush
      else {
        if (valueToPush.available) navPages[navItem.parent].available = true
        navPages[navItem.parent].subPages.push(valueToPush)
      }
    })
  })
  Object.keys(navPages).forEach((k) => !navPages[k] && delete navPages[k])
  return navPages
}
