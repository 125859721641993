var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":7}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(_vm.$t('selectClientTitle'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('selectClientSubtitle'))+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"headline mb-1"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":_vm.$t('searchClient'),"single-line":"","hide-details":"","disabled":_vm.loading},on:{"input":function($event){return _vm.$emit('update:recordClient', '')},"click:clear":function($event){return _vm.$emit('update:recordClient', '')}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('AppSkeleton',{attrs:{"condition":_vm.loading,"type":"createRecordClientList"},scopedSlots:_vm._u([{key:"skeleton-content",fn:function(){return [_c('v-list',{staticClass:"overflow-y-auto",attrs:{"flat":"","max-height":"272"}},[_c('v-list-item-group',{attrs:{"color":"secondary"}},[(_vm.search && _vm.search.length !== 0)?_c('v-list-item',{class:_vm.recordClient === _vm.search ? 'secondary' : '',on:{"click":function($event){return _vm.$emit(
                      'update:recordClient',
                      _vm.search === _vm.recordClient ? '' : _vm.search
                    )}}},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"secondary--text"},[_vm._v(" mdi-account-outline ")])],1)],1),_c('v-list-item-content',{class:_vm.recordClient === _vm.search ? 'white--text' : ''},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('sentToUser'))+" ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)],1):_vm._e(),(!_vm.search)?_c('v-list-item',{class:_vm.recordClient === _vm.$store.state.user.idNumber
                      ? 'secondary'
                      : '',on:{"click":function($event){return _vm.$emit(
                      'update:recordClient',
                      _vm.$store.state.user.idNumber === _vm.recordClient
                        ? ''
                        : _vm.$store.state.user.idNumber
                    )}}},[_c('v-list-item-icon',{staticClass:"my-3 mr-4"},[_c('AppAvatar',{attrs:{"userAvatar":_vm.$store.state.user.avatarURL,"userName":_vm.$store.state.user.name,"userRole":_vm.$store.state.user.roleRef.id,"userCollegiateNumber":_vm.$store.state.user.collegiateNumber,"avatarColor":"primary","initialsColor":"secondary","avatarSize":"40","iconSize":"24"}})],1),_c('v-list-item-content',{class:_vm.recordClient === _vm.$store.state.user.idNumber
                        ? 'primary--text'
                        : ''},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.user.name)+" ("+_vm._s(_vm.$t('me'))+") ")]),_c('v-list-item-title',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$store.state.user.idNumber)+" ")])],1)],1):_vm._e(),_vm._l((_vm.filteredContacts),function(item,i){return _c('v-list-item',{key:i,class:_vm.recordClient === item.user.idNumber ? 'primary' : '',on:{"click":function($event){return _vm.$emit(
                      'update:recordClient',
                      item.user.idNumber === _vm.recordClient
                        ? ''
                        : item.user.idNumber
                    )}}},[_c('v-list-item-icon',{staticClass:"my-3 mr-4"},[_c('AppAvatar',{attrs:{"userAvatar":item.user.avatarURL,"userName":item.customName,"userRole":item.user.roleRef.id,"userCollegiateNumber":item.user.collegiateNumber,"avatarSize":"40","iconSize":"24"}})],1),_c('v-list-item-content',{class:_vm.recordClient === item.user.idNumber ? 'white--text' : ''},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.customName)+" ")]),_c('v-list-item-title',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(item.user.idNumber)+" ")])],1)],1)})],2)],1)]},proxy:true}])}),(!_vm.filteredContacts.length && !_vm.search && !_vm.loading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('noContactsAssigned'))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }