<template>
  <i18n
    v-if="wrongVersion"
    path="reloadAdvice"
    tag="div"
    class="pa-4 yellow black--text"
  >
    <a @click="reload" style="text-decoration: underline">
      <b>{{ $t('reloadAdviceClickHere') }}</b>
    </a>
  </i18n>
</template>

<script>
import { mapState } from 'vuex'
import { getAppVersionSubscription } from '@/services/metadata-service'

export default {
  mounted() {
    this.unsubscribeVersion = getAppVersionSubscription()
  },
  destroyed() {
    this.unsubscribeVersion()
  },
  methods: {
    unsubscribeVersion() {},
    reload() {
      window.location.reload()
    },
  },
  computed: {
    ...mapState(['localAppVersion', 'remoteAppVersion']),
    wrongVersion() {
      return (
        !!this.remoteAppVersion &&
        this.localAppVersion !== this.remoteAppVersion
      )
    },
  },
}
</script>
