const netwoks = {
  1: {
    name: 'Ethereum Mainnet',
    scanURI: 'https://etherscan.io',
  },
  4: {
    name: 'Ethereum Rinkeby',
    scanURI: 'https://rinkeby.etherscan.io',
  },
  5: {
    name: 'Ethereum Goerli',
    scanURI: 'https://goerli.etherscan.io',
  },
  137: {
    name: 'Polygon Mainnet',
    scanURI: 'https://polygonscan.com',
  },
  80001: {
    name: 'Polygon Mumbai',
    scanURI: 'https://mumbai.polygonscan.com',
  },
  11155111: {
    name: 'Ethereum Sepolia',
    scanURI: 'https://sepolia.etherscan.io',
  },
}

export function getNetworkName(chainId) {
  return netwoks[chainId].name
}

export function getContractLink(chainId, contract) {
  return netwoks[chainId].scanURI + '/address/' + contract
}

export function getTransactionLink(chainId, transaction) {
  return netwoks[chainId].scanURI + '/tx/' + transaction
}

export function getBlockLink(chainId, blockNumber) {
  return netwoks[chainId].scanURI + '/block/' + blockNumber
}
