var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppSkeleton',{attrs:{"condition":!_vm.fileRefs,"type":"recordFiles"},scopedSlots:_vm._u([{key:"skeleton-content",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items-per-page":!_vm.isMobile ? 10 : _vm.visible * 5,"headers":_vm.showUsers ? _vm.headers : _vm.headersNoShared,"items":_vm.fileRefs,"search":_vm.search,"single-select":false,"item-key":"id","show-select":_vm.isCreator,"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
          },"hide-default-footer":_vm.isMobile},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.selected.length > 0)?_c('div',[_c('NotificationCreate',{attrs:{"attachmentAsset":_vm.record,"subAttachmentAssets":_vm.selected,"attachmentType":"record"},on:{"notificationCreated":_vm.emitEvent}})],1):_vm._e(),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('navigationPage.recordFiles'))+" ")]),(!_vm.isMobile)?[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.isCreator)?_c('RecordFileCreate',{attrs:{"client":_vm.client,"record":_vm.record},on:{"record-file-created":_vm.emitEvent}}):_vm._e()]:_vm._e()],2),(_vm.isMobile)?[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isCreator)?_c('RecordFileCreate',{attrs:{"client":_vm.client,"record":_vm.record},on:{"record-file-created":_vm.emitEvent}}):_vm._e()]:_vm._e()]},proxy:true},{key:"item",fn:function(ref){
          var recordFile = ref.item;
          var mobileVersion = ref.isMobile;
return [(!mobileVersion)?_c('tr',{style:(_vm.selectedItem.id === recordFile.id &&
                ' background-color: aliceblue'),on:{"click":function($event){return _vm.selectRow(recordFile)}}},[(_vm.isCreator)?_c('td',[_c('v-simple-checkbox',{attrs:{"value":_vm.selected.includes(recordFile),"ripple":false},on:{"click":function($event){return _vm.clickChecbox(recordFile)}}})],1):_vm._e(),_c('td',[_c('span',[_vm._v(" "+_vm._s(recordFile.name)+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(recordFile.type)+" ")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(recordFile.description)+" ")])]),(_vm.showUsers)?_c('td',[_c('v-row',{attrs:{"justify":"start","align":"start"}},[_vm._l((recordFile.whitelist.slice(0, 3)),function(item){return _c('span',{key:item.user.id,staticClass:"ml-1"},[_c('AppAvatar',{attrs:{"userAvatar":item.user.avatarURL,"userName":item.user.name,"avatarSize":"40","iconSize":"24"}})],1)}),(recordFile.whitelist.length > 3)?_c('span',{staticClass:"ml-1"},[_c('AppAvatar',{attrs:{"userName":_vm.getMoreShared(recordFile.whitelist, 3),"avatarSize":"40","iconSize":"24","avatarColor":"primary","initialsColor":"white"}})],1):_vm._e()],2)],1):_vm._e()]):_c('tr',[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-list-item',{style:(_vm.selectedItem.id === recordFile.id &&
                    ' background-color: aliceblue'),on:{"click":function($event){return _vm.selectRow(recordFile)}}},[(_vm.isCreator)?_c('v-simple-checkbox',{staticClass:"ml-3",attrs:{"value":_vm.selected.includes(recordFile),"ripple":false},on:{"click":function($event){return _vm.clickChecbox(recordFile)}}}):_vm._e(),_c('v-list-item-avatar',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"primary lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-file-document-outline ")])],1),_c('v-list-item-content',[(recordFile.name.length > 35)?_c('v-list-item-title',[_vm._v(" "+_vm._s(recordFile.name.substring(0, 32) + '...')+" ")]):_c('v-list-item-title',[_vm._v(" "+_vm._s(recordFile.name)+" ")]),_c('v-list-item-title',{staticClass:"primary--text lighten-3"},[_vm._v(" "+_vm._s(recordFile.type)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(recordFile.description)+" ")])],1)],1)],1)],1)]}},(_vm.isMobile)?{key:"footer",fn:function(){return [(_vm.visible * 5 <= _vm.fileRefs.length)?_c('v-list-item',[_c('v-btn',{attrs:{"block":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.visible++}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-chevron-down")]),_vm._v(" "+_vm._s(_vm.$t('showMore'))+" ")],1)],1):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }