<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10" align="center" justify="center">
        <h1>{{ $t('procedureInformationTitle') }}</h1>
      </v-col>
      <v-col cols="12" lg="8">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <b> {{ $t('proceduresFaq.question1') }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('proceduresFaq.answer1_part1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <b> {{ $t('proceduresFaq.question2') }} </b>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              {{ $t('proceduresFaq.answer2_part1') }}
              <br />
              <br />
              <ul>
                <li
                  v-for="procedureType in Object.keys($t('procedureTypes'))"
                  :key="procedureType"
                >
                  {{
                    $t('procedureTitle', {
                      type: $t(`procedureTypes.${procedureType}`),
                    })
                  }}.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <b> {{ $t('proceduresFaq.question3') }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('proceduresFaq.answer3_part1') }}
              <br />
              <br />
              <ul>
                <li
                  v-for="procedureStatus in Object.keys($t('procedureStatus'))"
                  :key="procedureStatus"
                >
                  <v-chip :color="statusColor[procedureStatus] + ' lighten-4'">
                    {{ $t(`procedureStatus.${procedureStatus}`) }}
                  </v-chip>
                  -
                  {{ $t(`proceduresFaq.answer3_${procedureStatus}`) }}
                  <br />
                  <br />
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <b> {{ $t('proceduresFaq.question4') }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('proceduresFaq.answer4_part1') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <b> {{ $t('proceduresFaq.question5') }} </b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ $t('proceduresFaq.answer5_part1') }}
              <br />
              <br />
              {{ $t('proceduresFaq.answer5_part2') }}
              <br />
              <br />
              {{ $t('proceduresFaq.answer5_part3') }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import procedureStatusColor from '@/utils/procedureStatus.json'

export default {
  data() {
    return {
      statusColor: procedureStatusColor,
    }
  },
}
</script>
