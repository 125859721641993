import { Storage } from '@/firebase-exports'
import store from '@/store/index'

async function uploadFile(fileObject, fullName) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fullName)
  return Storage.uploadBytes(fileRef, fileObject)
}

export async function removeFile(name) {
  if (!name) return
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, name)
  return await Storage.deleteObject(fileRef)
}

export async function uploadCertification(fileObject, id) {
  const fileName = id + '.' + fileObject.name.split('.').pop()
  return await uploadFile(fileObject, 'certification/' + fileName)
}

export async function uploadRecordFile(fileObject, recordId) {
  const fileName = fileObject.name
  return await uploadFile(fileObject, 'record/' + recordId + '/' + fileName)
}

export async function recordFileExists(recordId, fileName) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, 'record/' + recordId + '/' + fileName)
  try {
    await Storage.getMetadata(fileRef)
    return true
  } catch (error) {
    return false
  }
}

export async function uploadProcedure(fileObject, id) {
  const fileName = id + '.' + fileObject.name.split('.').pop()
  return await uploadFile(fileObject, 'procedure/' + fileName)
}

export async function uploadAvatar(fileObject) {
  const fileExtension = fileObject.name.split('.').pop()
  const userFileName = store.state.user.id + '.' + fileExtension
  await removeFile(store.state.user.avatarRef)
  return await uploadFile(fileObject, 'user/' + userFileName)
}

export async function getFileURL(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getDownloadURL(fileRef)
}

export async function getFileMetadata(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getMetadata(fileRef)
}

export async function getBlob(fileReference) {
  const storage = Storage.getStorage()
  const fileRef = Storage.ref(storage, fileReference)
  return await Storage.getBlob(fileRef)
}
