<template>
  <v-menu left offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <template v-if="isUserMenu">
        <v-btn text v-bind="attrs" v-on="on" style="text-transform: none">
          <span style="text-align: right" class="mr-4">
            <h4>{{ $store.state.user.name }}</h4>
            <h5>{{ $store.state.user.idNumber }}</h5>
          </span>
          <AppAvatar
            :userAvatar="$store.state.user.avatarURL"
            :userName="$store.state.user.name"
            :userRole="$store.state.user.roleRef.id"
            :userCollegiateNumber="$store.state.user.collegiateNumber"
            avatarSize="40"
            iconSize="24"
            overlapAvatarColor="secondary"
            overlapIconColor="primary"
          />
        </v-btn>
      </template>
      <v-btn v-else text v-bind="attrs" v-on="on">
        {{ $t(`navigationPage.${title}`) }}
      </v-btn>
    </template>

    <v-list color="primary">
      <v-fade-transition mode="out-in" v-for="item in items" :key="item.title">
        <v-list-item
          v-if="item.available"
          :to="item.routerData"
          exact
          :class="selectedItem(item)"
          :disabled="item.disabled"
        >
          <v-list-item-avatar>
            <v-icon :class="selectedText(item)">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title :class="selectedText(item)">
            {{ $t(`navigationPage.${item.title}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-fade-transition>
      <template v-if="isUserMenu">
        <div class="pa-2">
          <v-btn
            block
            outlined
            color="white"
            @click="$i18n.locale = $i18n.locale === 'es' ? 'en' : 'es'"
          >
            <v-icon>mdi-translate-variant</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $i18n.locale === 'es' ? 'en' : 'es' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
        <div class="pa-2">
          <v-btn block outlined color="white" @click="signOut">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-content>
              <v-list-item-title> {{ $t('signOut') }} </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    isUserMenu: Boolean,
    title: String,
    items: Array,
  },
  methods: {
    ...mapActions(['logout']),
    async signOut() {
      await this.logout()
      this.$router.push({ name: 'LoginView' })
    },
    selectedItem(item) {
      return item.path === this.$route.path ? 'secondary' : ''
    },
    selectedText(item) {
      return item.path === this.$route.path
        ? 'primary--text text--accent-3'
        : 'white--text'
    },
  },
}
</script>

<style lang="scss"></style>
