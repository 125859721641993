<template>
  <v-card class="mx-auto" outlined height="425">
    <SettingsAvatarRemoveDialog
      :showDialog="removeAvatarConfirmationDialog"
      :userAvatarURL="userModified.avatarURL"
      :loading="loading"
      @closeRemoveConfirmationDialog="removeAvatarConfirmationDialog = false"
      @removeAvatar="removeAvatar"
    />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('avatar') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('manageAvatar') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip
        bottom
        :open-on-click="false"
        :open-on-focus="isMobile ? false : true"
        :open-on-hover="isMobile ? false : true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary darkColor--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="clickEditUserInfo"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">{{ $t('editCancel') }}</span>
        <span v-else>{{ $t('editAvatars') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-form ref="avatarForm" v-model="validChangeUserInfo" lazy-validation>
      <v-list-item>
        <v-container>
          <v-row
            align="center"
            justify="center"
            no-gutters
            style="height: 258px"
          >
            <v-col cols="12" align="center" justify="center">
              <AppAvatar
                :userAvatar="fileURL"
                :userName="userModified.name"
                avatarSize="150"
                iconSize="24"
              />
            </v-col>
            <v-col cols="10">
              <v-file-input
                accept="image/png, image/gif, image/jpeg"
                v-model="avatarFile"
                :label="$t('avatar')"
                :disabled="!isEditingUserInfo || loading"
              />
            </v-col>
            <v-col cols="2" align="end">
              <v-btn
                :disabled="
                  !isEditingUserInfo ||
                  loading ||
                  !userModified.avatarRef ||
                  !!avatarFile
                "
                @click="removeAvatarConfirmationDialog = true"
                class="primary darkColor--text"
                fab
                small
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                :disabled="!isEditingUserInfo || loading"
                :loading="loading"
                @click="updateUserAvatar()"
                block
                class="primary darkColor--text"
              >
                {{ $t('updateData') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingUser') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateAvatar, removeAvatar } from '@/services/user-service'
import {
  uploadAvatar,
  removeFile,
  getFileURL,
} from '@/services/storage-service'
import SettingsAvatarRemoveDialog from '@/components/settings/SettingsAvatarRemoveDialog'

export default {
  components: {
    SettingsAvatarRemoveDialog,
  },
  async mounted() {
    this.user = { ...this.$store.state.user }
    this.userModified = { ...this.user }
    if (!this.user) return this.setNotFound(true)
  },
  computed: {
    ...mapGetters(['isMobile']),
    fileURL() {
      if (this.avatarFile) return URL.createObjectURL(this.avatarFile)
      else if (this.userModified.avatarURL) return this.userModified.avatarURL
      else return ''
    },
  },
  data() {
    return {
      avatarFile: undefined,
      user: {},
      userModified: {},
      isEditingUserInfo: false,
      loading: false,
      removeAvatarConfirmationDialog: false,
      validChangeUserInfo: false,
    }
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    async updateUserAvatar() {
      if (!this.$refs.avatarForm.validate()) return

      this.loading = true
      try {
        var responseUpload
        if (this.avatarFile) {
          responseUpload = await uploadAvatar(this.avatarFile)
          var avatarRef = responseUpload.metadata.fullPath
          var avatarURL = await getFileURL(avatarRef)
          await updateAvatar(this.$store.state.user.id, {
            avatarRef,
            avatarURL,
          })
          this.userModified.avatarRef = avatarRef
          this.userModified.avatarURL = avatarURL
        }

        this.avatarFile = undefined

        const message = this.$t('updatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })

        this.isEditingUserInfo = false
      } catch (err) {
        const message = err
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.user = { ...this.userModified }
    },
    async removeAvatar() {
      this.loading = true
      try {
        await removeFile(this.userModified.avatarRef)
        await removeAvatar(this.$store.state.user.id)
        this.userModified.avatarRef = ''
        this.userModified.avatarURL = ''
        this.avatarRef = undefined

        const message = this.$t('updatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })

        this.isEditingUserInfo = false
      } catch (err) {
        const message = this.$t('updatingUserError')
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.removeAvatarConfirmationDialog = false
      this.user = { ...this.userModified }
    },
    clickEditUserInfo() {
      this.userModified = { ...this.user }
      this.avatarFile = undefined
      this.isEditingUserInfo = !this.isEditingUserInfo
    },
  },
}
</script>
