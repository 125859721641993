var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.history && _vm.initialDelay)?_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"flat":"","max-width":"450"}},[(_vm.showTitle)?_c('v-list-item-title',{staticClass:"primary--text text-h5 mb-5"},[_vm._v(" "+_vm._s(_vm.$t('historyDetail'))+" ")]):_vm._e(),(!_vm.history.length)?[_vm._v(_vm._s(_vm.$t('historyEmpty')))]:_c('v-timeline',{attrs:{"dense":"","align-top":""}},_vm._l((_vm.history),function(item){return _c('v-timeline-item',{key:item.index,attrs:{"color":"primary","icon":_vm.certificationHistoryIcon[item.actionType],"icon-color":"secondary","fill-dot":"","large":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(item.user && item.user.avatarURL)?[_c('AppAvatar',{attrs:{"userAvatar":item.user.avatarURL,"userName":item.customName,"userRole":item.user.roleRef.id,"userCollegiateNumber":item.user.collegiateNumber,"avatarSize":"52","iconSize":"24"}}),_c('v-avatar',{staticStyle:{"margin-left":"-35px","margin-top":"45px","position":"absolute"},attrs:{"color":"secondary","size":"20"}},[_c('v-icon',{staticStyle:{"font-size":"15px"},attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.certificationHistoryIcon[item.actionType])+" ")])],1)]:_vm._e()]},proxy:true}],null,true)},[_c('v-card',{attrs:{"color":"primary"}},[_c('v-card-title',[(_vm.isMobile)?_c('h6',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t(("history." + (item.actionType))))+" ")]):_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$t(("history." + (item.actionType))))+" ")]),_c('v-spacer'),(item.blockchain && item.blockchain.registeredBlockchain)?[_c('v-tooltip',{attrs:{"bottom":"","color":"secondary","open-on-click":false,"open-on-focus":_vm.isMobile ? false : true,"open-on-hover":_vm.isMobile ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":_vm.getBlockchainTransactionLink(item),"target":"blank"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-link-lock ")])],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('viewOnBlockchain')))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary","open-on-click":false,"open-on-focus":_vm.isMobile ? false : true,"open-on-hover":_vm.isMobile ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0",attrs:{"fab":"","dark":"","x-small":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push({
                        name: 'BlockchainVerifierView',
                        params: {
                          actionType: item.blockchain.data.actionType,
                          createdAt: item.blockchain.data.createdAt,
                          creatorId: item.blockchain.data.creatorId,
                          documentHash: item.blockchain.data.documentHash,
                          receiverId: item.blockchain.data.receiverId,
                          secret: item.blockchain.data.secret,
                        },
                      })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pound ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('hashVerify')))])])]:_vm._e()],2),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.historyEntryItems),function(historyEntry){return [(historyEntry.value(item))?_c('v-list-item',{key:historyEntry.title},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(historyEntry.icon)+" ")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(historyEntry.value(item))+" ")])],1):_vm._e()]})],2)],1)],1)],1)}),1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }