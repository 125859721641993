<template>
  <v-form ref="helpForm" v-model="validForm" lazy-validation>
    <v-container>
      <v-row align="center" justify="center">
        <h1>{{ $t('help') }}</h1>
      </v-row>
      <v-row align="center" justify="center">
        <h3 class="headline mb-1">{{ $t('helpInfo') }}</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="helpForm.subject"
            :rules="subjectRules"
            :label="$t('subject')"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="helpForm.message"
            :rules="messageRules"
            :label="$t('message')"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            :disabled="loading"
            :loading="loading"
            class="primary darkColor--text"
            @click="sendForm"
          >
            {{ $t('helpFormButton') }}
            <template v-slot:loader>
              <v-progress-circular indeterminate size="20" width="2" />
              <span class="ml-2">{{ $t('sendingHelpForm') }}</span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import rules from '@/utils/rules'
import { HelpFormFunction } from '@/firebase-exports'
import { mapMutations } from 'vuex'

export default {
  watch: {
    '$i18n.locale': function () {
      this.$refs.helpForm.resetValidation()
    },
  },
  data() {
    return {
      loading: false,
      subjectRules: [rules.required],
      messageRules: [rules.required],
      validForm: true,
      helpForm: {},
    }
  },
  methods: {
    ...mapMutations(['setDialog', 'setSnackbar']),
    async sendForm() {
      if (!this.$refs.helpForm.validate()) return

      this.loading = true
      try {
        await HelpFormFunction(this.helpForm)

        const title = this.$t('help')
        const message = this.$t('helpFormSuccess')
        this.setDialog({ title, message })

        this.$refs.helpForm.reset()
      } catch (err) {
        const message = this.$t('helpFormError')
        this.setSnackbar({ position: 'top', message })
      }

      this.loading = false
    },
  },
}
</script>
