import {
  Firestore,
  CertificationTypesCollection,
  UsersCertificationTypesCollection,
  UsersCertificationTypesDoc,
} from '@/firebase-exports'
import store from '@/store'

export async function createCustomCertificationType(customCertificationType) {
  return await Firestore.addDoc(
    UsersCertificationTypesCollection(store.state.user.id),
    customCertificationType
  )
}

export async function listCertificationTypes() {
  const collections = [
    CertificationTypesCollection,
    UsersCertificationTypesCollection(store.state.user.id),
  ]
  var response = await Promise.all(
    collections.map(async (collection) => {
      const querySnapshot = await Firestore.getDocs(collection)
      return querySnapshot.docs.map((doc) => doc.data().name)
    })
  )
  return [...response[0], ...response[1]].sort((a, b) => a.localeCompare(b))
}

export async function listUserCertificationTypes() {
  const q = Firestore.query(
    UsersCertificationTypesCollection(store.state.user.id),
    Firestore.orderBy('name', 'asc')
  )
  const querySnapshotCustom = await Firestore.getDocs(q)
  return querySnapshotCustom.docs.map((doc) => {
    const document = doc.data()
    return { id: doc.id, ...document }
  })
}

export async function updateCustomCertificationType(id, data) {
  return await Firestore.updateDoc(
    UsersCertificationTypesDoc(store.state.user.id, id),
    data
  )
}

export async function deleteCustomCertificationType(id) {
  return await Firestore.deleteDoc(
    UsersCertificationTypesDoc(store.state.user.id, id)
  )
}
