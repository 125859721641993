<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <HelpCreate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpCreate from '@/components/help/HelpCreate'

export default {
  components: {
    HelpCreate,
  },
}
</script>
