<template>
  <v-card class="pa-3">
    <v-container fluid>
      <v-row align="center" justify="start">
        <h4 class="text-h4">
          <AppSkeleton :condition="!loaded" type="recordTitle">
            <template v-slot:skeleton-content>
              <span v-if="!isMobile" class="ml-4">
                {{ asset.procedureNumber }} {{ asset.client.name }} ({{
                  asset.client.taxNumber
                }})
              </span>
              <span v-else>
                {{ asset.procedureNumber }} {{ asset.client.name }} ({{
                  asset.client.taxNumber
                }})
              </span>
            </template>
          </AppSkeleton>
        </h4>
        <v-spacer></v-spacer>
        <AppSkeleton :condition="!loaded" type="certificationActions">
          <template v-slot:skeleton-content v-if="isCreator">
            <v-tooltip
              bottom
              :open-on-click="false"
              :open-on-focus="isMobile ? false : true"
              :open-on-hover="isMobile ? false : true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="primary darkColor--text ml-4"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $router.push({
                      name: 'RecordUpdateView',
                      params: { id: asset.id },
                    })
                  "
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('recordUpdate') }}</span>
            </v-tooltip>
          </template>
        </AppSkeleton>
      </v-row>
      <v-row align="center" justify="start">
        <h5 class="text-h5">
          <AppSkeleton :condition="!loaded" type="recordTitle">
            <template v-slot:skeleton-content>
              <span v-if="!isMobile" class="ml-4">
                {{ asset.subject }} - {{ asset.subjectType }}
              </span>
              <span v-else>
                {{ asset.subject }} - {{ asset.subjectType }}
              </span>
            </template>
          </AppSkeleton>
        </h5>
      </v-row>
      <v-row align="start" justify="start">
        <v-expansion-panels v-model="panel" multiple flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="secondary"> $expand </v-icon>
              </template>
              <div v-if="loaded" style="color: grey">
                {{ $t('recordTitleToggle') }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="start">
                <v-col cols="12" v-if="isMobile">
                  <v-subheader class="px-0">
                    {{ $t('clientDetails') }}
                  </v-subheader>
                  <v-divider />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-numeric </v-icon>
                          <b>{{ $t('recordProcedureNumberShort') }}: </b>
                          <br />
                          {{ asset.procedureNumber }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-account-outline </v-icon>
                          <b>{{ $t('client') }}: </b> <br />
                          {{ asset.client.idNumber }} -
                          {{ asset.client.name }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-gavel </v-icon>
                          <b>{{ $t('solicitor') }}: </b> <br />
                          {{ asset.solicitor }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
              </v-row>

              <v-row align="start" justify="start">
                <v-col cols="12" v-if="isMobile">
                  <v-subheader class="px-0">
                    {{ $t('oppositeDetails') }}
                  </v-subheader>
                  <v-divider />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-account-injury </v-icon>
                          <b>{{ $t('opposite') }}: </b> <br />
                          {{ asset.opposite }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-scale-balance </v-icon>
                          <b>{{ $t('oppositeLawyer') }}: </b> <br />
                          {{ asset.oppositeLawyer }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-gavel </v-icon>
                          <b>{{ $t('oppositeSolicitor') }}: </b> <br />
                          {{ asset.oppositeSolicitor }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
              </v-row>

              <v-row align="start" justify="start">
                <v-col cols="12" v-if="isMobile">
                  <v-subheader class="px-0">
                    {{ $t('additionalInformation') }}
                  </v-subheader>
                  <v-divider />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-calendar-check </v-icon>
                          <b>{{ $t('recordCreatedAtShort') }}: </b> <br />
                          {{ $d(asset.createdAt, 'i18nDate') }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-calendar-clock </v-icon>
                          <b>{{ $t('trialDateShort') }}: </b> <br />
                          <div v-if="asset.trialDate">
                            {{ $d(asset.trialDate, 'i18nDateAndHour') }}
                          </div>
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-bank </v-icon>
                          <b>{{ $t('recordJurisdictionTypeShort') }}: </b>
                          <br />
                          {{ asset.jurisdictionType }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
              </v-row>

              <v-row align="start" justify="start">
                <v-col cols="12">
                  <v-card-subtitle class="pt-1 pb-0 pl-0">
                    <AppSkeleton :condition="!loaded" type="recordSubtitle">
                      <template v-slot:skeleton-content>
                        <span>
                          <v-icon class="mr-2"> mdi-text </v-icon>
                          <b>{{ $t('recordComments') }}: </b> <br />
                          {{ asset.comments }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    asset: Object,
    loaded: Boolean,
    isCreator: Boolean,
  },
  async mounted() {
    if (this.isMobile) this.panel = []
  },
  data() {
    return {
      panel: [0],
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss" scoped></style>
