<template>
  <v-dialog :value="showDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="justify-center">
        {{ $t('removementAvatarConfirmation') }}
      </v-card-title>
      <v-card-text class="text-center">
        <AppAvatar :userAvatar="userAvatarURL" avatarSize="150" iconSize="24" />
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          class="red lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading"
          @click="$emit('closeRemoveConfirmationDialog')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="green lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading"
          :loading="loading"
          @click="$emit('removeAvatar')"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    userAvatarURL: String,
    loading: Boolean,
  },
}
</script>
