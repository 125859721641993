<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="5">
        <UserDetail />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserDetail from '@/components/user/UserDetail'

export default {
  components: {
    UserDetail,
  },
}
</script>

<style lang="scss" scoped></style>
