import vuetify from '@/plugins/vuetify'

const getters = {
  isMobile() {
    return !vuetify.framework.breakpoint.mdAndUp
  },
  isUserSignedIn(state) {
    return !!state.user
  },
  userRole(state, getters) {
    return function (path) {
      try {
        if (!getters.isUserSignedIn || !state.user.role) return false
        var roleValue = state.user.role
        const properties = path.split('.')
        for (const prop of properties) roleValue = roleValue[prop]
        return roleValue
      } catch (error) {
        console.log(error)
        console.log('error')
      }
    }
  },
  isUserAdmin(state, getters) {
    return getters.isUserSignedIn && state.user.roleRef.id === 'admin'
  },
  isUserLawyer(state, getters) {
    return getters.isUserSignedIn && state.user.roleRef.id === 'lawyer'
  },
  isUserSolicitor(state, getters) {
    return getters.isUserSignedIn && state.user.roleRef.id === 'solicitor'
  },
  isUserAssociationSolicitor(state, getters) {
    return (
      getters.isUserSignedIn && state.user.roleRef.id === 'associationSolicitor'
    )
  },
  isUserClient(state, getters) {
    return getters.isUserSignedIn && state.user.roleRef.id === 'client'
  },
}

export default getters
