import { Firestore, MetadataVersion } from '@/firebase-exports'
import store from '@/store/index'

export function getAppVersionSubscription() {
  const appVersionUnsubscribe = Firestore.onSnapshot(
    MetadataVersion,
    (doc) => store.commit('setRemoteAppVersion', doc.data().version),
    (error) => {
      throw error
    }
  )
  return appVersionUnsubscribe
}
