<template>
  <AppSkeleton :condition="!fileRefs" type="recordFiles">
    <template v-slot:skeleton-content>
      <v-card flat>
        <v-card>
          <v-data-table
            v-model="selected"
            :items-per-page="!isMobile ? 10 : visible * 5"
            :headers="showUsers ? headers : headersNoShared"
            :items="fileRefs"
            :search="search"
            :single-select="false"
            item-key="id"
            :show-select="isCreator"
            class="elevation-1"
            @click:row="selectRow"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
            }"
            :hide-default-footer="isMobile"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <div v-if="selected.length > 0">
                  <NotificationCreate
                    @notificationCreated="emitEvent"
                    :attachmentAsset="record"
                    :subAttachmentAssets="selected"
                    attachmentType="record"
                  />
                </div>
                <v-card-title>
                  {{ $t('navigationPage.recordFiles') }}
                </v-card-title>
                <template v-if="!isMobile">
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                  />
                  <v-spacer></v-spacer>
                  <RecordFileCreate
                    v-if="isCreator"
                    @record-file-created="emitEvent"
                    :client="client"
                    :record="record"
                  />
                </template>
              </v-toolbar>
              <template v-if="isMobile">
                <v-toolbar flat>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                  />
                </v-toolbar>
                <RecordFileCreate
                  v-if="isCreator"
                  @record-file-created="emitEvent"
                  :client="client"
                  :record="record"
                />
              </template>
            </template>
            <template
              v-slot:item="{ item: recordFile, isMobile: mobileVersion }"
            >
              <tr
                :style="
                  selectedItem.id === recordFile.id &&
                  ' background-color: aliceblue'
                "
                v-if="!mobileVersion"
                @click="selectRow(recordFile)"
              >
                <td v-if="isCreator">
                  <v-simple-checkbox
                    :value="selected.includes(recordFile)"
                    :ripple="false"
                    @click="clickChecbox(recordFile)"
                  ></v-simple-checkbox>
                </td>
                <td>
                  <span> {{ recordFile.name }} </span>
                </td>
                <td>
                  <span> {{ recordFile.type }} </span>
                </td>
                <td>
                  <span>
                    {{ recordFile.description }}
                  </span>
                </td>
                <td v-if="showUsers">
                  <v-row justify="start" align="start">
                    <span
                      class="ml-1"
                      v-for="item in recordFile.whitelist.slice(0, 3)"
                      v-bind:key="item.user.id"
                    >
                      <AppAvatar
                        :userAvatar="item.user.avatarURL"
                        :userName="item.user.name"
                        avatarSize="40"
                        iconSize="24"
                      />
                    </span>
                    <span v-if="recordFile.whitelist.length > 3" class="ml-1">
                      <AppAvatar
                        :userName="getMoreShared(recordFile.whitelist, 3)"
                        avatarSize="40"
                        iconSize="24"
                        avatarColor="primary"
                        initialsColor="white"
                      />
                    </span>
                  </v-row>
                </td>
              </tr>
              <tr v-else>
                <v-list subheader two-line>
                  <v-list-item
                    @click="selectRow(recordFile)"
                    :style="
                      selectedItem.id === recordFile.id &&
                      ' background-color: aliceblue'
                    "
                  >
                    <v-simple-checkbox
                      v-if="isCreator"
                      class="ml-3"
                      :value="selected.includes(recordFile)"
                      :ripple="false"
                      @click="clickChecbox(recordFile)"
                    ></v-simple-checkbox>
                    <v-list-item-avatar class="mr-2">
                      <v-icon class="primary lighten-1" dark>
                        mdi-file-document-outline
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="recordFile.name.length > 35">
                        {{ recordFile.name.substring(0, 32) + '...' }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        {{ recordFile.name }}
                      </v-list-item-title>
                      <v-list-item-title class="primary--text lighten-3">
                        {{ recordFile.type }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        {{ recordFile.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </tr>
            </template>
            <template v-slot:footer v-if="isMobile">
              <v-list-item v-if="visible * 5 <= fileRefs.length">
                <v-btn block rounded @click="visible++" color="primary">
                  <v-icon left dark>mdi-chevron-down</v-icon>
                  {{ $t('showMore') }}
                </v-btn>
              </v-list-item>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </template>
  </AppSkeleton>
</template>

<script>
import { mapGetters } from 'vuex'
import AppAvatar from '@/components/commons/AppAvatar'
import NotificationCreate from '@/components/notification/NotificationCreate'
import RecordFileCreate from '@/components/record/RecordFileCreate'

export default {
  components: {
    NotificationCreate,
    RecordFileCreate,
    AppAvatar,
  },
  props: {
    fileRefs: Array,
    client: Object,
    record: Object,
    showUsers: Boolean,
    isCreator: Boolean,
  },
  data() {
    return {
      benched: 0,
      selectedItem: {},
      selected: [],
      visible: 1,
      search: '',
    }
  },
  methods: {
    selectRow(item) {
      this.selectedItem = item
      this.$emit('select-row', item)
    },
    emitEvent(item) {
      this.selected = []
      this.$emit('record-file-created', item)
    },
    clickChecbox(item) {
      this.selected.includes(item)
        ? this.selected.splice(this.selected.indexOf(item), 1)
        : this.selected.push(item)
    },
    getMoreShared(items, alreadyShowed) {
      return '+ ' + (items.length - alreadyShowed).toString()
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    headers() {
      return [
        {
          text: this.$t('recordFileName'),
          align: 'start',
          value: 'name',
          width: '20%',
        },
        { text: this.$t('recordFileType'), value: 'type', width: '20%' },
        {
          text: this.$t('descriptionInput'),
          value: 'description',
          width: '30%',
        },
        { text: this.$t('sharedWith'), value: 'whitelist', width: '30%' },
      ]
    },
    headersNoShared() {
      return [
        {
          text: this.$t('recordFileName'),
          align: 'start',
          value: 'name',
          width: '30%',
        },
        { text: this.$t('recordFileType'), value: 'type', width: '30%' },
        {
          text: this.$t('descriptionInput'),
          value: 'description',
          width: '40%',
        },
      ]
    },
  },
}
</script>
