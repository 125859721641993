<template>
  <v-card>
    <v-data-table
      :items-per-page="!isMobile ? 10 : visible * 5"
      :headers="headers"
      :items="users"
      :search="search"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
      }"
      :hide-default-footer="isMobile"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-card-title>
            {{ $t('navigationPage.users') }}
          </v-card-title>
          <template v-if="!isMobile">
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="$router.push({ name: 'UserCreateView' })"
            >
              {{ $t('userCreate') }}
            </v-btn>
          </template>
        </v-toolbar>
        <template v-if="isMobile">
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
          </v-toolbar>
        </template>
      </template>
      <template v-slot:item="{ item: user, isMobile: mobileVersion }">
        <tr v-if="!mobileVersion" @click="openDocumentDetail(user)">
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ user.name }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ user.idNumber }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ $t(user.roleRef.id) }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  <v-row justify="center">
                    <v-icon>
                      {{ user.disabled ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                  </v-row>
                </span>
              </template>
            </AppSkeleton>
          </td>
        </tr>
        <tr v-else>
          <v-list subheader two-line>
            <AppSkeleton
              :condition="loadingTableData"
              type="list-item-avatar-two-line"
            >
              <template v-slot:skeleton-content>
                <v-list-item @click="openDocumentDetail(user)">
                  <v-list-item-avatar>
                    <v-icon class="primary lighten-1" dark>
                      mdi-account-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-if="user.name.length > 20">
                      {{ user.name.substring(0, 17) + '...' }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-title class="primary--text lighten-3">
                      {{ user.idNumber }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <v-list-item-subtitle>
                      {{ $t('type') }}: {{ $t(user.roleRef.id) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ $t('disabled') }}
                      <v-icon small>
                        {{ user.disabled ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-action-text>
                </v-list-item>
              </template>
            </AppSkeleton>
          </v-list>
        </tr>
      </template>
      <template v-slot:footer v-if="isMobile">
        <v-list-item v-if="visible * 5 <= users.length">
          <v-btn
            block
            rounded
            @click="visible++"
            color="primary"
            :disabled="loadingTableData"
          >
            <v-icon left dark>mdi-chevron-down</v-icon>
            {{ $t('showMore') }}
          </v-btn>
        </v-list-item>
      </template>
    </v-data-table>
    <v-fab-transition>
      <v-btn
        v-if="isMobile && !$store.state.navigationDrawer"
        @click="$router.push({ name: 'UserCreateView' })"
        color="secondary"
        fab
        fixed
        right
        bottom
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    loadingTableData: Boolean,
    users: Array,
  },
  data() {
    return {
      visible: 1,
      search: '',
    }
  },
  methods: {
    openDocumentDetail(value) {
      if (!Object.keys(value).length) return

      this.$router.push({
        name: 'UserDetailView',
        params: { id: value.id },
      })
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name',
          groupable: false,
          width: 200,
        },
        {
          text: this.$t('idNumber'),
          value: 'idNumber',
          groupable: false,
          width: 150,
        },
        {
          text: this.$t('type'),
          value: 'roleRef.id',
          groupable: false,
          width: 75,
        },
        {
          text: this.$t('disabled'),
          value: 'disabled',
          groupable: false,
          width: 75,
          align: 'center',
        },
      ]
    },
  },
}
</script>

<style lang="scss"></style>
