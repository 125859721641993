<template>
  <v-bottom-sheet v-model="sheet" inset @click:outside="closeSheet">
    <template v-slot:activator="{ on, attrs }">
      <v-btn block outlined v-bind="attrs" v-on="on">
        <v-icon left> mdi-arrow-top-right-bold-outline </v-icon>
        {{ $t('share') }}
      </v-btn>
    </template>

    <v-stepper v-model="step" class="rounded-b-0">
      <v-row>
        <v-spacer />
        <v-text-field
          v-if="step === 1"
          v-model="search"
          prepend-icon="mdi-magnify"
          clearable
          @input="selectedItem = ''"
          @click:clear="selectedItem = ''"
          :label="$t('searchClient')"
          single-line
          hide-details
          class="mt-2"
        />
        <v-spacer />
        <v-btn icon class="ma-5" @click="closeSheet">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-container class="text-center">
            <v-slide-group v-model="selectedItem">
              <v-slide-item
                v-show="search && search.length !== 0"
                v-slot="{ active, toggle }"
              >
                <v-card
                  flat
                  :color="active ? 'secondary' : undefined"
                  class="ma-2 pa-2"
                  width="100"
                  @click="toggle"
                >
                  <v-avatar color="primary">
                    <v-icon class="secondary--text">
                      mdi-account-outline
                    </v-icon>
                  </v-avatar>
                  <v-list-item-subtitle
                    :class="active ? 'white--text' : undefined"
                  >
                    {{ $t('sentToUser') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="active ? 'white--text' : undefined"
                  >
                    {{ search }}
                  </v-list-item-subtitle>
                </v-card>
              </v-slide-item>
              <v-slide-item
                v-for="(item, i) in filteredItems"
                :key="i"
                v-slot="{ active, toggle }"
              >
                <v-card
                  flat
                  :color="active ? 'primary' : undefined"
                  class="ma-2 pa-2"
                  width="100"
                  @click="toggle"
                >
                  <v-list-item-icon class="mr-0 mt-0">
                    <AppAvatar
                      :isGroup="item.type !== 'contact'"
                      :userAvatar="item.user ? item.user.avatarURL : ''"
                      :userName="item.customName"
                      :userRole="item.user ? item.user.roleRef.id : 'group'"
                      :userCollegiateNumber="
                        item.user ? item.user.collegiateNumber : undefined
                      "
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-list-item-icon>
                  <v-list-item-subtitle
                    :class="active ? 'white--text' : undefined"
                  >
                    {{ item.customName }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="active ? 'white--text' : undefined"
                  >
                    {{
                      item.user
                        ? item.user.collegiateNumber || item.user.idNumber
                        : $tc('contactCount', item.contacts.length)
                    }}
                  </v-list-item-subtitle>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-container>
            <AppUserSummary
              :clientName="itemInfo.name"
              :clientAvatar="itemInfo.user ? itemInfo.user.avatarURL : ''"
              :clientIdNumber="
                itemInfo.id || $tc('contactCount', itemInfo.contacts.length)
              "
              :clientRole="itemInfo.user ? itemInfo.user.roleRef.id : 'group'"
              :clientCollegiateNumber="
                itemInfo.user ? itemInfo.user.collegiateNumber : undefined
              "
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn v-if="step !== 1" color="primary" text @click="step--">
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="
            selectedItem === undefined || selectedItem === '' || loading
          "
          :loading="loading"
          @click="nextStep"
        >
          {{ $t(stepsInfo[step - 1].nextAction) }}
          <template v-slot:loader>
            <span>{{ $t('creatingNotification') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-bottom-sheet>
</template>

<script>
import { getUserByIdNumber } from '@/services/user-service'
import { listGroups } from '@/services/group-service'
import { listContacts } from '@/services/contact-service'
import { createNotification } from '@/services/notification-service'
import AppUserSummary from '@/components/commons/AppUserSummary'
import { mapMutations } from 'vuex'

export default {
  components: {
    AppUserSummary,
  },
  props: {
    attachmentAsset: Object,
    subAttachmentAssets: Array,
    attachmentType: String,
  },
  data() {
    return {
      stepsInfo: [
        { name: 'client', optional: false, nextAction: 'next' },
        { name: 'confirmClient', optional: false, nextAction: 'confirm' },
      ],
      step: 1,
      items: { groups: [], contacts: [] },
      itemInfo: { contacts: [] },
      selectedItem: undefined,
      loading: false,
      sheet: false,
      search: '',
    }
  },
  async mounted() {
    this.items.groups = await listGroups()
    this.items.contacts = await listContacts()
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    closeSheet() {
      this.step = 1
      this.itemInfo = { contacts: [] }
      this.selectedItem = undefined
      this.sheet = false
      this.search = ''
    },
    async nextStep() {
      switch (this.step) {
        case 1:
          if (this.selectedItem === undefined) {
            const message = this.$t('clientRequired')
            this.setSnackbar({ position: 'bottom', message })
            return
          }

          this.itemInfo = !this.selectedItem
            ? this.search
            : this.filteredItems[this.selectedItem - 1]

          if (this.itemInfo.type == 'contact') {
            this.itemInfo.id = this.itemInfo.user.idNumber
            this.itemInfo.name = this.getNameHidden(this.itemInfo.user.name)
          } else if (this.itemInfo.type == 'group') {
            this.itemInfo.id = ''
            this.itemInfo.name = this.itemInfo.customName
          } else {
            var user = await getUserByIdNumber(this.itemInfo)
            if (user) {
              this.itemInfo = {
                type: 'contact',
                id: user.idNumber,
                name: this.getNameHidden(user.name),
                user,
              }
            } else {
              const message = this.$t('userNotFound')
              this.setSnackbar({ position: 'bottom', message })
              return
            }
          }

          break

        case 2:
          try {
            this.loading = true
            if (this.itemInfo.type == 'contact') {
              var notificationContact = {
                attachmentRef: this.attachmentAsset.id,
                issuerRef: this.$store.state.user.id,
                read: false,
                receiverRef: (await getUserByIdNumber(this.itemInfo.id)).id,
              }

              var subAttachmentsRef = []
              this.subAttachmentAssets.forEach((element) => {
                subAttachmentsRef.push(element.id)
              })
              if (subAttachmentsRef.length > 0)
                notificationContact.subAttachmentsRef = subAttachmentsRef

              await createNotification(notificationContact, this.attachmentType)
            } else if (this.itemInfo.type == 'group') {
              for (const groupContact of this.itemInfo.contacts) {
                var notificationGroup = {
                  attachmentRef: this.attachmentAsset.id,
                  issuerRef: this.$store.state.user.id,
                  read: false,
                  receiverRef: groupContact.contact.userRef.id,
                }

                subAttachmentsRef = []
                this.subAttachmentAssets.forEach((element) => {
                  subAttachmentsRef.push(element.id)
                })
                if (subAttachmentsRef.length > 0)
                  notificationGroup.subAttachmentsRef = subAttachmentsRef

                await createNotification(notificationGroup, this.attachmentType)
              }
            } else {
              return
            }

            const message = this.$t('creatingNotificationSuccess')
            this.setSnackbar({ position: 'bottom', message, color: 'green' })
          } catch (err) {
            const message = this.$t('creatingNotificationError')
            this.setSnackbar({ position: 'bottom', message })
          }
          this.loading = false
          this.closeSheet()
          this.$emit('notificationCreated')
          return

        default:
          break
      }
      this.step++
    },
    getNameHidden(fullName) {
      const splittedName = fullName.split(' ').filter((e) => e)
      var name = splittedName[0]
      var surnames = splittedName.slice(1, splittedName.length)
      return name + ' ' + surnames.map((n) => n[0] + '. ').join('')
    },
  },
  computed: {
    filteredItems() {
      this.items.groups.forEach((group) => (group.type = 'group'))
      this.items.contacts.forEach((contact) => (contact.type = 'contact'))
      const fixedItems = [...this.items.groups, ...this.items.contacts]
      return fixedItems.filter((item) => {
        if (!this.search) return fixedItems
        return (
          item.customName.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.user &&
            item.user.idNumber
              .toLowerCase()
              .includes(this.search.toLowerCase()))
        )
      })
    },
  },
}
</script>
