<template>
  <v-card flat>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h4 class="text-h4">
            <AppSkeleton :condition="!procedure.type" type="procedureTitle">
              <template v-slot:skeleton-content>
                <span>
                  {{
                    $t('procedureTitle', {
                      type: $t(`procedureTypes.${procedure.type}`),
                    })
                  }}
                </span>
              </template>
            </AppSkeleton>
          </h4>
          <AppSkeleton
            :condition="!procedure.status"
            type="procedureCurrentStatus"
          >
            <template v-slot:skeleton-content>
              <i18n
                path="procedureCurrentStatus"
                tag="div"
                class="primary--text"
              >
                <v-chip :color="statusColor[procedure.status] + ' lighten-4'">
                  {{ $t(`procedureStatus.${procedure.status}`) }}
                </v-chip>
              </i18n>
            </template>
          </AppSkeleton>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import procedureStatusColor from '@/utils/procedureStatus.json'

export default {
  props: {
    procedure: Object,
  },
  data() {
    return {
      statusColor: procedureStatusColor,
    }
  },
}
</script>

<style lang="scss" scoped></style>
