<template>
  <v-card flat>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h4 class="text-h4">
            <AppSkeleton
              :condition="!certification.name"
              type="certificationTitle"
            >
              <template v-slot:skeleton-content>
                <span> {{ certification.name }} </span>
              </template>
            </AppSkeleton>
          </h4>
          <v-card-subtitle>
            <AppSkeleton
              :condition="!certification.type"
              type="certificationSubtitle"
            >
              <template v-slot:skeleton-content>
                <span>
                  <v-icon class="mr-2"> mdi-certificate-outline </v-icon>
                  {{ certification.type }}
                  <br />
                </span>
              </template>
            </AppSkeleton>

            <AppSkeleton
              :condition="!certification.client"
              type="certificationSubtitle"
            >
              <template v-slot:skeleton-content>
                <span>
                  <v-icon class="mr-2"> mdi-account-details-outline </v-icon>
                  {{ certification.client.idNumber }} -
                  {{ certification.client.name }}
                  <br />
                </span>
              </template>
            </AppSkeleton>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    certification: Object,
  },
}
</script>

<style lang="scss" scoped></style>
