<template>
  <AppSkeleton :condition="!procedureFile" type="button">
    <template v-slot:skeleton-content>
      <v-dialog v-model="mediaDialog" fullscreen>
        <template v-slot:activator="{ on, attrs }">
          <v-btn block outlined v-bind="attrs" v-on="on">
            <v-icon left> mdi-eye </v-icon>
            {{ $t('openProcedureInput') }}
          </v-btn>
        </template>
        <v-card>
          <v-toolbar elevation="0">
            <v-spacer />
            <v-btn icon @click="mediaDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <AppMedia
            style="height: 90vh"
            :fileURL="procedureFile.url"
            :fileContentType="procedureFile.metadata.contentType"
            maxHeight="800px"
            :contain="true"
          />
        </v-card>
      </v-dialog>
    </template>
  </AppSkeleton>
</template>

<script>
import AppMedia from '@/components/commons/AppMedia'

export default {
  components: {
    AppMedia,
  },
  props: {
    procedureFile: Object,
  },
  data() {
    return {
      mediaDialog: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
