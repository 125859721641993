<template>
  <v-card flat>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card-subtitle>
            <AppSkeleton
              :condition="!certificationHash"
              type="certificationAttributes"
            >
              <template v-slot:skeleton-content>
                <span v-if="certificationHash">
                  <v-icon class="mr-2"> mdi-lock-check </v-icon>
                  {{ certificationHash }}
                  <br />
                </span>
              </template>
            </AppSkeleton>

            <AppSkeleton
              :condition="!certificationDate"
              type="certificationAttributes"
            >
              <template v-slot:skeleton-content>
                <span v-if="certificationDate">
                  <v-icon class="mr-2"> mdi-calendar-range </v-icon>
                  {{ $d(certificationDate, 'i18nDateAndHourSeconds') }}
                  <br />
                </span>
              </template>
            </AppSkeleton>

            <AppSkeleton
              :condition="!certificationBlockchain"
              type="certificationAttributes"
            >
              <template v-slot:skeleton-content>
                <span v-if="certificationBlockchain.registeredBlockchain">
                  <v-icon class="mr-2"> mdi-file-sign </v-icon>
                  {{ certificationBlockchain.hash.substring(0, 12) }}...{{
                    certificationBlockchain.hash.substring(51, 63)
                  }}
                  <br />
                </span>
              </template>
            </AppSkeleton>

            <AppSkeleton
              :condition="!certificationBlockchain"
              type="certificationAttributes"
            >
              <template v-slot:skeleton-content>
                <span v-if="certificationBlockchain.registeredBlockchain">
                  <v-icon class="mr-2"> mdi-link-lock </v-icon>
                  <a :href="getBlockchainTransactionLink()" target="blank">{{
                    $t('viewOnBlockchain')
                  }}</a>
                  <v-btn
                    depressed
                    class="mx-2"
                    color="primary"
                    x-small
                    @click="
                      $router.push({
                        name: 'BlockchainVerifierView',
                        params: {
                          actionType: certificationBlockchain.data.actionType,
                          createdAt: certificationBlockchain.data.createdAt,
                          creatorId: certificationBlockchain.data.creatorId,
                          documentHash:
                            certificationBlockchain.data.documentHash,
                          receiverId: certificationBlockchain.data.receiverId,
                          secret: certificationBlockchain.data.secret,
                        },
                      })
                    "
                  >
                    {{ $t('hashVerify') }}
                  </v-btn>
                  <br />
                </span>
              </template>
            </AppSkeleton>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { getTransactionLink } from '@/utils/blockchain-utils'

export default {
  props: {
    certificationHash: String,
    certificationDate: Date,
    certificationBlockchain: Object,
  },
  methods: {
    getBlockchainTransactionLink() {
      return getTransactionLink(
        this.certificationBlockchain.chainId,
        this.certificationBlockchain.transactionHash
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
