var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","max-width":"450"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title || _vm.actionMessages.title[_vm.actionDialog]))+" ")]),_c('v-card-text',{staticClass:"text-center"},[(_vm.isDelete)?[_c('v-alert',{staticClass:"text-left mb-10",attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('removeContactWarning'))+" ")]),_c('h2',[_vm._v(_vm._s(_vm.contactData.customName))]),_c('h3',[_vm._v(_vm._s(_vm.contactData.idNumber))])]:_c('v-form',{key:_vm.contactType,ref:"contactDialogForm",attrs:{"lazy-validation":""},model:{value:(_vm.validContactForm),callback:function ($$v) {_vm.validContactForm=$$v},expression:"validContactForm"}},[_c('v-container',[_c('v-row',[(_vm.contactType === 'client')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('taxNumber'),"rules":_vm.contactIdNumberRules,"success-messages":_vm.contactIdInputMessage(false),"error-messages":_vm.contactIdInputMessage(true),"prepend-icon":"mdi-card-account-details-outline","disabled":_vm.isUpdate,"dense":""},on:{"keyup":_vm.fixAndUppercase,"focus":function($event){_vm.isContactIdSelected = !_vm.isContactIdSelected},"blur":function($event){_vm.isContactIdSelected = !_vm.isContactIdSelected},"change":_vm.checkContactExists},scopedSlots:_vm._u([(_vm.isCreate)?{key:"append",fn:function(){return [(_vm.loadingContactId)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":"","size":"20","width":"2"}}):(
                      !_vm.isContactIdSelected &&
                      _vm.contactData.idNumber &&
                      !_vm.rulesFails
                    )?_c('v-icon',{attrs:{"right":"","color":_vm.validContactId ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.validContactId ? 'mdi-check' : 'mdi-close')+" ")]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.contactData.idNumber),callback:function ($$v) {_vm.$set(_vm.contactData, "idNumber", $$v)},expression:"contactData.idNumber"}})],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.associationList[_vm.contactType],"item-text":"name","item-value":_vm.isCreate ? 'shortName' : 'id',"prepend-icon":"mdi-office-building-outline","label":_vm.$t('selectAssociation'),"rules":_vm.contactAssociationRules,"disabled":_vm.loading || _vm.isUpdate,"menu-props":{ bottom: true, offsetY: true }},on:{"change":function($event){if (_vm.contactData.collegiateNumber) {
                      _vm.$refs.contactDialogForm.validate()
                      _vm.checkContactExists()
                    }}},model:{value:(_vm.contactData.association),callback:function ($$v) {_vm.$set(_vm.contactData, "association", $$v)},expression:"contactData.association"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-numeric","label":_vm.$t('collegiateNumber'),"type":"Number","min":"1","rules":_vm.contactCollegiateNumberRules,"disabled":_vm.loading || _vm.isUpdate,"success-messages":_vm.contactIdInputMessage(false),"error-messages":_vm.contactIdInputMessage(true)},on:{"focus":function($event){_vm.isContactIdSelected = !_vm.isContactIdSelected},"blur":function($event){_vm.isContactIdSelected = !_vm.isContactIdSelected},"change":_vm.checkContactExists},scopedSlots:_vm._u([(_vm.isCreate)?{key:"append",fn:function(){return [(_vm.loadingContactId)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":"","size":"20","width":"2"}}):(
                        !_vm.isContactIdSelected &&
                        _vm.contactData.collegiateNumber &&
                        !_vm.rulesFails
                      )?_c('v-icon',{attrs:{"right":"","color":_vm.validContactId ? 'green' : 'red'}},[_vm._v(" "+_vm._s(_vm.validContactId ? 'mdi-check' : 'mdi-close')+" ")]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.contactData.collegiateNumber),callback:function ($$v) {_vm.$set(_vm.contactData, "collegiateNumber", $$v)},expression:"contactData.collegiateNumber"}})],1)],_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('nameOrNickname'),"rules":_vm.contactNameRules,"prepend-icon":"mdi-account-outline","dense":""},model:{value:(_vm.contactData.customName),callback:function ($$v) {_vm.$set(_vm.contactData, "customName", $$v)},expression:"contactData.customName"}})],1)],2)],1)],1)],2),_c('v-card-actions',{staticClass:"headline justify-center"},[_c('v-btn',{staticClass:"red lighten-4",attrs:{"outlined":"","rounded":"","text":"","width":"150px","disabled":_vm.loading},on:{"click":function($event){return _vm.resetForm(true)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{staticClass:"green lighten-4",attrs:{"outlined":"","rounded":"","text":"","width":"150px","disabled":!_vm.valid ||
          (!_vm.validContactForm && !!_vm.contactData.idNumber) ||
          _vm.loading ||
          _vm.loadingContactId,"loading":_vm.loading},on:{"click":_vm.performAction},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }