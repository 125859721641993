<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <UserList :users="users" :loadingTableData="loadingTableData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listUsers } from '@/services/user-service'
import UserList from '@/components/user/UserList'

export default {
  components: {
    UserList,
  },
  data() {
    return {
      loadingTableData: true,
      users: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    }
  },
  async mounted() {
    const usersList = await listUsers()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.users = usersList
    this.loadingTableData = false
  },
}
</script>
