<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <RecordList :records="records" :loadingTableData="loadingTableData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listRecords } from '@/services/record-service'
import RecordList from '@/components/record/RecordList'

export default {
  components: {
    RecordList,
  },
  data() {
    return {
      loadingTableData: true,
      records: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    }
  },
  async mounted() {
    const recordsList = await listRecords()
    await new Promise((resolve) => setTimeout(resolve, 500))
    this.records = recordsList

    this.loadingTableData = false
  },
}
</script>
