<template>
  <v-card class="mx-auto" outlined height="425">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('userCertificationTypes') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('manageUserCertificationTypes') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-form ref="typesForm" v-model="validCertificationType" lazy-validation>
      <v-container>
        <v-row align="center" justify="center">
          <v-col :cols="isMobile ? 9 : 10">
            <v-list-item>
              <v-text-field
                v-model="newCertificationType"
                :label="$t('newCertificationType')"
                :rules="certificationTypeRules"
                :disabled="loading"
              ></v-text-field>
            </v-list-item>
          </v-col>
          <v-col :cols="isMobile ? 3 : 2">
            <v-list-item>
              <v-btn
                :disabled="loading || !validCertificationType"
                @click="addNewCertificationType()"
                class="primary darkColor--text"
                fab
                small
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-list height="255" class="overflow-y-auto">
      <AppSkeleton :condition="loading" type="customCertificationTypes">
        <template v-slot:skeleton-content>
          <v-list-item>
            <v-list-item-content>
              <v-chip
                v-for="item in certificationTypes"
                :key="item.name"
                close
                @click:close="deleteElement(item)"
                class="mb-2 chipCustomCertificationType"
                style="height: 45px"
              >
                <v-list-item-title style="width: 100%">
                  {{ item.name }}
                </v-list-item-title>
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </AppSkeleton>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  listUserCertificationTypes,
  createCustomCertificationType,
  deleteCustomCertificationType,
} from '@/services/certification-type-service'
import rules from '@/utils/rules'

export default {
  async mounted() {
    const certificationTypesList = await listUserCertificationTypes()
    await new Promise((resolve) => setTimeout(resolve, 500))
    this.certificationTypes = certificationTypesList
    this.loading = false
  },
  computed: {
    ...mapGetters(['isMobile']),
    certificationTypeRules() {
      return [
        rules.required,
        rules.certificationTypeNotExists(this.certificationTypes),
      ]
    },
  },
  data() {
    return {
      loading: true,
      certificationTypes: [],
      newCertificationType: '',
      validCertificationType: true,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async getUserCertificationTypes() {
      this.certificationTypes = await listUserCertificationTypes()
    },
    async deleteElement(item) {
      try {
        await deleteCustomCertificationType(item.id)

        const message = this.$t('deletingCertificationTypeSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t('deletingCertificationTypeError')
        this.setSnackbar({ position: 'top', message })
      }

      this.certificationTypes = await listUserCertificationTypes()
    },
    async addNewCertificationType() {
      if (!this.$refs.typesForm.validate()) return

      try {
        await createCustomCertificationType({ name: this.newCertificationType })
        this.newCertificationType = ''
        this.$refs.typesForm.resetValidation()

        const message = this.$t('creatingCertificationTypeSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t('creatingCertificationTypeError')
        this.setSnackbar({ position: 'top', message })
      }

      this.certificationTypes = await listUserCertificationTypes()
    },
  },
}
</script>

<style lang="scss">
.chipCustomCertificationType > .v-chip__content {
  width: 100%;
}
</style>
