import {
  Firestore,
  CertificationsCollection,
  CertificationsDoc,
  CertificationsWhitelistCollection,
  CertificationsHistoryCollection,
  UsersDoc,
  GetDocFromReference,
} from '@/firebase-exports'
import store from '@/store'

export function newCertificationId() {
  return Firestore.doc(CertificationsCollection).id
}

export async function createCertification(id, certification) {
  const certificationInput = {
    ...certification,
    createdAt: Firestore.serverTimestamp(),
    clientRef: UsersDoc(certification.client),
    creatorRef: UsersDoc(certification.creator),
  }
  delete certificationInput.client
  delete certificationInput.creator

  return await Firestore.setDoc(CertificationsDoc(id), certificationInput)
}

export async function listCertifications() {
  const querySnapshot = await Firestore.getDocs(
    Firestore.query(
      CertificationsCollection,
      Firestore.where('creatorRef', '==', UsersDoc(store.state.user.id)),
      Firestore.orderBy('createdAt', 'desc')
    )
  )
  return await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.createdAt = new Date(document.createdAt.toDate())
      document.client = await GetDocFromReference(document.clientRef)
      document.creator = await GetDocFromReference(document.creatorRef)
      return { id: doc.id, ...document }
    })
  )
}

export async function getCertification(id, context) {
  var certification = undefined
  const docSnap = await Firestore.getDoc(CertificationsDoc(id))
  const document = docSnap.data()
  if (document.blockchainRef) {
    document.blockchain = await GetDocFromReference(document.blockchainRef)
  }
  document.createdAt = new Date(document.createdAt.toDate())
  document.client = await GetDocFromReference(document.clientRef)
  document.creator = await GetDocFromReference(document.creatorRef)
  document.creator.association = await GetDocFromReference(
    document.creator.associationRef
  )
  document.history = []
  document.historyUnsubscribe = await getCertificationHistorySubscription(
    id,
    context,
    document.creatorRef.id === store.state.user.id
  )
  document.whitelist = []
  document.whitelistUnsubscribe =
    document.creatorRef.id === store.state.user.id
      ? await getCertificationWhitelistSubscription(id, context)
      : () => {}

  if (document) certification = { id, ...document }

  return certification
}

async function getCertificationHistorySubscription(id, context, isCreator) {
  const q = isCreator
    ? Firestore.query(
        CertificationsHistoryCollection(id),
        Firestore.orderBy('createdAt', 'desc')
      )
    : Firestore.query(
        CertificationsHistoryCollection(id),
        Firestore.orderBy('createdAt', 'desc'),
        Firestore.where('userRef', '==', UsersDoc(store.state.user.id))
      )
  var historyUnsubscribe = Firestore.onSnapshot(
    q,
    async (querySnapshot) => {
      context.certification.history = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          document.createdAt = new Date(document.createdAt.toDate())
          if (document.blockchainRef) {
            document.blockchain = await GetDocFromReference(
              document.blockchainRef
            )
          }
          if (isCreator) {
            document.user = await GetDocFromReference(document.userRef)
            document.user.association = await GetDocFromReference(
              document.user.associationRef
            )
          }
          return { id: doc.id, ...document }
        })
      )
    },
    (error) => {
      throw error
    }
  )
  return historyUnsubscribe
}

async function getCertificationWhitelistSubscription(id, context) {
  const q = Firestore.query(
    CertificationsWhitelistCollection(id),
    Firestore.orderBy('read', 'desc')
  )
  var whitelistUnsubscribe = Firestore.onSnapshot(
    q,
    async (querySnapshot) => {
      context.certification.whitelist = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          document.user = await GetDocFromReference(document.userRef)
          return { id: doc.id, ...document }
        })
      )
    },
    (error) => {
      throw error
    }
  )
  return whitelistUnsubscribe
}
