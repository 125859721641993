<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0" align="center" justify="center">
      <v-btn
        block
        :disabled="loading"
        :loading="loading"
        outlined
        @click="generateReport"
      >
        <v-icon left> mdi-printer-outline </v-icon>
        {{ $t('print') }}
        <template v-slot:loader>
          <v-progress-circular indeterminate size="20" width="2" />
          <span class="ml-2">{{ $t('creatingPrintable') }}</span>
        </template>
      </v-btn>
    </v-row>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :paginate-elements-by-height="1100"
      :filename="`${certification.id}-printable`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
    >
      <section slot="pdf-content">
        <div id="fileContainer" name="fileContainer">
          <img src="@/assets/app-logo.png" height="50" />
          <h1>
            <b>{{ $t('certification') }}</b>
            {{ certification.id }}
          </h1>
          <br />
          <h4 v-if="$store.state.user">
            <span>{{ $t('solicitedBy') }}</span>
            :
            {{ $store.state.user.name }}
          </h4>
          <h4>
            <span>{{ $t('solicitedDate') }}</span>
            :
            {{ $d(new Date(), 'i18nDateAndHourSeconds') }}
          </h4>
        </div>
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <h3>
            <b>{{ certification.type }}</b> {{ certification.id }}
          </h3>
          <br />
          <div style="padding-left: 20px">
            <p>
              {{ $t('printIntroduction') }}
            </p>
          </div>
        </div>
        <hr class="dotted" />
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <h3>
            {{ $t('certificationDetails') }}
          </h3>
          <br />
          <div style="padding-left: 20px">
            <p>
              <b>{{ $t('type') }}:</b>
              {{ certification.type }}
            </p>
            <p>
              <b>{{ $t('subject') }}:</b> {{ certification.description }}
            </p>
            <p v-if="certification.creator">
              <b>{{ $t('creator') }}:</b>
              {{ certification.creator.idNumber }} -
              {{ certification.creator.name }} -
              {{ certification.creator.email }}
            </p>
            <p v-if="certification.client">
              <b>{{ $t('client') }}:</b>
              {{ certification.client.idNumber }} -
              {{ certification.client.name }} -
              {{ certification.client.email }}
            </p>
          </div>
        </div>
        <hr class="dotted" />
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <h3>
            {{ $t('cryptoData') }}
          </h3>
          <br />
          <div style="padding-left: 20px">
            <p>
              <b>{{ $t('fileHash') }}:</b> {{ certification.hash }}
            </p>
            <p>
              <b>{{ $t('url') }}:</b> {{ certificationFile.url }}
            </p>
            <p>
              <b>{{ $t('filteType') }}:</b>
              {{ certificationFile.metadata.contentType }}
            </p>
            <p
              v-if="
                certification.blockchain &&
                certification.blockchain.registeredBlockchain
              "
            >
              <b>{{ $t('blockchainHash') }}:</b>
              {{ certification.blockchain.hash }}
            </p>
            <p
              v-if="
                certification.blockchain &&
                certification.blockchain.registeredBlockchain
              "
            >
              <b>{{ $t('blockchainNetwork') }}:</b>
              {{ getBlockchainNetworkName(certification.blockchain.chainId) }}
              ({{ certification.blockchain.chainId }})
            </p>
            <p
              v-if="
                certification.blockchain &&
                certification.blockchain.registeredBlockchain
              "
            >
              <b>{{ $t('blockchainTransaction') }}:</b>
              <a
                style="text-decoration: none; color: inherit"
                :href="
                  getBlockchainLink(
                    certification.blockchain.chainId,
                    certification.blockchain.transactionHash
                  )
                "
              >
                {{ certification.blockchain.transactionHash }}
              </a>
            </p>
          </div>
        </div>
        <hr class="dotted" />
        <template v-if="showHistory">
          <div
            id="certificationInfoNoBackground"
            name="certificationInfoNoBackground"
            style="padding-bottom: 0"
          >
            <h3>{{ $t('sharedWith') }}:</h3>
          </div>
          <div
            id="certificationInfoNoBackground"
            name="certificationInfoNoBackground"
            style="padding-top: 10px; padding-bottom: 0"
            v-for="(value, key) in certification.whitelist"
            v-bind:key="`whitelist-${key}`"
          >
            <div style="padding-left: 20px">
              <span>
                <b>{{ value.user.idNumber }} - </b> {{ value.user.name }}
                <span>({{ $t(value.read ? 'read' : 'notRead') }})</span>
              </span>
            </div>
          </div>
          <div
            id="certificationInfoNoBackground"
            name="certificationInfoNoBackground"
            style="padding-bottom: 0"
          >
            <h3>{{ $t('historyDetail') }}:</h3>
          </div>
          <div
            id="certificationInfoNoBackground"
            name="certificationInfoNoBackground"
            style="padding-top: 15px; padding-bottom: 15px"
            v-for="(value, key) in certification.history.slice().reverse()"
            v-bind:key="`history-${key}`"
          >
            <div style="padding-left: 20px">
              <span>
                {{ $d(value.createdAt, 'i18nDateAndHourSeconds') }}
                -
                <b>{{ $t(value.actionType) }}</b>
                <span v-if="value.user">
                  <br /><b>{{ $t('client') }}:</b>
                  <template
                    v-if="value.user.collegiateNumber && value.user.association"
                  >
                    {{ value.user.collegiateNumber }} ({{
                      value.user.association.name
                    }})
                  </template>
                  <template v-else>
                    {{ value.user.idNumber }}
                  </template>
                  / {{ value.user.email }}
                  <template
                    v-if="
                      value.blockchain && value.blockchain.registeredBlockchain
                    "
                  >
                    <br />
                    <b>{{ $t('blockchain') }}:</b>
                    {{ getBlockchainNetworkName(value.blockchain.chainId) }} ({{
                      value.blockchain.chainId
                    }}) /
                    <a
                      style="text-decoration: none; color: inherit"
                      :href="
                        getBlockchainLink(
                          value.blockchain.chainId,
                          value.blockchain.transactionHash
                        )
                      "
                    >
                      {{ value.blockchain.transactionHash }}
                    </a>
                  </template>
                </span>
              </span>
            </div>
          </div>
        </template>
      </section>
    </VueHtml2pdf>
  </v-container>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { getNetworkName, getTransactionLink } from '@/utils/blockchain-utils'

export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    certification: Object,
    certificationFile: Object,
    showHistory: Boolean,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    generateReport() {
      this.loading = true
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      options.enableLinks = true
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            pdf.setPage(currentPage)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(
              this.$t('pageNumber', { currentPage, totalPages }),
              pdf.internal.pageSize.getWidth() * 0.8,
              pdf.internal.pageSize.getHeight() - 0.3
            )
            pdf.text(
              `${this.$t('certification')} ${this.certification.id}`,
              pdf.internal.pageSize.getWidth() * 0.05,
              pdf.internal.pageSize.getHeight() - 0.3
            )
          }
        })
        .save()
      this.loading = false
    },
    getBlockchainNetworkName(chainId) {
      return getNetworkName(chainId)
    },
    getBlockchainLink(chainId, transactionHash) {
      return getTransactionLink(chainId, transactionHash)
    },
  },
}
</script>

<style lang="scss" scoped>
#fileContainer {
  width: 100%;
  background-color: #1e355e;
  padding: 30px;
  color: #ecf0f1;
  font-family: 'Courier New', monospace;
}
#certificationInfo {
  width: 100%;
  background-color: #ecf0f1;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  color: #1e355e;
  font-family: 'Courier New', monospace;
}
#certificationInfoNoBackground {
  width: 100%;
  background-color: white;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  color: #1e355e;
  font-family: 'Courier New', monospace;
}
hr.dotted {
  border: 1px dashed #1e355e;
}
</style>
