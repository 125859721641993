<template>
  <v-card flat>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card-subtitle>
            <AppSkeleton
              v-for="field in procedureAttributes"
              :condition="!procedure[field.value]"
              type="procedureAttributes"
              :key="field.value"
            >
              <template v-slot:skeleton-content>
                <span v-if="field.value === 'idNumber'">
                  <template v-if="procedure.isLegalEntity">
                    <v-icon class="mr-2"> mdi-domain </v-icon>
                    {{ procedure.legalIdNumber }}
                    <br />
                    <v-icon class="mr-2"> mdi-alphabetical </v-icon>
                    {{ procedure.legalName }}
                    <br />
                  </template>
                  <v-icon class="mr-2"> {{ field.icon }} </v-icon>
                  {{ procedure[field.value] }}
                  <br />
                </span>
                <span v-else>
                  <v-icon class="mr-2"> {{ field.icon }} </v-icon>
                  <template v-if="field.value === 'createdAt'">
                    {{ $d(procedure[field.value], 'i18nDateAndHourSeconds') }}
                  </template>
                  <template v-else>
                    {{ procedure[field.value] }}
                  </template>
                  <template v-if="field.value === 'phonePrefix'">
                    ({{ $t(prefixCountries[procedure[field.value]]) }})
                  </template>
                  <br />
                </span>
              </template>
            </AppSkeleton>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    procedure: Object,
  },
  data() {
    return {
      procedureAttributes: [
        { icon: 'mdi-card-account-details-outline', value: 'idNumber' },
        { icon: 'mdi-alphabetical', value: 'name' },
        { icon: 'mdi-at', value: 'email' },
        { icon: 'mdi-phone-dial-outline', value: 'phonePrefix' },
        { icon: 'mdi-phone-outline', value: 'phoneNumber' },
        { icon: 'mdi-calendar-range', value: 'createdAt' },
      ],
      prefixCountries: {
        '+34': 'countries.spain',
        '+351': 'countries.portugal',
        '+31': 'countries.netherlands',
        '+49': 'countries.germany',
        '+7': 'countries.rusia',
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
