import i18n from '@/i18n'
import validIdNumber from '@/utils/validate-id-number'

export default {
  // COMMON
  required: (value) => !!value || i18n.t('requiredField'),
  maxCharacters: (maxLength) => {
    return (value) =>
      (value && value.length <= maxLength) || i18n.t('overCharacters')
  },

  // USER
  idNumber: (value) =>
    (value && validIdNumber(value)) || i18n.t('notValidTaxNumberError'),
  firstName: (value) => {
    const regex = /^[a-zA-Z]+(?:[\s][a-zA-Z]+)*$/
    return !value || regex.test(value) || i18n.t('firstNameInvalid')
  },
  lastName: (value) => {
    const regex = /^[a-zA-Z]+(?:[\s][a-zA-Z]+)+$/
    return !value || regex.test(value) || i18n.t('lastNameInvalid')
  },
  email: (value) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !value || regex.test(value) || i18n.t('mailInvalid')
  },
  phonePrefix: (value) =>
    (!!value.prefix &&
      !!value.state &&
      value.prefix.length > 0 &&
      value.state.length > 0) ||
    i18n.t('requiredField'),

  // CERTIFICATION
  file: (value) => !!value || i18n.t('missingFileError'),

  // CONTACT
  contactNotExists: (contacts, generatedIdNumber) => {
    return (value) =>
      (value &&
        !contacts.some(
          (contact) => contact.user.idNumber === (generatedIdNumber || value)
        )) ||
      i18n.t('contactAlreadyExists')
  },
  contactNotYourself: (idNumber, generatedIdNumber) => {
    return (value) =>
      (value && (generatedIdNumber || value) !== idNumber) ||
      i18n.t('cannotAddYourself')
  },
  number: (value) => {
    const regex = /^[0-9]\d*$/
    return !value || regex.test(value) || i18n.t('notValidNumberError')
  },

  // GROUP
  groupNameNotExists: (groups, editCustomName) => {
    return (value) => {
      if (!value) return true
      const alreadyExists = groups.some(
        (group) =>
          group.customName.toLowerCase() === value.toLowerCase() &&
          (editCustomName
            ? editCustomName.toLowerCase() !== value.toLowerCase()
            : true)
      )
      return (value && !alreadyExists) || i18n.t('groupAlreadyExists')
    }
  },
  groupContactsNotEmpty: (value) =>
    (value && value.length !== 0) || i18n.t('requiredField'),

  // CUSTOM TYPES
  certificationTypeNotExists: (certificationTypes) => {
    return (value) =>
      (value &&
        !certificationTypes.some(
          (type) => type.name.toLowerCase() === value.toLowerCase()
        )) ||
      i18n.t('certificationTypeAlreadyExists')
  },
  recordSubjectTypeNotExists: (recordSubjectTypes) => {
    return (value) =>
      (value &&
        !recordSubjectTypes.some(
          (type) => type.name.toLowerCase() === value.toLowerCase()
        )) ||
      i18n.t('recordSubjectTypeAlreadyExists')
  },
  recordFileTypeNotExists: (recordFileTypes) => {
    return (value) =>
      (value &&
        !recordFileTypes.some(
          (type) => type.name.toLowerCase() === value.toLowerCase()
        )) ||
      i18n.t('recordFileTypeAlreadyExists')
  },

  // SETTINGS
  passwordLength: (value) =>
    (value && value === undefined) ||
    (value && value.length >= 6) ||
    i18n.t('passRequirements'),
  passwordNotRepeated: (newPassword) => {
    return (value) =>
      (value && value === newPassword) || i18n.t('incorrectRepeatPassword')
  },
}
