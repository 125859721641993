<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8">
        <v-row no-gutters>
          <v-col cols="12">
            <ProcedureDetailTitle :procedure="procedure" />
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
          <v-col cols="12">
            <ProcedureDetailAttributes :procedure="procedure" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="6">
            <ProcedureDetailIssuer :user="procedure.creator" type="Client" />
          </v-col>
          <v-col cols="12" lg="6">
            <ProcedureDetailIssuer :user="procedure.creator" type="Lawyer" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <ProcedureDetailActions
              :procedure="procedure"
              :procedureFile="procedureFile"
              :changeStatusAllowed="isCreator"
              @updateProcedureStatus="updateProcedureStatus"
              @updateProcedureRejectReason="updateProcedureRejectReason"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getFileURL, getFileMetadata } from '@/services/storage-service'
import { getProcedure } from '@/services/procedure-service'
import ProcedureDetailTitle from '@/components/procedure/detail/ProcedureDetailTitle'
import ProcedureDetailAttributes from '@/components/procedure/detail/ProcedureDetailAttributes'
import ProcedureDetailActions from '@/components/procedure/detail/ProcedureDetailActions'
import ProcedureDetailIssuer from '@/components/procedure/detail/ProcedureDetailIssuer'

export default {
  components: {
    ProcedureDetailTitle,
    ProcedureDetailAttributes,
    ProcedureDetailActions,
    ProcedureDetailIssuer,
  },
  async mounted() {
    const procedureGet = await getProcedure(this.$route.params.id, this)

    if (!procedureGet) return this.setNotFound(true)

    if (procedureGet.file) {
      var url = await getFileURL(procedureGet.file)
      var metadata = await getFileMetadata(procedureGet.file)
      var metadataFile = {
        fullPath: metadata.fullPath,
        contentType: metadata.contentType,
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.procedure = procedureGet
    if (procedureGet.file) {
      this.procedureFile = { url, metadata: metadataFile }
    } else {
      this.procedureFile = {}
    }
  },
  data() {
    return {
      procedure: {},
      procedureFile: undefined,
    }
  },
  methods: {
    ...mapMutations(['setNotFound']),
    updateProcedureStatus(destinationStatus) {
      this.procedure.status = destinationStatus
    },
    updateProcedureRejectReason(rejectReason) {
      this.procedure.rejectReason = rejectReason
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    isCreator() {
      if (!this.procedure.creator) return
      return this.procedure.creator.idNumber === this.$store.state.user.idNumber
    },
  },
}
</script>

<style lang="scss" scoped></style>
