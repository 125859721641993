import {
  Auth,
  Firestore,
  GetDocFromReference,
  UsersDoc,
} from '@/firebase-exports'
import { listNotificationsSubscription } from '@/services/notification-service'
import overrideRoles from '@/utils/roles-override'
import getUserPages from '@/utils/get-user-pages'
import { updateUserRolesFinal } from '@/services/user-service'

const actions = {
  async fetchUser({ commit, dispatch, state }) {
    try {
      const firebaseUser = await Auth.getAuth().currentUser
      Auth.getAuth().currentUser.getIdToken(false)
      if (firebaseUser) {
        var userUnsubscribe = Firestore.onSnapshot(
          UsersDoc(firebaseUser.uid),
          async (docSnap) => {
            var firestoreUser = docSnap.data()
            firestoreUser.id = firebaseUser.uid

            var redirect =
              !!state.user && state.user.roleRef.id !== firestoreUser.roleRef.id

            if (firestoreUser.roleUnsubscribe) firestoreUser.roleUnsubscribe()

            if (firestoreUser.roleRef) {
              firestoreUser.roleUnsubscribe = Firestore.onSnapshot(
                firestoreUser.roleRef,
                async (docSnap) => {
                  var firestoreCopy = { ...firestoreUser }
                  firestoreCopy.role = overrideRoles(
                    docSnap.data(),
                    firestoreCopy.rolesOverride
                  )

                  if (firestoreUser.parentUnsubscribe)
                    firestoreUser.parentUnsubscribe()
                  if (firestoreUser.parentRoleUnsubscribe)
                    firestoreUser.parentRoleUnsubscribe()

                  if (firestoreUser.parentRef) {
                    firestoreUser.parentUnsubscribe = Firestore.onSnapshot(
                      firestoreUser.parentRef,
                      async (parent) => {
                        parent = parent.data()

                        var parentRole = await GetDocFromReference(
                          parent.roleRef
                        )
                        const proceduresRole = parent.rolesOverride
                          ? overrideRoles(
                              parentRole.procedures,
                              parent.rolesOverride.procedures
                            )
                          : parentRole.procedures
                        firestoreCopy.role.procedures = { ...proceduresRole }
                      }
                    )
                    const parent = await GetDocFromReference(
                      firestoreUser.parentRef
                    )
                    firestoreUser.parentRoleUnsubscribe = Firestore.onSnapshot(
                      parent.roleRef,
                      async (parentRole) => {
                        parentRole = parentRole.data()
                        const proceduresRole = parent.rolesOverride
                          ? overrideRoles(
                              parentRole.procedures,
                              parent.rolesOverride.procedures
                            )
                          : parentRole.procedures
                        firestoreCopy.role.procedures = { ...proceduresRole }
                      }
                    )
                  }
                  await updateUserRolesFinal(
                    firestoreCopy.id,
                    firestoreCopy.role
                  )
                  dispatch('configureUser', firestoreCopy)

                  await new Promise((resolve) => setTimeout(resolve, 3000))

                  if (firestoreCopy.role.notifications.list.issued) {
                    commit(
                      'setUnsubscribeIssuedNotification',
                      await listNotificationsSubscription(
                        firestoreCopy.id,
                        'issued'
                      )
                    )
                  }
                  if (firestoreCopy.role.notifications.list.received) {
                    commit(
                      'setUnsubscribeReceivedNotification',
                      await listNotificationsSubscription(
                        firestoreCopy.id,
                        'received'
                      )
                    )
                  }

                  if (redirect) {
                    commit('setRedirectPath', {
                      path: docSnap.data().loginRedirect,
                    })
                    redirect = false
                  }
                }
              )
            } else {
              throw new Error('Current User has NO Role Reference')
            }

            const tokenExpiration = firebaseUser.stsTokenManager.expirationTime
            const currentDate = new Date().getTime()
            const expirationTimeout = tokenExpiration - currentDate
            setTimeout(async () => {
              await dispatch('fetchUser')
            }, expirationTimeout)
          },
          (error) => {
            throw error
          }
        )
        return userUnsubscribe
      } else {
        throw new Error('User is not authenticated')
      }
    } catch (err) {
      dispatch('configureUser', null)
      throw err
    }
  },
  async configureUser({ commit }, userData) {
    commit('setUser', userData)

    if (!userData) commit('setNavigationPages', {})
    else if (userData.role) commit('setNavigationPages', getUserPages())
  },
  async logout({ dispatch }) {
    await Auth.signOut(Auth.getAuth())
    dispatch('configureUser', null)
  },
}

export default actions
