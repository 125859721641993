<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            {{ $t('groups') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('groupsManage') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-btn
          class="primary darkColor--text"
          fab
          small
          @click="openGroupDialog('create')"
          :disabled="loading"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            clearable
            :label="$t('searchGroup')"
            single-line
            hide-details
            :disabled="loading"
          />
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <AppSkeleton :condition="loading" type="groupList">
        <template v-slot:skeleton-content>
          <v-list height="272" class="overflow-y-auto">
            <template v-if="groups.length !== 0">
              <v-container v-if="!filteredGroups.length" fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col cols="12" align="center" justify="center">
                    {{ $t('emptySearch') }}
                  </v-col>
                </v-row>
              </v-container>
              <v-list-item v-else v-for="(item, i) in filteredGroups" :key="i">
                <v-list-item-icon class="my-3 mr-4">
                  <AppAvatar
                    :userName="item.customName"
                    userRole="group"
                    isGroup
                    avatarSize="40"
                    iconSize="24"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.customName }}
                  </v-list-item-title>
                  <v-list-item-title class="subtitle font-weight-bold">
                    {{ $tc('contactCount', item.contacts.length) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon @click="openGroupDialog('update', item)">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon @click="openGroupDialog('delete', item)">
                    mdi-delete
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-container v-else fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  {{ $t('noGroupsAssigned') }}
                </v-col>
              </v-row>
            </v-container>
          </v-list>
        </template>
      </AppSkeleton>
    </v-card>
    <GroupDialog
      :showDialog="showDialog"
      :actionDialog="actionDialog"
      :contacts="contacts"
      :groups="groups"
      :groupProp="groupData"
      @closeGroupDialog="closeGroupDialog"
      @confirmGroupDialog="confirmGroupDialog"
    />
  </div>
</template>

<script>
import GroupDialog from '@/components/group/GroupDialog'

export default {
  props: {
    contacts: Array,
    groups: Array,
    loading: Boolean,
  },
  components: {
    GroupDialog,
  },
  data() {
    return {
      search: '',
      showDialog: false,
      actionDialog: '',
      groupData: {},
    }
  },
  methods: {
    openGroupDialog(action, item) {
      if (action === 'create') {
        this.groupData = {}
      } else if (action === 'update') {
        this.groupData = {
          id: item.id,
          customName: item.customName,
          contacts: item.contacts.map((contact) => contact.id),
        }
      } else if (action === 'delete') {
        this.groupData = item
      }
      this.showDialog = true
      this.actionDialog = action
    },
    closeGroupDialog() {
      this.showDialog = false
      this.groupData = {}
    },
    async confirmGroupDialog() {
      this.showDialog = false
      this.actionDialog = ''
      this.$emit('reloadElements')
    },
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((item) => {
        if (!this.search) return this.groups
        return item.customName.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
}
</script>

<style lang="scss"></style>
