<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        :sm="recordFile.fileId ? 9 : 12"
        :md="recordFile.fileId ? 9 : 12"
        cols="12"
      >
        <v-row>
          <v-col cols="12">
            <RecordDetailTitle
              :asset="record"
              :loaded="loaded"
              :isCreator="isCreator"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <RecordDocumentList
              @record-file-created="getRecord"
              @select-row="openFile"
              :fileRefs="record.files"
              :client="record.client"
              :record="record"
              :showUsers="isCreator"
              :isCreator="isCreator"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="recordFile.fileId" cols="12" sm="3">
        <v-card>
          <v-row justify="end" align="end" no-gutters>
            <v-btn class="ma-2" x-small color="red" dark @click="closeFile">
              {{ $t('closeFile') }}
            </v-btn>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col v-if="openedFile.url" cols="12" sm="12">
              <CertificationDetailFile
                :certificationFile="openedFile"
                height="200px"
              />
            </v-col>
            <v-col class="pl-3" cols="12" sm="12">
              <v-btn block outlined @click="downloadFile">
                <v-icon left> mdi-download </v-icon>
                {{ $t('download') }}
              </v-btn>
            </v-col>
            <br /><br />
            <v-col class="pl-3" cols="12" sm="12">
              <span>
                <v-icon class="mr-2"> mdi-lock-check </v-icon>
                <b>{{ $t('hash') }}: </b>
                {{ recordFile.hash }}
              </span>
            </v-col>
            <v-col class="pl-3" cols="12" sm="12">
              <span>
                <v-icon class="mr-2"> mdi-form-textbox </v-icon>
                <b>{{ $t('recordFileName') }}: </b>
                {{ recordFile.name }}
              </span>
            </v-col>
            <v-col class="pl-3" cols="12" sm="12">
              <span>
                <v-icon class="mr-2"> mdi-file-document </v-icon>
                <b>{{ $t('filteType') }}: </b>
                {{ recordFile.type }}
              </span>
            </v-col>
            <v-col class="pl-3" cols="12" sm="12">
              <span>
                <v-icon class="mr-2"> mdi-text </v-icon>
                <b>{{ $t('descriptionInput') }}: </b>
                {{ recordFile.description }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-tabs centered v-model="tab">
                <v-tab v-if="isCreator">{{ $t('sharedWith') }}</v-tab>
                <v-tab>{{ $t('historyDetail') }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-if="isCreator && recordFile.whitelist">
                  <AssetDetailWhitelist
                    :showTitle="false"
                    :users="recordFile.whitelist"
                    :showUsers="isCreator"
                  />
                </v-tab-item>
                <v-tab-item>
                  <AssetDetailHistory
                    v-if="recordFile.history"
                    :showTitle="false"
                    :history="recordFile.history"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import RecordDocumentList from '@/components/record/detail/RecordDocumentList'
import RecordDetailTitle from '@/components/record/detail/RecordDetailTitle'
import CertificationDetailFile from '@/components/certification/detail/CertificationDetailFile'
import AssetDetailWhitelist from '@/components/commons/detail/AssetDetailWhitelist'
import AssetDetailHistory from '@/components/commons/detail/AssetDetailHistory'

import { saveAs } from 'file-saver'
import { getBlob } from '@/services/storage-service'
import { getRecord, getRecordFile } from '@/services/record-service'
import { getFileURL, getFileMetadata } from '@/services/storage-service'

export default {
  components: {
    RecordDocumentList,
    CertificationDetailFile,
    RecordDetailTitle,
    AssetDetailWhitelist,
    AssetDetailHistory,
  },
  async mounted() {
    await this.getRecord()
  },
  destroyed() {
    /* TODO
    this.recordFile.historyUnsubscribe()
    this.recordFile.whitelistUnsubscribe()
    */
  },
  data() {
    return {
      loaded: false,
      record: {},
      recordFile: {},
      openedFile: {},
      tab: 0,
    }
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    async openFile(value) {
      this.recordFile = await getRecordFile(this.record.id, value.id, this)

      const url = await getFileURL(this.recordFile.file)
      const metadata = await getFileMetadata(this.recordFile.file)

      var metadataFile = {
        fullPath: metadata.fullPath,
        contentType: metadata.contentType,
      }

      this.openedFile = { url, metadata: metadataFile }
    },
    closeFile() {
      this.recordFile = {}
      this.openedFile = {}
    },
    async getRecord() {
      const recordGet = await getRecord(this.$route.params.id, this)
      if (!recordGet) return this.setNotFound(true)
      await new Promise((resolve) => setTimeout(resolve, 1000))
      this.record = recordGet
      this.loaded = true
    },
    async downloadFile() {
      try {
        const blob = await getBlob(this.recordFile.file)
        const fileName = this.recordFile.name
        saveAs(blob, fileName)
      } catch (err) {
        const message = this.$t('downloadingFileError')
        this.setSnackbar({ position: 'top', message })
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    isCreator() {
      if (!this.record.creator) return
      return this.record.creator.idNumber === this.$store.state.user.idNumber
    },
  },
}
</script>

<style lang="scss" scoped></style>
