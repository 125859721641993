<template>
  <v-card class="mx-auto" outlined height="425">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('userRecordFileTypes') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('manageUserRecordFileTypes') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-form ref="typesForm" v-model="validRecordFileType" lazy-validation>
      <v-container>
        <v-row align="center" justify="center">
          <v-col :cols="isMobile ? 9 : 10">
            <v-list-item>
              <v-text-field
                v-model="newRecordFileType"
                :label="$t('newRecordFileType')"
                :rules="recordFileTypeRules"
                :disabled="loading"
              ></v-text-field>
            </v-list-item>
          </v-col>
          <v-col :cols="isMobile ? 3 : 2">
            <v-list-item>
              <v-btn
                :disabled="loading || !validRecordFileType"
                @click="addNewRecordFileType()"
                class="primary darkColor--text"
                fab
                small
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-list height="255" class="overflow-y-auto">
      <AppSkeleton :condition="loading" type="customRecordFileTypes">
        <template v-slot:skeleton-content>
          <v-list-item>
            <v-list-item-content>
              <v-chip
                v-for="item in recordFileTypes"
                :key="item.name"
                close
                @click:close="deleteElement(item)"
                class="mb-2 chipCustomRecordFileType"
                style="height: 45px"
              >
                <v-list-item-title style="width: 100%">
                  {{ item.name }}
                </v-list-item-title>
              </v-chip>
            </v-list-item-content>
          </v-list-item>
        </template>
      </AppSkeleton>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  listUserRecordFileTypes,
  createCustomRecordFileType,
  deleteCustomRecordFileType,
} from '@/services/record-type-service'
import rules from '@/utils/rules'

export default {
  async mounted() {
    const recordFileTypesList = await listUserRecordFileTypes()
    await new Promise((resolve) => setTimeout(resolve, 500))
    this.recordFileTypes = recordFileTypesList
    this.loading = false
  },
  computed: {
    ...mapGetters(['isMobile']),
    recordFileTypeRules() {
      return [
        rules.required,
        rules.recordFileTypeNotExists(this.recordFileTypes),
      ]
    },
  },
  data() {
    return {
      loading: true,
      recordFileTypes: [],
      newRecordFileType: '',
      validRecordFileType: true,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async getUserRecordFileTypes() {
      this.recordFileTypes = await listUserRecordFileTypes()
    },
    async deleteElement(item) {
      try {
        await deleteCustomRecordFileType(item.id)

        const message = this.$t('deletingRecordFileTypeSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t('deletingRecordFileTypeError')
        this.setSnackbar({ position: 'top', message })
      }

      this.recordFileTypes = await listUserRecordFileTypes()
    },
    async addNewRecordFileType() {
      if (!this.$refs.typesForm.validate()) return

      try {
        await createCustomRecordFileType({ name: this.newRecordFileType })
        this.newRecordFileType = ''
        this.$refs.typesForm.resetValidation()

        const message = this.$t('creatingRecordFileTypeSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t('creatingRecordFileTypeError')
        this.setSnackbar({ position: 'top', message })
      }

      this.recordFileTypes = await listUserRecordFileTypes()
    },
  },
}
</script>

<style lang="scss">
.chipCustomRecordFileType > .v-chip__content {
  width: 100%;
}
</style>
