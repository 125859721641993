<template>
  <v-card class="mx-auto" outlined elevation="20">
    <v-stepper v-model="step">
      <v-stepper-header>
        <template v-for="n in stepsInfo.length">
          <v-spacer :key="`${n}-initial-spacer`" />
          <v-stepper-step
            :step="n"
            :key="`${n}-step`"
            :complete="step > n"
            :editable="step > n"
            edit-icon="mdi-check"
          >
            {{ $t(stepsInfo[n - 1].name) }}
          </v-stepper-step>
          <v-spacer :key="`${n}-final-spacer`" />
          <v-divider v-if="n !== stepsInfo.length" :key="n" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <CertificationCreateClientList
              :certificationClient.sync="certification.client"
              ref="CertificationCreateClientList"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <AppUserSummary
              :clientName="client.name"
              :clientAvatar="client.avatarURL"
              :clientIdNumber="client.idNumber"
              :clientRole="client.roleRef.id"
              :clientCollegiateNumber="client.collegiateNumber"
              ref="AppUserSummary"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container>
            <CertificationCreateInformation
              :certificationFile.sync="certificationFile"
              :certificationName.sync="certification.name"
              :certificationType.sync="certification.type"
              :certificationDescription.sync="certification.description"
              ref="CertificationCreateInformationEnabled"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-container>
            <CertificationCreateInformation
              :certificationFile.sync="certificationFile"
              :certificationName.sync="certification.name"
              :certificationType.sync="certification.type"
              :certificationDescription.sync="certification.description"
              :client="client"
              disabled
              ref="CertificationCreateInformationDisabled"
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="step !== 1"
          color="primary"
          text
          :disabled="loading"
          @click="step--"
        >
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="loading || !certification.client"
          :loading="loading"
          @click="nextStep"
        >
          {{ $t(stepsInfo[step - 1].nextAction) }}
          <template v-slot:loader>
            <span>{{ $t('creatingCertification') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-card>
</template>

<script>
import { getUserByIdNumber } from '@/services/user-service'
import { uploadCertification } from '@/services/storage-service'
import {
  createCertification,
  newCertificationId,
} from '@/services/certification-service'
import CertificationCreateClientList from '@/components/certification/create/CertificationCreateClientList'
import AppUserSummary from '@/components/commons/AppUserSummary'
import CertificationCreateInformation from '@/components/certification/create/CertificationCreateInformation'
import { mapMutations } from 'vuex'
import file2md5 from 'file2md5'

export default {
  components: {
    CertificationCreateClientList,
    AppUserSummary,
    CertificationCreateInformation,
  },
  data() {
    return {
      client: {
        name: '',
        avatarURL: '',
        idNumber: '',
        id: '',
        roleRef: {},
      },
      dialog: { show: false, card: '', method: '', params: undefined },
      stepsInfo: [
        { name: 'client', optional: false, nextAction: 'next' },
        { name: 'confirmClient', optional: false, nextAction: 'next' },
        { name: 'certificationData', optional: false, nextAction: 'next' },
        { name: 'confirm', optional: false, nextAction: 'confirm' },
      ],
      step: 1,
      existingUserFlag: true,
      certification: {
        name: '',
        description: '',
        type: '',
        client: '',
        creator: this.$store.state.user.id,
      },
      certificationFile: undefined,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async nextStep() {
      switch (this.step) {
        case 1:
          var client = await getUserByIdNumber(this.certification.client)
          if (!client) {
            const message = this.$t('userNotFound')
            this.setSnackbar({ position: 'top', message })
            return
          }

          this.client.id = client.id
          this.client.name = this.getNameHidden(client.name)
          this.client.avatarURL = client.avatarURL
          this.client.idNumber = client.idNumber
          this.client.roleRef = client.roleRef
          this.client.collegiateNumber = client.collegiateNumber
          break

        case 2:
          break

        case 3:
          if (
            !this.$refs.CertificationCreateInformationEnabled.$refs.certificationForm.validate() ||
            !this.$refs.CertificationCreateInformationEnabled.$refs.certificationFilePicker.$refs.procedureFilePickerInput.validate()
          )
            return
          break

        case 4:
          this.loading = true
          try {
            this.certification.client = this.client.id
            var id = newCertificationId()
            var responseUpload = await uploadCertification(
              this.certificationFile,
              id
            )
            this.certification.file = responseUpload.metadata.fullPath
            this.certification.hash = await file2md5(this.certificationFile)
            await createCertification(id, this.certification)
            const message = this.$t('creatingCertificationSuccess')
            this.setSnackbar({ position: 'top', message, color: 'green' })
            this.loading = false
            this.$router.push({
              name: 'CertificationDetailView',
              params: { id },
            })
            return
          } catch (err) {
            this.certification.client = this.client.idNumber
            this.loading = false
            const message = this.$t('creatingCertificationError')
            this.setSnackbar({ position: 'top', message })
            return
          }

        default:
          break
      }
      this.step++
    },
    getNameHidden(fullName) {
      const splittedName = fullName.split(' ').filter((e) => e)
      var name = splittedName[0]
      var surnames = splittedName.slice(1, splittedName.length)
      return name + ' ' + surnames.map((n) => n[0] + '. ').join('')
    },
  },
}
</script>
