import {
  Firestore,
  AssociationsCollection,
  AssociationsDoc,
} from '@/firebase-exports'

export async function createUserIdNumber(user) {
  if (!user.associationRef) return user.taxNumber

  if (user.roleRef === 'associationSolicitor') {
    delete user.collegiateNumber
    const docSnap = await Firestore.getDoc(
      AssociationsDoc('solicitor', user.associationRef)
    )
    return docSnap.data().shortName.toUpperCase()
  }

  const docSnap = await Firestore.getDoc(
    AssociationsDoc(user.roleRef, user.associationRef)
  )
  return `${docSnap.data().shortName}-${user.collegiateNumber}`.toUpperCase()
}

export async function listAssociations() {
  const associations = ['lawyer', 'solicitor']
  const response = await Promise.all(
    associations.map(async (association) => {
      const q = Firestore.query(
        AssociationsCollection(association),
        Firestore.orderBy('name', 'asc')
      )
      var querySnapshot = await Firestore.getDocs(q)
      return querySnapshot.docs.map((doc) => {
        const document = doc.data()
        document.name = `${document.name} (${document.shortName})`
        return { id: doc.id, ...document }
      })
    })
  )
  return {
    lawyer: response[0],
    solicitor: response[1],
    associationSolicitor: response[1],
  }
}
