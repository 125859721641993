<template>
  <v-card>
    <v-data-table
      :items-per-page="!isMobile ? 10 : visible * 5"
      :headers="headers"
      :items="records"
      :search="search"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
      }"
      :hide-default-footer="isMobile"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-card-title>
            {{ $t('navigationPage.records') }}
          </v-card-title>
          <template v-if="!isMobile">
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="$router.push({ name: 'RecordCreateView' })"
            >
              {{ $t('recordCreate') }}
            </v-btn>
          </template>
        </v-toolbar>
        <template v-if="isMobile">
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
          </v-toolbar>
        </template>
      </template>
      <template v-slot:item="{ item: record, isMobile: mobileVersion }">
        <tr v-if="!mobileVersion" @click="openRecordDetail(record)">
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ record.subject }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ record.procedureNumber }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  {{ record.client.idNumber }}
                  -
                  {{ record.client.name }}
                </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  {{ $d(record.trialDate, 'i18nDateAndHourSeconds') }}
                </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  {{ $d(record.createdAt, 'i18nDateAndHourSeconds') }}
                </span>
              </template>
            </AppSkeleton>
          </td>
        </tr>
        <tr v-else>
          <v-list subheader two-line>
            <AppSkeleton :condition="loadingTableData" type="recordListMobile">
              <template v-slot:skeleton-content>
                <v-list-item @click="openRecordDetail(record)">
                  <v-list-item-avatar>
                    <v-icon class="primary lighten-1" dark>
                      mdi-folder-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-if="record.subject.length > 35">
                      {{ record.subject.substring(0, 32) + '...' }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ record.subject }}
                    </v-list-item-title>
                    <v-list-item-title class="primary--text lighten-3">
                      {{ record.type }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ record.client.idNumber }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ $d(record.createdAt, 'i18nDateAndHourSeconds') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppSkeleton>
          </v-list>
        </tr>
      </template>
      <template v-slot:footer v-if="isMobile">
        <v-list-item v-if="visible * 5 <= records.length">
          <v-btn
            block
            rounded
            @click="visible++"
            color="primary"
            :disabled="loadingTableData"
          >
            <v-icon left dark>mdi-chevron-down</v-icon>
            {{ $t('showMore') }}
          </v-btn>
        </v-list-item>
      </template>
    </v-data-table>

    <v-fab-transition>
      <v-btn
        v-if="isMobile && !$store.state.navigationDrawer"
        @click="$router.push({ name: 'RecordCreateView' })"
        color="secondary"
        fab
        fixed
        right
        bottom
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    loadingTableData: Boolean,
    records: Array,
  },
  data() {
    return {
      visible: 1,
      search: '',
    }
  },
  methods: {
    openRecordDetail(value) {
      if (!Object.keys(value).length) return

      this.$router.push({
        name: 'RecordDetailView',
        params: { id: value.id },
      })
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    headers() {
      return [
        {
          text: this.$t('recordSubject'),
          value: 'subject',
        },
        {
          text: this.$t('recordProcedureNumber'),
          value: 'procedureNumber',
        },
        {
          text: this.$t('clientName'),
          value: 'client.name',
        },

        {
          text: this.$t('trialDate'),
          value: 'trialDate',
        },
        {
          text: this.$t('recordCreatedAt'),
          value: 'createdAt',
        },
      ]
    },
  },
}
</script>

<style lang="scss"></style>
