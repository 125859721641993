<template>
  <v-container fill-height>
    <v-row>
      <v-col cols="12">
        <template v-if="fileContentType.match('video.*')">
          <video controls width="100%" height="100%">
            <source :src="fileURL" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </template>
        <template v-else-if="fileContentType.match('image.*')">
          <v-img
            :class="imgClass"
            :src="fileURL"
            :contain="contain"
            :max-height="maxHeight"
            :aspect-ratio="aspectRatio"
            :min-height="mediaLoaded ? undefined : '400px'"
            @load="mediaLoaded = true"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary" />
              </v-row>
            </template>
            <slot name="img-content" />
          </v-img>
        </template>
        <template v-else-if="displayPDF">
          <v-row
            v-if="!mediaLoaded"
            class="fill-height ma-0"
            align="center"
            justify="center"
            style="position: absolute; width: 100%; height: 500px"
          >
            <v-progress-circular indeterminate color="primary" />
          </v-row>
          <embed
            :src="fileURL"
            height="500"
            width="100%"
            @load="mediaLoaded = true"
          />
        </template>
        <template v-else>
          <v-img
            src="@/assets/no-preview.png"
            contain
            :min-height="mediaLoaded ? undefined : '400px'"
            @load="delayMediaLoaded"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary" />
              </v-row>
            </template>
          </v-img>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    fileURL: {
      type: String,
    },
    fileContentType: {
      type: String,
    },
    aspectRatio: {
      type: String,
    },
    maxHeight: {
      type: String,
      default: '100%',
    },
    contain: {
      type: Boolean,
      default: false,
    },
    imgClass: String,
  },
  data() {
    return {
      mediaLoaded: false,
    }
  },
  methods: {
    async delayMediaLoaded() {
      await new Promise((resolve) => setTimeout(resolve, 100))
      this.mediaLoaded = true
    },
  },
  computed: {
    displayPDF() {
      const isPDF = this.fileContentType.match('application/pdf')
      const isBrowserMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      return isPDF && !isBrowserMobile
    },
  },
}
</script>

<style lang="scss" scoped></style>
