const dateTimeFormats = {
  en: {
    i18nDate: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    i18nHour: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    i18nDateAndHour: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    i18nDateAndHourSeconds: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
  es: {
    i18nDate: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    i18nHour: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    i18nDateAndHour: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    i18nDateAndHourSeconds: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
  },
}

export default dateTimeFormats
