<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <CertificationDetail />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CertificationDetail from '@/components/certification/CertificationDetail'

export default {
  components: {
    CertificationDetail,
  },
}
</script>

<style lang="scss" scoped></style>
