<template>
  <div>
    <v-avatar :color="avatarColor" :size="avatarSize">
      <v-icon v-if="isGroup" :style="`font-size: ${iconSize}px;`">
        mdi-account-group-outline
      </v-icon>
      <span v-else-if="!userAvatar" :class="`${initialsColor}--text`">
        <v-icon v-if="isAssociationAvatar" :style="`font-size: ${iconSize}px;`">
          {{
            getUserRoleIcon(
              userCollegiateNumber === '0' ? 'association' : userRole
            )
          }}
        </v-icon>
        <template v-else-if="userName">
          {{
            userName
              .split(' ')
              .map((n) => n[0])
              .join('')
              .substring(0, 3)
          }}
        </template>
      </span>
      <v-img v-else elevation="2" :src="userAvatar" />
    </v-avatar>
    <v-avatar
      v-if="userRole"
      :color="overlapAvatarColor"
      :size="overlapAvatarSize"
      :style="{
        'margin-left': `${-avatarSize / 2.5}px`,
        'margin-top': `${avatarSize / 1.5}px`,
        position: 'absolute',
      }"
    >
      <v-icon
        :style="`font-size: ${overlapIconSize}px;`"
        :color="overlapIconColor"
      >
        {{
          getUserRoleIcon(
            userCollegiateNumber === '0' ? 'association' : userRole
          )
        }}
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>
import getUserRoleIcon from '@/utils/get-user-role-icon'

export default {
  props: {
    avatarColor: {
      type: String,
      default: 'secondary',
    },
    initialsColor: {
      type: String,
      default: 'primary',
    },
    avatarSize: String,
    iconSize: String,
    isGroup: Boolean,
    isAssociationAvatar: Boolean,
    userAvatar: String,
    userName: String,
    userRole: String,
    userCollegiateNumber: String,
    overlapAvatarColor: {
      type: String,
      default: 'primary',
    },
    overlapIconColor: {
      type: String,
      default: 'secondary',
    },
    overlapAvatarSize: {
      type: String,
      default: '25',
    },
    overlapIconSize: {
      type: String,
      default: '20',
    },
  },
  data() {
    return {
      getUserRoleIcon: getUserRoleIcon,
    }
  },
}
</script>

<style lang="scss" scoped></style>
