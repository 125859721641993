<template>
  <v-dialog :value="showDialog" persistent max-width="450">
    <v-card>
      <v-card-title class="justify-center">
        {{ $t(title || actionMessages.title[actionDialog]) }}
      </v-card-title>
      <v-card-text class="text-center">
        <template v-if="isDelete">
          <v-alert type="warning" class="text-left mb-10">
            {{ $t('removeContactWarning') }}
          </v-alert>
          <h2>{{ contactData.customName }}</h2>
          <h3>{{ contactData.idNumber }}</h3>
        </template>
        <v-form
          v-else
          ref="contactDialogForm"
          v-model="validContactForm"
          lazy-validation
          :key="contactType"
        >
          <v-container>
            <v-row>
              <v-col cols="12" v-if="contactType === 'client'">
                <v-text-field
                  v-model="contactData.idNumber"
                  @keyup="fixAndUppercase"
                  :label="$t('taxNumber')"
                  :rules="contactIdNumberRules"
                  :success-messages="contactIdInputMessage(false)"
                  :error-messages="contactIdInputMessage(true)"
                  @focus="isContactIdSelected = !isContactIdSelected"
                  @blur="isContactIdSelected = !isContactIdSelected"
                  @change="checkContactExists"
                  prepend-icon="mdi-card-account-details-outline"
                  :disabled="isUpdate"
                  dense
                >
                  <template v-slot:append v-if="isCreate">
                    <v-progress-circular
                      v-if="loadingContactId"
                      color="info"
                      indeterminate
                      size="20"
                      width="2"
                    />
                    <v-icon
                      v-else-if="
                        !isContactIdSelected &&
                        contactData.idNumber &&
                        !rulesFails
                      "
                      right
                      :color="validContactId ? 'green' : 'red'"
                    >
                      {{ validContactId ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <template v-else>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="contactData.association"
                    :items="associationList[contactType]"
                    item-text="name"
                    :item-value="isCreate ? 'shortName' : 'id'"
                    prepend-icon="mdi-office-building-outline"
                    :label="$t('selectAssociation')"
                    :rules="contactAssociationRules"
                    :disabled="loading || isUpdate"
                    :menu-props="{ bottom: true, offsetY: true }"
                    @change="
                      if (contactData.collegiateNumber) {
                        $refs.contactDialogForm.validate()
                        checkContactExists()
                      }
                    "
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="contactData.collegiateNumber"
                    prepend-icon="mdi-numeric"
                    :label="$t('collegiateNumber')"
                    type="Number"
                    min="1"
                    :rules="contactCollegiateNumberRules"
                    :disabled="loading || isUpdate"
                    :success-messages="contactIdInputMessage(false)"
                    :error-messages="contactIdInputMessage(true)"
                    @focus="isContactIdSelected = !isContactIdSelected"
                    @blur="isContactIdSelected = !isContactIdSelected"
                    @change="checkContactExists"
                  >
                    <template v-slot:append v-if="isCreate">
                      <v-progress-circular
                        v-if="loadingContactId"
                        color="info"
                        indeterminate
                        size="20"
                        width="2"
                      />
                      <v-icon
                        v-else-if="
                          !isContactIdSelected &&
                          contactData.collegiateNumber &&
                          !rulesFails
                        "
                        right
                        :color="validContactId ? 'green' : 'red'"
                      >
                        {{ validContactId ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </template>
              <v-col cols="12">
                <v-text-field
                  v-model="contactData.customName"
                  :label="$t('nameOrNickname')"
                  :rules="contactNameRules"
                  prepend-icon="mdi-account-outline"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          class="red lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading"
          @click="resetForm(true)"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="green lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="
            !valid ||
            (!validContactForm && !!contactData.idNumber) ||
            loading ||
            loadingContactId
          "
          :loading="loading"
          @click="performAction"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { getUserByIdNumber } from '@/services/user-service'
import {
  createContact,
  deleteContact,
  updateContact,
} from '@/services/contact-service'
import { listAssociations } from '@/services/association-service'
import { mapMutations } from 'vuex'

export default {
  props: {
    title: String,
    actionDialog: String,
    showDialog: Boolean,
    contacts: Array,
    contactType: String,
    contactProp: Object,
  },
  data() {
    return {
      loading: false,
      contactNameRules: [rules.required],
      contactAssociationRules: [rules.required],
      isContactIdSelected: false,
      loadingContactId: false,
      associationList: { lawyer: [], solicitor: [] },
      rulesFails: false,
      valid: true,
      validContactId: false,
      validContactForm: true,
      contactData: {},
      actionMessages: {
        title: {
          create: 'addContact',
          update: 'editContact',
          delete: 'removementContactConfirmation',
        },
        success: {
          create: 'creatingContactSuccess',
          update: 'editingContactSuccess',
          delete: 'deletingContactSuccess',
        },
        error: {
          create: 'creatingContactError',
          update: 'editingContactError',
          delete: 'deletingContactError',
        },
      },
    }
  },
  async mounted() {
    this.associationList = await listAssociations()
  },
  watch: {
    contactProp: function (newValue) {
      this.contactData = { ...newValue }
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async performAction() {
      const contactDialogForm = this.$refs.contactDialogForm
      if (contactDialogForm && !contactDialogForm.validate()) return

      this.loading = true

      try {
        if (this.isCreate) {
          const newContact = await getUserByIdNumber(this.contactData.idNumber)
          if (newContact) {
            await createContact({
              customName: this.contactData.customName,
              contact: newContact.id,
            })
          }
        } else if (this.isUpdate) {
          await updateContact(this.contactProp.id, {
            customName: this.contactData.customName,
          })
        } else if (this.isDelete) {
          await deleteContact(this.contactProp.id)
        }

        this.$emit('confirmContactDialog')
        this.resetForm()

        const message = this.$t(this.actionMessages.success[this.actionDialog])
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t(this.actionMessages.error[this.actionDialog])
        this.setSnackbar({ position: 'top', message })
      }
      this.loading = false
    },
    fixAndUppercase() {
      if (this.contactData.idNumber) {
        const regex = /[^a-zA-Z0-9]/g
        this.contactData.idNumber = this.contactData.idNumber
          .replace(regex, '')
          .toUpperCase()
      }
    },
    async checkContactExists() {
      const isClient = this.contactType === 'client'

      // TODO.- Review way to improve
      const rules = isClient
        ? this.contactIdNumberRules
        : this.contactCollegiateNumberRules
      const value = isClient
        ? this.contactData.idNumber
        : this.contactData.collegiateNumber
      this.rulesFails = false
      for (const rule of rules) if (rule(value) !== true) this.rulesFails = true
      if (!isClient && !this.contactData.association) this.rulesFails = true
      if (this.rulesFails) return

      if (!isClient) {
        this.contactData.idNumber = `${this.contactData.association}-${this.contactData.collegiateNumber}`
      }

      const regex = isClient ? /[^a-zA-Z0-9]/g : /[^a-zA-Z0-9-]/g
      this.contactData.idNumber = this.contactData.idNumber
        .replace(/ /g, '-')
        .replace(regex, '')
        .toUpperCase()

      if (!this.contactData.idNumber) {
        this.validContactId = true
        return
      }
      this.loadingContactId = true
      const contactByIdNumber = await getUserByIdNumber(
        this.contactData.idNumber
      )
      if (!contactByIdNumber) {
        this.validContactId = false
        this.valid = false
      } else {
        this.validContactId = true
        this.valid = true
      }
      this.loadingContactId = false
    },
    contactIdInputMessage(isError) {
      var contactCondition =
        this.isCreate &&
        !this.rulesFails &&
        !this.loadingContactId &&
        !this.isContactIdSelected &&
        !!this.contactData.idNumber &&
        (isError ? !this.validContactId : this.validContactId)
      return contactCondition
        ? isError
          ? this.$t('contactIdNumberNotExists')
          : this.$t('contactIdNumberExists')
        : []
    },
    resetForm(close) {
      const isClient = this.contactType === 'client'
      if (!isClient) this.contactData.idNumber = ''
      if (this.$refs.contactDialogForm) this.$refs.contactDialogForm.reset()
      if (close) this.$emit('closeContactDialog')
    },
  },
  computed: {
    isCreate() {
      return this.actionDialog === 'create'
    },
    isUpdate() {
      return this.actionDialog === 'update'
    },
    isDelete() {
      return this.actionDialog === 'delete'
    },
    contactIdNumberRules() {
      return this.isCreate
        ? [
            rules.required,
            rules.contactNotExists(this.contacts),
            rules.contactNotYourself(this.$store.state.user.idNumber),
            rules.idNumber,
          ]
        : [rules.required]
    },
    contactCollegiateNumberRules() {
      return this.isCreate
        ? [
            rules.required,
            rules.number,
            rules.contactNotExists(
              this.contacts,
              `${this.contactData.association}-${this.contactData.collegiateNumber}`
            ),
            rules.contactNotYourself(
              this.$store.state.user.idNumber,
              `${this.contactData.association}-${this.contactData.collegiateNumber}`
            ),
          ]
        : [rules.required]
    },
  },
}
</script>
