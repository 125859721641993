<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-form ref="roleForm">
          <v-row align="center" justify="center" no-gutters>
            <template
              v-for="roleElement in [
                'lawyer',
                'solicitor',
                'associationSolicitor',
              ]"
            >
              <v-col
                cols="6"
                lg="3"
                align="right"
                justify="right"
                class="pr-4"
                :key="`${roleElement}Title`"
              >
                <h3 :disabled="disabled">{{ $t(roleElement) }}</h3>
              </v-col>
              <v-col cols="6" lg="3" :key="`${roleElement}Switch`">
                <v-switch
                  :value="userRoleRef === roleElement"
                  inset
                  :disabled="disabled"
                  @change="
                    $emit('update:userRoleRef', $event ? roleElement : 'client')
                    $emit('update:userAssociationRef', undefined)
                    $emit('update:userCollegiateNumber', undefined)
                  "
                />
              </v-col>
            </template>
          </v-row>
          <v-row v-if="userRoleRef !== 'client'">
            <v-col
              cols="12"
              :lg="userRoleRef === 'associationSolicitor' ? 12 : 6"
            >
              <v-autocomplete
                :value="userAssociationRef"
                @input="$emit('update:userAssociationRef', $event)"
                :items="associationList[userRoleRef]"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-office-building-outline"
                :label="$t('selectAssociation')"
                :rules="associationRules"
                :disabled="disabled"
                :menu-props="{ bottom: true, offsetY: true }"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-if="userRoleRef === 'lawyer' || userRoleRef === 'solicitor'"
            >
              <v-text-field
                :value="userCollegiateNumber"
                @input="$emit('update:userCollegiateNumber', $event)"
                prepend-icon="mdi-numeric"
                :label="$t('collegiateNumber')"
                type="Number"
                min="1"
                :rules="collegiateNumberRules"
                :disabled="disabled"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/utils/rules'
import { listAssociations } from '@/services/association-service'

export default {
  props: {
    disabled: Boolean,
    userRoleRef: String,
    userAssociationRef: String,
    userCollegiateNumber: String,
  },
  data() {
    return {
      associationList: { lawyer: [], solicitor: [] },
      associationRules: [rules.required],
      collegiateNumberRules: [rules.required, rules.number],
    }
  },
  async mounted() {
    this.associationList = await listAssociations()
  },
}
</script>
