<template>
  <v-form ref="procedureInformationForm">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6">
        <v-row align="center" justify="center">
          <template v-if="procedureLegalEntity">
            <v-col cols="12" v-if="procedureLegalEntity">
              <v-subheader class="px-0">
                {{ $t('legalEntityData') }}
              </v-subheader>
              <v-divider />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                :value="procedureLegalIdNumber"
                @input="
                  $emit('update:procedureLegalIdNumber', $event.toUpperCase())
                "
                :label="$t('idNumber')"
                prepend-icon="mdi-domain"
                :rules="idNumberRules"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                :value="procedureLegalName"
                @input="$emit('update:procedureLegalName', $event)"
                :label="$t('firstName')"
                prepend-icon="mdi-alphabetical"
                :rules="nameRules"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="12" v-if="procedureLegalEntity">
              <v-subheader class="px-0">
                {{ $t('legalEntityClientData') }}
              </v-subheader>
              <v-divider />
            </v-col>
          </template>
          <v-col cols="12" lg="6">
            <v-text-field
              :value="procedureFirstName"
              @input="$emit('update:procedureFirstName', $event)"
              :label="$t('firstName')"
              prepend-icon="mdi-alphabetical"
              :rules="firstNameRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              :value="procedureLastName"
              @input="$emit('update:procedureLastName', $event)"
              :label="$t('lastName')"
              prepend-icon="mdi-alphabetical"
              :rules="lastNameRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              :value="procedureIdNumber"
              @input="$emit('update:procedureIdNumber', $event.toUpperCase())"
              :label="$t('idNumber')"
              prepend-icon="mdi-card-account-details-outline"
              :rules="idNumberRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              :value="procedureEmail"
              @input="$emit('update:procedureEmail', $event)"
              :label="$t('email')"
              prepend-icon="mdi-at"
              :rules="emailRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              prepend-icon="mdi-phone-dial-outline"
              append-icon="mdi-menu-down"
              :value="procedurePrefix"
              @input="$emit('update:procedurePrefix', $event)"
              :rules="phonePrefixRules"
              :hint="procedurePrefix ? procedurePrefix.state : ''"
              :items="prefixes"
              item-text="prefix"
              item-value="prefix"
              :label="$t('prefix')"
              persistent-hint
              return-object
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="procedurePhone"
              @input="$emit('update:procedurePhone', $event)"
              :label="$t('phone')"
              prepend-icon="mdi-phone-outline"
              :rules="phoneNumberRules"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="procedureLegalEntity" cols="12" lg="6">
        <AppMedia
          v-if="procedureFile"
          :fileURL="fileURL"
          :fileContentType="procedureFile.type"
          contain
        />
        <AppFilePicker
          ref="procedureFilePicker"
          :value="procedureFile"
          @input="$emit('update:procedureFile', $event)"
          :fileType="$t('legalProof')"
          :disabled="disabled"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import AppMedia from '@/components/commons/AppMedia'
import AppFilePicker from '@/components/commons/AppFilePicker'

export default {
  components: {
    AppMedia,
    AppFilePicker,
  },
  props: {
    procedureFile: File,
    procedureEmail: String,
    procedureFirstName: String,
    procedureLastName: String,
    procedureIdNumber: String,
    procedureLegalIdNumber: String,
    procedureLegalName: String,
    procedurePrefix: {
      type: Object,
      default: () => {
        return {}
      },
    },
    procedurePhone: String,
    procedureType: String,
    procedureLegalEntity: Boolean,
    client: Object,
    disabled: Boolean,
  },
  data() {
    return {
      nameRules: [rules.required],
      phoneNumberRules: [rules.required],
      firstNameRules: [rules.required, rules.firstName],
      lastNameRules: [rules.required, rules.lastName],
      idNumberRules: [rules.required, rules.idNumber],
      emailRules: [rules.required, rules.email],
      phonePrefixRules: [rules.phonePrefix],
      prefixes: [
        { state: this.$t('countries.spain'), prefix: '+34' },
        { state: this.$t('countries.portugal'), prefix: '+351' },
        { state: this.$t('countries.netherlands'), prefix: '+31' },
        { state: this.$t('countries.germany'), prefix: '+49' },
        { state: this.$t('countries.rusia'), prefix: '+7' },
      ],
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.procedureInformationForm.resetValidation()
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    fileURL() {
      return URL.createObjectURL(this.procedureFile)
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
  },
}
</script>
