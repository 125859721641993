<template>
  <v-form ref="recordForm">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-row align="center" justify="center">
          <!--  <v-col cols="12" v-if="disabled">
            <v-text-field
              :value="`${client.idNumber} - ${client.name}`"
              :label="$t('client')"
              prepend-icon="mdi-account-outline"
              disabled
            /> 
          </v-col> -->
          <v-col cols="12" lg="8">
            <v-text-field
              :value="recordSubject"
              @input="$emit('update:recordSubject', $event)"
              :label="$t('recordSubject')"
              prepend-icon="mdi-subtitles-outline"
              :rules="nameRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-autocomplete
              ref="recordSubjectTypeInput"
              :value="recordSubjectType"
              @input="$emit('update:recordSubjectType', $event)"
              :items="recordSubjectTypes"
              :label="$t('recordSubjectType')"
              prepend-icon="mdi-format-list-bulleted-type"
              :rules="typeRules"
              :disabled="disabled"
              @keyup="fixRecordSubjectTypeInput"
              :search-input.sync="searchSubjectType"
            >
              <template v-slot:no-data>
                <div style="display: none" />
              </template>
              <template v-slot:append-item>
                <v-list-item
                  v-if="recordSubjectTypeAvailable"
                  @click="addNewRecordSubjectType"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon left> mdi-plus </v-icon>
                      {{ $t('recordSubjectTypeNotFound') }}
                      "{{ createSubjectTypeButtonText }}"
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              :value="recordProcedureNumber"
              @input="$emit('update:recordProcedureNumber', $event)"
              :label="$t('recordProcedureNumber')"
              prepend-icon="mdi-numeric"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-autocomplete
              ref="recordJurisdictionTypeInput"
              :value="recordJurisdictionType"
              @input="$emit('update:recordJurisdictionType', $event)"
              :items="recordJurisdictionTypes"
              :label="$t('recordJurisdictionType')"
              prepend-icon="mdi-certificate-outline"
              :rules="typeRules"
              :disabled="disabled"
            >
              <template v-slot:no-data>
                <div style="display: none" />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" v-if="!disabled">
            <v-datetime-picker
              v-model="trialDate"
              @input="$emit('update:recordTrialDate', $event)"
              :label="$t('trialDate')"
              :ok-text="$t('ok')"
              :clear-text="$t('clear')"
              :text-field-props="{
                prependIcon: 'mdi-calendar-clock',
              }"
              :date-picker-props="{
                'allowed-dates': disablePastDates,
                locale: $t('javascriptLocale'),
              }"
              :time-picker-props="{
                format: '24hr',
              }"
              :dateFormat="$t('vuetifyDatePicker')"
              :disabled="disabled"
            >
              <template slot="actions" slot-scope="{ parent }">
                <v-btn
                  text
                  color="secondary"
                  @click.native="parent.clearHandler"
                  >{{ $t('clear') }}</v-btn
                >
                <v-btn text color="primary" @click="parent.okHandler">{{
                  $t('ok')
                }}</v-btn>
              </template>
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="12" lg="4" v-else>
            <v-text-field
              :value="
                recordTrialDate ? $d(recordTrialDate, 'i18nDateAndHour') : ''
              "
              :label="$t('trialDate')"
              prepend-icon="mdi-scale-balance"
              disabled
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              :value="recordSolicitor"
              @input="$emit('update:recordSolicitor', $event)"
              :label="$t('solicitor')"
              prepend-icon="mdi-gavel"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="8">
            <v-textarea
              :value="recordComments"
              rows="1"
              @input="$emit('update:recordComments', $event)"
              :label="$t('recordComments')"
              prepend-icon="mdi-text"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <v-subheader class="px-0">
              <b>{{ $t('oppositeDetails') }}</b>
            </v-subheader>
            <v-divider />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              :value="recordOpposite"
              @input="$emit('update:recordOpposite', $event)"
              :label="$t('opposite')"
              prepend-icon="mdi-account-injury"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              :value="recordOppositeLawyer"
              @input="$emit('update:recordOppositeLawyer', $event)"
              :label="$t('oppositeLawyer')"
              prepend-icon="mdi-scale-balance"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              :value="recordOppositeSolicitor"
              @input="$emit('update:recordOppositeSolicitor', $event)"
              :label="$t('oppositeSolicitor')"
              prepend-icon="mdi-gavel"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import {
  listRecordSubjectTypes,
  createCustomRecordSubjectType,
  listRecordJurisdictionTypes,
} from '@/services/record-type-service'

export default {
  props: {
    recordSubject: String,
    recordSubjectType: String,
    recordProcedureNumber: String,
    recordJurisdictionType: String,
    recordTrialDate: Date,
    recordSolicitor: String,
    recordOpposite: String,
    recordOppositeLawyer: String,
    recordOppositeSolicitor: String,
    recordComments: String,
    client: Object,
    disabled: Boolean,
  },
  data() {
    return {
      searchSubjectType: '',
      recordSubjectTypes: [],
      recordJurisdictionTypes: [],
      nameRules: [rules.required],
      typeRules: [rules.required],
      commentsRules: [rules.required],
      trialDate: '',
    }
  },
  async mounted() {
    this.recordSubjectTypes = await listRecordSubjectTypes()
    this.recordJurisdictionTypes = await listRecordJurisdictionTypes()
    this.trialDate = this.recordTrialDate
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.recordForm.resetValidation()
    },
    recordTrialDate: function () {
      this.trialDate = this.recordTrialDate
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    createSubjectTypeButtonText() {
      const maxLength = this.isMobile ? 10 : 25
      if (this.searchSubjectType) {
        return this.searchSubjectType.length > maxLength
          ? this.searchSubjectType.slice(0, maxLength) + '...'
          : this.searchSubjectType
      } else {
        return ''
      }
    },
    recordSubjectTypeAvailable() {
      if (!this.searchSubjectType) return false
      const index = this.recordSubjectTypes.findIndex((element) => {
        return element.toLowerCase() === this.searchSubjectType.toLowerCase()
      })
      return index === -1
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10)
    },
    fixRecordSubjectTypeInput() {
      if (!this.searchSubjectType) return
      this.searchSubjectType = this.searchSubjectType.replace(/\s+/g, ' ')
    },
    async addNewRecordSubjectType() {
      if (this.recordSubjectTypeAvailable) {
        try {
          await createCustomRecordSubjectType({ name: this.searchSubjectType })
          this.recordSubjectTypes = await listRecordSubjectTypes()
          this.$emit('update:recordSubjectType', this.searchSubjectType)
          this.$refs.recordSubjectTypeInput.blur()
        } catch (err) {
          const message = this.$t('creatingRecordSubjectTypeError')
          this.setSnackbar({ position: 'top', message })
        }
      } else {
        const message = this.$t('recordSubjectTypeExists')
        this.setSnackbar({ position: 'bottom', message })
      }
    },
  },
}
</script>
