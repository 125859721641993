<template>
  <AppSkeleton :condition="!record.subject" type="recordUpdate">
    <template v-slot:skeleton-content>
      <v-card class="mx-auto" outlined elevation="20">
        <v-stepper v-model="step">
          <v-stepper-header>
            <template v-for="n in stepsInfo.length">
              <v-spacer :key="`${n}-initial-spacer`" />
              <v-stepper-step
                :step="n"
                :key="`${n}-step`"
                :complete="step > n"
                :editable="step > n"
                edit-icon="mdi-check"
              >
                {{ $t(stepsInfo[n - 1].name) }}
              </v-stepper-step>
              <v-spacer :key="`${n}-final-spacer`" />
              <v-divider v-if="n !== stepsInfo.length" :key="n" />
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <RecordCreateInformation
                  :recordSubject.sync="record.subject"
                  :recordSubjectType.sync="record.subjectType"
                  :recordProcedureNumber.sync="record.procedureNumber"
                  :recordJurisdictionType.sync="record.jurisdictionType"
                  :recordTrialDate.sync="record.trialDate"
                  :recordSolicitor.sync="record.solicitor"
                  :recordOpposite.sync="record.opposite"
                  :recordOppositeLawyer.sync="record.oppositeLawyer"
                  :recordOppositeSolicitor.sync="record.oppositeSolicitor"
                  :recordComments.sync="record.comments"
                  ref="RecordUpdateInformationEnabled"
                />
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-container>
                <RecordCreateInformation
                  :recordSubject.sync="record.subject"
                  :recordSubjectType.sync="record.subjectType"
                  :recordProcedureNumber.sync="record.procedureNumber"
                  :recordJurisdictionType.sync="record.jurisdictionType"
                  :recordTrialDate.sync="record.trialDate"
                  :recordSolicitor.sync="record.solicitor"
                  :recordOpposite.sync="record.opposite"
                  :recordOppositeLawyer.sync="record.oppositeLawyer"
                  :recordOppositeSolicitor.sync="record.oppositeSolicitor"
                  :recordComments.sync="record.comments"
                  disabled
                  ref="RecordCreateInformationDisabled"
                />
              </v-container>
            </v-stepper-content>
          </v-stepper-items>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="primary"
              text
              :disabled="loading"
              @click="step !== 1 ? step-- : backToRecord()"
            >
              {{ $t('back') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loading"
              :loading="loading"
              @click="nextStep"
            >
              {{ $t(stepsInfo[step - 1].nextAction) }}
              <template v-slot:loader>
                <span>{{ $t('creatingRecord') }}</span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-stepper>
      </v-card>
    </template>
  </AppSkeleton>
</template>

<script>
import { updateRecord, getRecordToUpdate } from '@/services/record-service'
import RecordCreateInformation from '@/components/record/create/RecordCreateInformation'
import { mapMutations } from 'vuex'

export default {
  components: {
    RecordCreateInformation,
  },
  data() {
    return {
      stepsInfo: [
        { name: 'recordData', optional: false, nextAction: 'next' },
        { name: 'confirm', optional: false, nextAction: 'confirm' },
      ],
      step: 1,
      record: {
        subject: '',
        subjectType: '',
        procedureNumber: '',
        jurisdictionType: '',
        trialDate: null,
        solicitor: '',
        opposite: '',
        oppositeLawyer: '',
        oppositeSolicitor: '',
        comments: '',
      },
      loading: false,
    }
  },
  async mounted() {
    await this.getRecord()
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async getRecord() {
      const recordGet = await getRecordToUpdate(this.$route.params.id, this)
      if (!recordGet) return this.setNotFound(true)
      this.record = recordGet
    },
    backToRecord() {
      this.$router.push({
        name: 'RecordDetailView',
        params: { id: this.$route.params.id },
      })
    },
    async nextStep() {
      switch (this.step) {
        case 1:
          if (
            !this.$refs.RecordUpdateInformationEnabled.$refs.recordForm.validate()
          )
            return
          break

        case 2:
          this.loading = true
          try {
            await updateRecord(this.$route.params.id, this.record)
            const message = this.$t('updatingRecordSuccess')
            this.setSnackbar({ position: 'top', message, color: 'green' })
            this.loading = false
            this.backToRecord()
            return
          } catch (err) {
            this.loading = false
            const message = this.$t('updatingRecordError')
            this.setSnackbar({ position: 'top', message })
            return
          }

        default:
          break
      }
      this.step++
    },
  },
}
</script>
