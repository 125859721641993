<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0" align="center" justify="center">
      <v-btn
        block
        :disabled="loading"
        :loading="loading"
        outlined
        @click="generateReport"
      >
        <v-icon left> mdi-printer-outline </v-icon>
        {{ $t('printHashInfo') }}
        <template v-slot:loader>
          <v-progress-circular indeterminate size="20" width="2" />
          <span class="ml-2">{{ $t('creatingPrintable') }}</span>
        </template>
      </v-btn>
    </v-row>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :paginate-elements-by-height="1100"
      :filename="`${generateBlockchainHash()}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
    >
      <section slot="pdf-content">
        <div id="fileContainer" name="fileContainer">
          <img src="@/assets/app-logo.png" height="50" />
          <h1>
            <b>{{ $t('blockchainHash') }}</b>
          </h1>
          <br />
          <h4 v-if="$store.state.user">
            <span>{{ $t('solicitedBy') }}</span>
            :
            {{ $store.state.user.name }}
          </h4>
          <h4>
            <span>{{ $t('solicitedDate') }}</span>
            :
            {{ $d(new Date(), 'i18nDateAndHourSeconds') }}
          </h4>
        </div>
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <div style="padding-left: 20px">
            <p>
              {{ $t('printHashIntroduction') }}
              <a
                style="text-decoration: none; color: inherit"
                href="https://app.blockiure.io/blockchain/verify"
                >https://app.blockiure.io/blockchain/verify</a
              >
            </p>
          </div>
        </div>
        <hr class="dotted" />
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <h3>
            {{ $t('hashGenerationInformation') }}
          </h3>
          <br />
          <div style="padding-left: 20px">
            <p>
              <b>{{ $t('actionType') }}:</b>
              {{ hashDetails.actionType }}
            </p>
            <p>
              <b>{{ $t('createdAt') }}:</b>
              {{ hashDetails.createdAt }}
            </p>
            <p>
              <b>{{ $t('creatorId') }}:</b>
              {{ hashDetails.creatorId }}
            </p>
            <p>
              <b>{{ $t('receiverId') }}:</b>
              {{ hashDetails.receiverId }}
            </p>
            <p>
              <b>{{ $t('documentHash') }}:</b>
              {{ hashDetails.documentHash }}
            </p>
            <p>
              <b>{{ $t('secret') }}:</b>
              {{ hashDetails.secret }}
            </p>
          </div>
        </div>
        <hr class="dotted" />
        <div
          id="certificationInfoNoBackground"
          name="certificationInfoNoBackground"
        >
          <h3>
            {{ $t('blockchainHash') }}
          </h3>
          <br />
          <div style="padding-left: 20px">
            <p>
              {{ generateBlockchainHash() }}
            </p>
          </div>
        </div>
        <hr class="dotted" />
      </section>
    </VueHtml2pdf>
  </v-container>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import { generateHash } from '@/utils/generate-hash'
import { canonicalize } from 'json-canonicalize'

export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    hashDetails: Object,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    generateReport() {
      this.loading = true
      this.$refs.html2Pdf.generatePdf()
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      options.enableLinks = true
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            pdf.setPage(currentPage)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
          }
        })
        .save()
      this.loading = false
    },
    generateBlockchainHash() {
      var blockchainObject = {
        actionType: this.hashDetails.actionType,
        createdAt: parseInt(this.hashDetails.createdAt),
        creatorId: this.hashDetails.creatorId,
        documentHash: this.hashDetails.documentHash,
        receiverId: this.hashDetails.receiverId,
        secret: this.hashDetails.secret,
      }
      return generateHash(canonicalize(blockchainObject))
    },
  },
}
</script>

<style lang="scss" scoped>
#fileContainer {
  width: 100%;
  background-color: #1e355e;
  padding: 30px;
  color: #ecf0f1;
  font-family: 'Courier New', monospace;
}
#certificationInfo {
  width: 100%;
  background-color: #ecf0f1;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  color: #1e355e;
  font-family: 'Courier New', monospace;
}
#certificationInfoNoBackground {
  width: 100%;
  background-color: white;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  color: #1e355e;
  font-family: 'Courier New', monospace;
}
hr.dotted {
  border: 1px dashed #1e355e;
}
</style>
