<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <ProcedureList
          :procedures="procedures"
          :loadingTableData="loadingTableData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listProcedures } from '@/services/procedure-service'
import ProcedureList from '@/components/procedure/ProcedureList'

export default {
  components: {
    ProcedureList,
  },
  data() {
    return {
      loadingTableData: true,
      procedures: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    }
  },
  async mounted() {
    var proceduresList = []
    const filter = this.$route.params.filter
    if (filter === 'my' || filter === 'assigned') {
      proceduresList = await listProcedures(filter)
    }

    await new Promise((resolve) => setTimeout(resolve, 500))
    this.procedures = proceduresList
    this.loadingTableData = false
  },
}
</script>
