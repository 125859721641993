<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <RecordUpdate />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RecordUpdate from '@/components/record/RecordUpdate'

export default {
  components: {
    RecordUpdate,
  },
}
</script>
