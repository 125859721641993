<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <BlockchainVerifier />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlockchainVerifier from '@/components/blockchain/BlockchainVerifier'

export default {
  components: {
    BlockchainVerifier,
  },
}
</script>
