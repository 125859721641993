import store from '@/store/index'
import {
  Firestore,
  Database,
  UsersDoc,
  UsersContactsCollection,
  UsersContactsDoc,
  UsersGroupsCollection,
  GetDocFromReference,
} from '@/firebase-exports'

export async function createContact(contact) {
  const contactInput = { ...contact, userRef: UsersDoc(contact.contact) }
  delete contactInput.contact
  return await Firestore.addDoc(
    UsersContactsCollection(store.state.user.id),
    contactInput
  )
}

export async function listContacts() {
  const q = Firestore.query(
    UsersContactsCollection(store.state.user.id),
    Firestore.orderBy('customName', 'asc')
  )
  const querySnapshot = await Firestore.getDocs(q)
  return await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.user = await GetDocFromReference(document.userRef)
      return { id: doc.id, ...document }
    })
  )
}

export async function updateContact(id, data) {
  return await Firestore.updateDoc(
    UsersContactsDoc(store.state.user.id, id),
    data
  )
}

export async function deleteContact(id) {
  const contactRef = UsersContactsDoc(store.state.user.id, id)

  const groupsCollection = UsersGroupsCollection(store.state.user.id)

  const querySnapshot = await Firestore.getDocs(
    Firestore.query(
      groupsCollection,
      Firestore.where('contactsRef', 'array-contains', contactRef)
    )
  )

  const batch = Firestore.writeBatch(Database)

  batch.delete(contactRef)

  if (!querySnapshot.empty) {
    querySnapshot.forEach((doc) => {
      const document = doc.data()
      if (document.contactsRef.length === 1) {
        batch.delete(Firestore.doc(groupsCollection, doc.id))
      } else {
        const index = document.contactsRef.findIndex(
          (ref) => ref.id === contactRef.id
        )
        if (index > -1) {
          document.contactsRef.splice(index, 1)
          batch.update(Firestore.doc(groupsCollection, doc.id), {
            ...document,
          })
        }
      }
    })
  }

  return await batch.commit()
}
