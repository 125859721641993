<template>
  <v-fade-transition mode="out-in">
    <v-card
      flat
      max-width="450"
      class="mx-auto pa-3"
      v-if="showUsers && initialDelay"
    >
      <v-list-item-title v-if="showTitle" class="primary--text text-h5 mb-5">
        {{ $t('sharedWith') }}
      </v-list-item-title>
      <template v-if="!users.length">{{ $t('sharedWithNobody') }}</template>
      <v-list v-else three-line class="pa-0">
        <v-divider />
        <template v-for="(item, index) in users">
          <v-list-item :key="item.id">
            <v-list-item-icon class="mr-0 my-1">
              <AppAvatar
                :userAvatar="item.user.avatarURL"
                :userName="item.user.name"
                :userRole="item.user.roleRef.id"
                :userCollegiateNumber="item.user.collegiateNumber"
                avatarSize="40"
                iconSize="24"
                class="ma-5"
              />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-html="item.user.name"></v-list-item-title>
            </v-list-item-content>
            <span v-if="item.read">
              <v-icon>mdi-check-all</v-icon>
            </span>
          </v-list-item>
          <v-divider :key="index" :inset="item.inset"></v-divider>
        </template>
      </v-list>
    </v-card>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    users: Array,
    showUsers: Boolean,
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initialDelay: false,
    }
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.initialDelay = true
  },
}
</script>
