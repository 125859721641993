<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline mb-1">
            {{ $t('contacts') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('contactsManage') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-menu left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="primary darkColor--text"
              fab
              small
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-list color="primary">
            <v-list-item @click="$router.push({ name: 'UserCreateView' })">
              <v-list-item-avatar>
                <v-icon class="darkColor--text">
                  mdi-account-plus-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="darkColor--text">
                {{ $t('createNewUser') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="item in items"
              :key="item.type"
              @click="openContactDialog('create', item)"
            >
              <v-list-item-avatar>
                <v-icon class="darkColor--text">{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="darkColor--text">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            clearable
            :label="$t('searchContact')"
            single-line
            hide-details
            :disabled="loading"
          />
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <AppSkeleton :condition="loading" type="contactList">
        <template v-slot:skeleton-content>
          <v-list height="272" class="overflow-y-auto">
            <template v-if="contacts.length !== 0">
              <v-container v-if="!filteredContacts.length" fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col cols="12" align="center" justify="center">
                    {{ $t('emptySearch') }}
                  </v-col>
                </v-row>
              </v-container>
              <v-list-item
                v-else
                v-for="(item, i) in filteredContacts"
                :key="i"
              >
                <v-list-item-icon class="my-3 mr-4">
                  <AppAvatar
                    :userAvatar="item.user.avatarURL"
                    :userName="item.customName"
                    :userRole="item.user.roleRef.id"
                    :userCollegiateNumber="item.user.collegiateNumber"
                    avatarSize="40"
                    iconSize="24"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.customName }}
                  </v-list-item-title>
                  <v-list-item-title class="subtitle">
                    {{ item.user.idNumber }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon @click="openContactDialog('update', item)">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-icon>
                  <v-icon @click="openContactDialog('delete', item)">
                    mdi-delete
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-container v-else fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" align="center" justify="center">
                  {{ $t('noContactsAssigned') }}
                </v-col>
              </v-row>
            </v-container>
          </v-list>
        </template>
      </AppSkeleton>
    </v-card>
    <ContactDialog
      :title="title"
      :showDialog="showDialog"
      :actionDialog="actionDialog"
      :contacts="contacts"
      :contactType="contactType"
      :contactProp="contactToEdit"
      @closeContactDialog="closeContactDialog"
      @confirmContactDialog="confirmContactDialog"
    />
  </div>
</template>

<script>
import ContactDialog from '@/components/contact/ContactDialog'
import getUserRoleIcon from '@/utils/get-user-role-icon'

export default {
  props: {
    contacts: Array,
    loading: Boolean,
  },
  components: {
    ContactDialog,
  },
  data() {
    return {
      search: '',
      items: [
        {
          type: 'client',
          title: 'addExistingClient',
          icon: getUserRoleIcon('client'),
        },
        {
          type: 'lawyer',
          title: 'addExistingLawyer',
          icon: getUserRoleIcon('lawyer'),
        },
        {
          type: 'solicitor',
          title: 'addExistingSolicitor',
          icon: getUserRoleIcon('solicitor'),
        },
      ],
      createContactDialog: false,
      showDialog: false,
      actionDialog: '',
      title: '',
      contactType: '',
      editContactDialog: false,
      removeContactConfirmationDialog: false,
      contactToRemove: { user: {} },
      contactToEdit: {},
    }
  },
  methods: {
    openContactDialog(action, item) {
      if (action === 'create') {
        this.contactToEdit = {}
      } else if (action === 'update') {
        this.contactToEdit = {
          id: item.id,
          customName: item.customName,
          idNumber: item.user.idNumber,
          association: item.user.associationRef?.id,
          collegiateNumber: item.user.collegiateNumber,
        }
      } else if (action === 'delete') {
        this.contactToEdit = {
          id: item.id,
          customName: item.customName,
          idNumber: item.user.idNumber,
        }
      }
      this.contactType = item.type || item.user.roleRef.id
      this.actionDialog = action
      this.title = item.title
      this.showDialog = true
    },
    closeContactDialog() {
      this.showDialog = false
      this.contactToEdit = {}
    },
    async confirmContactDialog() {
      this.showDialog = false
      this.actionDialog = ''
      this.$emit('reloadElements')
    },
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter((item) => {
        if (!this.search) return this.contacts
        return (
          item.customName.toLowerCase().includes(this.search.toLowerCase()) ||
          item.user.idNumber.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
  },
}
</script>

<style lang="scss"></style>
