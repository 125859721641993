<template>
  <v-card class="mx-auto" outlined height="425">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('userInformation') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('managePersonalUserInfo') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip
        bottom
        :open-on-click="false"
        :open-on-focus="isMobile ? false : true"
        :open-on-hover="isMobile ? false : true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary darkColor--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            @click="clickEditUserInfo"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">{{ $t('editCancel') }}</span>
        <span v-else>{{ $t('editInformation') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-form ref="informationForm" v-model="validChangeUserInfo" lazy-validation>
      <v-list-item>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                disabled
                v-model="userModified['name']"
                :label="$t('name')"
                prepend-icon="mdi-account-outline"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                disabled
                v-model="userModified['taxNumber']"
                :label="$t('taxNumber')"
                prepend-icon="mdi-card-account-details-outline"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                :disabled="!isEditingUserInfo"
                v-model="userModified['email']"
                :label="$t('email')"
                prepend-icon="mdi-email-outline"
                :rules="emailRules"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                :disabled="!isEditingUserInfo || loading"
                :loading="loading"
                @click="updateUserInfo()"
                block
                class="primary darkColor--text"
              >
                {{ $t('updateData') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingUser') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateUserEmail } from '@/services/user-service'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'

export default {
  async mounted() {
    this.user = { ...this.$store.state.user }
    this.userModified = { ...this.user }
    if (!this.user) return this.setNotFound(true)
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  data() {
    return {
      user: {},
      userModified: {},
      isEditingUserInfo: false,
      loading: false,
      emailRules: [rules.required, rules.email],
      validChangeUserInfo: false,
    }
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    async updateUserInfo() {
      if (!this.$refs.informationForm.validate()) return

      if (this.userModified.email === this.user.email) {
        this.clickEditUserInfo()
        return
      }
      this.loading = true
      try {
        await updateUserEmail({
          newEmail: this.userModified.email,
        })

        const message = this.$t('updatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })

        this.isEditingUserInfo = false
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.user = { ...this.userModified }
    },
    clickEditUserInfo() {
      this.userModified = { ...this.user }
      this.isEditingUserInfo = !this.isEditingUserInfo
    },
  },
}
</script>
