import store from '@/store'

import LoginView from '@/views/LoginView'
import ContactsView from '@/views/ContactsView'
import SettingsView from '@/views/SettingsView'
import HelpView from '@/views/HelpView'
import BlockchainVerifierView from '@/views/BlockchainVerifierView'
import NotificationListView from '@/views/NotificationListView'
import UserCreateView from '@/views/UserCreateView'
import UserListView from '@/views/UserListView'
import UserDetailView from '@/views/UserDetailView'
import CertificationCreateView from '@/views/CertificationCreateView'
import CertificationListView from '@/views/CertificationListView'
import RecordCreateView from '@/views/RecordCreateView'
import RecordUpdateView from '@/views/RecordUpdateView'
import RecordListView from '@/views/RecordListView'
import CertificationDetailView from '@/views/CertificationDetailView'
import RecordDetailView from '@/views/RecordDetailView'
import ProcedureCreateView from '@/views/ProcedureCreateView'
import ProcedureListView from '@/views/ProcedureListView'
import ProcedureDetailView from '@/views/ProcedureDetailView'
import ProcedureInformationView from '@/views/ProcedureInformationView'
import NotFound from '@/views/NotFoundView'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {
      tabTitle: 'login',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/contacts',
    name: 'ContactsView',
    component: ContactsView,
    meta: {
      tabTitle: 'contacts',
      userCanAccess: () => store.getters.userRole('users.contacts.list'),
      navigationConfig: () => [
        {
          parent: undefined,
          title: 'contacts',
          icon: 'mdi-book-open-variant',
          includeTitle: true,
        },
      ],
    },
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView,
    meta: {
      tabTitle: 'profile',
      userCanAccess: () => store.getters.isUserSignedIn,
      navigationConfig: () => [
        {
          parent: 'profile',
          title: 'settings',
          icon: 'mdi-cog-outline',
        },
      ],
    },
  },
  {
    path: '/help',
    name: 'HelpView',
    component: HelpView,
    meta: {
      tabTitle: 'help',
      userCanAccess: () => store.getters.isUserSignedIn,
      navigationConfig: () => [
        {
          parent: 'profile',
          title: 'help',
          icon: 'mdi-help-circle-outline',
        },
      ],
    },
  },
  {
    path: '/blockchain/verify',
    name: 'BlockchainVerifierView',
    component: BlockchainVerifierView,
    meta: {
      tabTitle: 'blockchainVerifier',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
  {
    path: '/create/user',
    name: 'UserCreateView',
    component: UserCreateView,
    beforeEnter: (to, from, next) => {
      to.params.skipRoles = !store.getters.isUserAdmin
      next()
    },
    meta: {
      tabTitle: 'userCreate',
      userCanAccess: () => store.getters.userRole('users.create'),
      navigationConfig: () => {
        return store.getters.isUserAdmin
          ? [
              {
                parent: 'users',
                title: 'createUser',
                icon: 'mdi-account-plus-outline',
              },
            ]
          : []
      },
    },
  },
  {
    path: '/users',
    name: 'UserListView',
    component: UserListView,
    meta: {
      tabTitle: 'userList',
      userCanAccess: () => store.getters.userRole('users.list'),
      navigationConfig: () => {
        return store.getters.isUserAdmin
          ? [
              {
                parent: 'users',
                title: 'listUsers',
                icon: 'mdi-account-group-outline',
              },
            ]
          : []
      },
    },
  },
  {
    path: '/user/:id',
    name: 'UserDetailView',
    component: UserDetailView,
    meta: {
      tabTitle: 'userDetail',
      userCanAccess: () => store.getters.userRole('users.get'),
      navigationConfig: () => [],
    },
  },
  {
    path: '/create/certification',
    name: 'CertificationCreateView',
    component: CertificationCreateView,
    meta: {
      tabTitle: 'certificationCreate',
      userCanAccess: () => store.getters.userRole('certifications.create'),
      navigationConfig: () => [
        {
          parent: 'certifications',
          title: 'createCertification',
          icon: 'mdi-plus',
        },
      ],
    },
  },
  {
    path: '/certifications',
    name: 'CertificationListView',
    component: CertificationListView,
    meta: {
      tabTitle: 'certificationList',
      userCanAccess: () => store.getters.userRole('certifications.list'),
      navigationConfig: () => [
        {
          parent: 'certifications',
          title: 'myCertifications',
          icon: 'mdi-file-document-multiple-outline',
        },
      ],
    },
  },
  {
    path: '/create/record',
    name: 'RecordCreateView',
    component: RecordCreateView,
    meta: {
      tabTitle: 'recordCreate',
      userCanAccess: () => store.getters.userRole('records.create'),
      navigationConfig: () => [
        {
          parent: 'records',
          title: 'createRecord',
          icon: 'mdi-plus',
        },
      ],
    },
  },
  {
    path: '/update/record/:id',
    name: 'RecordUpdateView',
    component: RecordUpdateView,
    meta: {
      tabTitle: 'recordUpdate',
      userCanAccess: () => store.getters.userRole('records.update'),
      navigationConfig: () => [],
    },
  },
  {
    path: '/records',
    name: 'RecordListView',
    component: RecordListView,
    meta: {
      tabTitle: 'recordList',
      userCanAccess: () => store.getters.userRole('records.list'),
      navigationConfig: () => [
        {
          parent: 'records',
          title: 'myRecords',
          icon: 'mdi-folder-multiple',
        },
      ],
    },
  },
  {
    path: '/certification/:id',
    name: 'CertificationDetailView',
    component: CertificationDetailView,
    meta: {
      tabTitle: 'certificationDetail',
      userCanAccess: () => store.getters.userRole('certifications.get'),
      navigationConfig: () => [],
    },
  },
  {
    path: '/record/:id',
    name: 'RecordDetailView',
    component: RecordDetailView,
    meta: {
      tabTitle: 'recordDetail',
      userCanAccess: () => store.getters.userRole('records.get'),
      navigationConfig: () => [],
    },
  },
  {
    path: '/create/procedure',
    name: 'ProcedureCreateView',
    component: ProcedureCreateView,
    meta: {
      tabTitle: 'procedureCreate',
      userCanAccess: () =>
        store.getters.isUserSolicitor ||
        store.getters.isUserAssociationSolicitor,
      navigationConfig: () => [
        {
          parent: 'procedures',
          title: 'createProcedure',
          icon: 'mdi-plus',
        },
      ],
    },
  },
  {
    path: '/procedures/:filter',
    name: 'ProcedureListView',
    component: ProcedureListView,
    meta: {
      tabTitle: 'procedureList',
      userCanAccess: (params) => {
        const filter = params.filter
        if (filter === 'my') {
          return (
            store.getters.isUserSolicitor ||
            store.getters.isUserAssociationSolicitor
          )
        } else if (filter === 'assigned') {
          return store.getters.isUserAssociationSolicitor
        } else {
          return false
        }
      },
      navigationConfig: (params) => {
        const filter = params.filter
        const icons = {
          my: 'mdi-file-document-multiple-outline',
          assigned: 'mdi-file-eye-outline',
        }
        return {
          parent: 'procedures',
          title: `${filter}Procedures`,
          icon: icons[filter],
          path: `/procedures/${filter}`,
          routerData: {
            name: 'ProcedureListView',
            params: { filter },
          },
        }
      },
    },
  },
  {
    path: '/procedure/:id',
    name: 'ProcedureDetailView',
    component: ProcedureDetailView,
    meta: {
      tabTitle: 'procedureDetail',
      userCanAccess: () =>
        store.getters.isUserSolicitor ||
        store.getters.isUserAssociationSolicitor,
      navigationConfig: () => [],
    },
  },
  {
    path: '/procedures/information',
    name: 'ProcedureInformationView',
    component: ProcedureInformationView,
    meta: {
      tabTitle: 'procedureInformation',
      userCanAccess: () =>
        store.getters.isUserSolicitor ||
        store.getters.isUserAssociationSolicitor,
      navigationConfig: () => [
        {
          parent: 'procedures',
          title: 'informationProcedure',
          icon: 'mdi-information-variant',
        },
      ],
    },
  },
  {
    path: '/notifications/:filter',
    name: 'NotificationListView',
    component: NotificationListView,
    meta: {
      tabTitle: 'notificationList',
      userCanAccess: (params) => {
        const filter = params.filter
        if (filter === 'issued') {
          return store.getters.userRole('notifications.list.issued')
        } else if (filter === 'received') {
          return store.getters.userRole('notifications.list.received')
        } else {
          return false
        }
      },
      navigationConfig: (params) => {
        const filter = params.filter
        const icons = {
          issued: 'mdi-email-arrow-right-outline',
          received: 'mdi-email-arrow-left-outline',
        }
        const data = {
          title: `${filter}Notifications`,
          icon: icons[filter],
          path: `/notifications/${filter}`,
          routerData: {
            name: 'NotificationListView',
            params: { filter },
          },
        }
        if (filter === 'issued') {
          return [{ parent: 'certifications', ...data }]
        } else if (filter === 'received') {
          const receivedConfig = []
          if (!store.getters.isMobile) {
            receivedConfig.push({
              parent: undefined,
              ...data,
              icon: 'mdi-bell-outline',
              includeBadge: true,
            })
          }
          if (!store.getters.isUserClient) {
            receivedConfig.push({ parent: 'certifications', ...data })
          }
          return receivedConfig
        }
      },
    },
  },
  {
    path: '*',
    name: 'NotFoundView',
    component: NotFound,
    meta: {
      tabTitle: 'notFound',
      userCanAccess: () => true,
      navigationConfig: () => [],
    },
  },
]

export default routes
