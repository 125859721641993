<template>
  <v-form ref="procedureSelectorForm">
    <v-row align="center" justify="center">
      <v-col cols="12" :lg="procedureType === 'apudActa' ? 6 : 12">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="6">
            <v-autocomplete
              ref="procedureTypeInput"
              :value="procedureType"
              @input="$emit('update:procedureType', $event)"
              :items="procedureTypes"
              :item-text="(type) => $t(`procedureTypes.${type.value}`)"
              :label="$t('procedureType')"
              prepend-icon="mdi-file-document-outline"
              :rules="procedureTypeRules"
              :disabled="disabled"
            />
          </v-col>
          <template v-if="procedureType === 'apudActa'">
            <v-col cols="6" lg="4" align="right" justify="right" class="pr-4">
              <span :disabled="disabled"> {{ $t('legalEntity') }} </span>
            </v-col>
            <v-col cols="6" lg="2">
              <v-switch
                :value="procedureLegalEntity"
                @change="$emit('update:procedureLegalEntity', !!$event)"
                inset
                :disabled="disabled"
              />
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    procedureType: String,
    procedureLegalEntity: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      procedureTypeRules: [rules.required],
      procedureTypes: [
        { value: 'apudActa' },
        { value: 'gownCertificate' },
        { value: 'childCertificate' },
        { value: 'marriageCertificate' },
      ],
    }
  },
}
</script>
