import Vue from 'vue'
import App from '@/App'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/i18n'

import AppAvatar from '@/components/commons/AppAvatar'
import AppSkeleton from '@/components/commons/AppSkeleton'

import { App as FirebaseApp, Analytics } from '@/firebase-exports'

Vue.component('AppAvatar', AppAvatar)
Vue.component('AppSkeleton', AppSkeleton)

Analytics.getAnalytics(FirebaseApp)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
