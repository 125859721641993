<template>
  <div>
    <!-- slot for parent component to activate the file changer -->
    <slot name="activator">
      <v-container>
        <div v-if="!value" class="drop-zone" v-ripple @click="openFilePicker">
          <div class="drop-zone-content">
            <img class="file-icon" src="@/assets/upload-image.svg" />
            <h3>{{ $t('uploadFile', { fileType }) }}</h3>
            <p className="label">{{ $t('clickFileUpload') }}</p>
            <v-alert outlined type="error" class="text-left pa-4 mx-8">
              {{ $t('missingFileError') }}
            </v-alert>
          </div>
        </div>
      </v-container>
      <v-container v-if="value && !disabled">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <v-btn class="ma-3 mb-0" text @click="openFilePicker">
              <v-icon left>mdi-pencil</v-icon>
              {{ $t('changeFile') }}
            </v-btn>
            <v-btn class="ma-3 mb-0" text @click="reset">
              <v-icon left>mdi-delete</v-icon>
              {{ $t('removeFile') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </slot>
    <!-- file input: style is set to hidden and assigned a ref so that it can be triggered -->
    <v-form ref="procedureFilePickerInput">
      <v-file-input
        :value="value"
        ref="fileInput"
        @change="onFileChange($event)"
        :rules="fileRules"
        style="display: none"
      />
    </v-form>
  </div>
</template>

<script>
import rules from '@/utils/rules'

export default {
  data() {
    return {
      fileRules: [rules.file],
    }
  },
  props: {
    value: File,
    fileType: String,
    disabled: Boolean,
  },
  methods: {
    openFilePicker() {
      this.$refs.fileInput.$refs.input.click()
    },
    onFileChange(file) {
      this.$emit('input', file)
    },
    reset() {
      this.$emit('input', undefined)
    },
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop-zone {
  width: 400px;
  height: 300px;
  background-color: #f6f8fb;
  border: 2px dashed #bdbdbd;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop-zone-content {
  color: #bdbdbd;
}
.label {
  font-size: 12px;
  color: #bdbdbd;
}
.file-icon {
  width: 50px;
}
</style>
