<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <ProcedureDetail />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcedureDetail from '@/components/procedure/ProcedureDetail'

export default {
  components: {
    ProcedureDetail,
  },
}
</script>

<style lang="scss" scoped></style>
