const mutations = {
  setRedirectPath(state, redirectPath) {
    state.redirectPath = redirectPath
  },
  setNotFound(state, notFound) {
    state.notFound = notFound
  },
  setNavigationDrawer(state, navigationDrawer) {
    state.navigationDrawer = navigationDrawer
  },
  setSnackbar(
    state,
    { isDisplayed = true, position, message, color = 'red', timeout = 5000 }
  ) {
    const snackbar = { isDisplayed, position, message, color, timeout }
    state.snackbar = snackbar
  },
  setDialog(state, { isDisplayed = true, title, message }) {
    const dialog = { isDisplayed, title, message }
    state.dialog = dialog
  },
  setUser(state, user) {
    state.user = user
  },
  setNavigationPages(state, navigationPages) {
    state.navigationPages = navigationPages
  },
  resetNotifications(state) {
    state.receivedNotifications = []
    state.issuedNotifications = []
  },
  setUnsubscribeIssuedNotification(state, method) {
    state.issuedNotificationsUnsubscribe = method
  },
  setUnsubscribeReceivedNotification(state, method) {
    state.receivedNotificationsUnsubscribe = method
  },
  setReceivedNotification(state, receivedNotifications) {
    state.receivedNotifications = receivedNotifications
  },
  setIssuedNotification(state, issuedNotifications) {
    state.issuedNotifications = issuedNotifications
  },
  setRemoteAppVersion(state, remoteAppVersion) {
    state.remoteAppVersion = remoteAppVersion
  },
}

export default mutations
