<template>
  <v-card class="mx-auto">
    <v-list-item>
      <v-btn
        class="primary darkColor--text mr-4"
        fab
        small
        @click="$router.push({ name: 'UserListView' })"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('userInformation') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('managePersonalUserInfo') }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-tooltip
        bottom
        :open-on-click="false"
        :open-on-focus="!isMobile"
        :open-on-hover="!isMobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary darkColor--text mr-2"
            fab
            small
            :disabled="loading || initialLoading"
            v-bind="attrs"
            v-on="on"
            @click="editUserAccountStatus()"
          >
            <v-icon v-if="!userModified.disabled"> mdi-account-cancel </v-icon>
            <v-icon v-else> mdi-account-check </v-icon>
          </v-btn>
        </template>
        <span v-if="!userModified.disabled">{{ $t('disableUser') }}</span>
        <span v-else>{{ $t('enableUser') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        :open-on-click="false"
        :open-on-focus="!isMobile"
        :open-on-hover="!isMobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="primary darkColor--text"
            fab
            small
            v-bind="attrs"
            v-on="on"
            :disabled="loading || initialLoading"
            @click="clickEditUserInfo()"
          >
            <v-icon v-if="isEditingUserInfo"> mdi-close </v-icon>
            <v-icon v-else> mdi-pencil </v-icon>
          </v-btn>
        </template>
        <span v-if="isEditingUserInfo">{{ $t('editCancel') }}</span>
        <span v-else>{{ $t('editInformation') }}</span>
      </v-tooltip>
    </v-list-item>
    <v-form ref="informationForm" v-model="validChangeUserInfo" lazy-validation>
      <v-list-item>
        <v-container>
          <template>
            <v-row align="center" justify="center">
              <v-col cols="12" md="6">
                <AppSkeleton :condition="initialLoading" type="userDetailField">
                  <template v-slot:skeleton-content>
                    <v-text-field
                      :disabled="!isEditingUserInfo || loading"
                      v-model="userModified.name"
                      :label="$t('name')"
                      :rules="nameRules"
                      prepend-icon="mdi-account-outline"
                    />
                  </template>
                </AppSkeleton>
              </v-col>
              <v-col cols="12" md="6">
                <AppSkeleton :condition="initialLoading" type="userDetailField">
                  <template v-slot:skeleton-content>
                    <v-text-field
                      :disabled="!isEditingUserInfo || loading"
                      v-model="userModified.taxNumber"
                      @keyup="fixAndUppercase"
                      :label="$t('taxNumber')"
                      :rules="taxNumberRules"
                      prepend-icon="mdi-card-account-details-outline"
                    />
                  </template>
                </AppSkeleton>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <AppSkeleton :condition="initialLoading" type="userDetailField">
                  <template v-slot:skeleton-content>
                    <v-text-field
                      :disabled="!isEditingUserInfo || loading"
                      v-model="userModified.email"
                      :label="$t('email')"
                      :rules="emailRules"
                      prepend-icon="mdi-email-outline"
                    />
                  </template>
                </AppSkeleton>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <template
                v-for="roleElement in [
                  'lawyer',
                  'solicitor',
                  'associationSolicitor',
                ]"
              >
                <v-col
                  :cols="initialLoading ? 12 : 6"
                  :lg="initialLoading ? 6 : 3"
                  align="right"
                  justify="right"
                  class="pr-4 py-0"
                  :key="`${roleElement}Title`"
                >
                  <AppSkeleton
                    :condition="initialLoading"
                    type="userDetailSwitch"
                  >
                    <template v-slot:skeleton-content>
                      <h3>{{ $t(roleElement) }}</h3>
                    </template>
                  </AppSkeleton>
                </v-col>
                <v-fade-transition mode="out-in" :key="`${roleElement}Switch`">
                  <v-col v-if="!initialLoading" cols="6" lg="3" class="py-0">
                    <v-switch
                      v-model="userModified.roleRef"
                      :value="roleElement"
                      inset
                      :disabled="!isEditingUserInfo || loading"
                      @change="
                        if (!userModified.roleRef)
                          userModified.roleRef = 'client'
                        delete userModified.associationRef
                        delete userModified.collegiateNumber
                      "
                    />
                  </v-col>
                </v-fade-transition>
              </template>
            </v-row>
            <v-fade-transition mode="out-in">
              <v-row
                v-if="!initialLoading && userModified.roleRef !== 'client'"
              >
                <v-col
                  cols="12"
                  :lg="userModified.roleRef === 'associationSolicitor' ? 12 : 6"
                >
                  <v-autocomplete
                    v-model="userModified.associationRef"
                    :items="associationList[userModified.roleRef]"
                    item-text="name"
                    item-value="id"
                    prepend-icon="mdi-office-building-outline"
                    :label="$t('selectAssociation')"
                    :rules="associationRules"
                    :disabled="!isEditingUserInfo || loading"
                    :menu-props="{ bottom: true, offsetY: true }"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  v-if="
                    userModified.roleRef === 'lawyer' ||
                    userModified.roleRef === 'solicitor'
                  "
                >
                  <v-text-field
                    v-model="userModified.collegiateNumber"
                    prepend-icon="mdi-numeric"
                    :label="$t('collegiateNumber')"
                    type="Number"
                    min="1"
                    :rules="collegiateNumberRules"
                    :disabled="!isEditingUserInfo || loading"
                  />
                </v-col>
              </v-row>
            </v-fade-transition>
          </template>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-btn
                :disabled="!isEditingUserInfo || loading"
                :loading="loading"
                @click="updateUserInfo()"
                block
                class="primary darkColor--text"
              >
                {{ $t('updateData') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingUser') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getUser, updateUser, disableUser } from '@/services/user-service'
import {
  listAssociations,
  createUserIdNumber,
} from '@/services/association-service'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'

export default {
  async mounted() {
    const userGet = await getUser(this.$route.params.id)
    if (!userGet) return this.setNotFound(true)

    this.associationList = await listAssociations()

    await new Promise((resolve) => setTimeout(resolve, 500))
    this.user = userGet
    this.assignUserModified()

    this.initialLoading = false
    this.$refs.informationForm.resetValidation()
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  data() {
    return {
      initialLoading: true,
      isEditingUserInfo: false,
      taxNumberRules: [rules.idNumber],
      nameRules: [rules.required],
      emailRules: [rules.required, rules.email],
      associationRules: [rules.required],
      collegiateNumberRules: [rules.required, rules.number],
      validChangeUserInfo: false,
      user: {},
      userModified: {},
      associationList: { lawyer: [], solicitor: [] },
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setNotFound', 'setSnackbar']),
    assignUserModified() {
      this.userModified = { ...this.user }
    },
    fixAndUppercase() {
      const regex = /[^a-zA-Z0-9]/g
      this.userModified.taxNumber = this.userModified.taxNumber
        .replace(regex, '')
        .toUpperCase()
    },
    async updateUserInfo() {
      if (!this.$refs.informationForm.validate()) return

      const userEquals = (user1, user2) =>
        Object.keys(user1).every((key) => user1[key] === user2[key]) &&
        Object.keys(user2).every((key) => user2[key] === user1[key])

      if (userEquals(this.userModified, this.user)) {
        this.clickEditUserInfo()
        return
      }
      this.loading = true
      try {
        this.userModified.idNumber = await createUserIdNumber(this.userModified)
        delete this.userModified.registeredByRef
        await updateUser(this.userModified)

        const message = this.$t('updatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })

        this.isEditingUserInfo = false
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.user = {
        ...this.userModified,
      }
    },
    async editUserAccountStatus() {
      if (this.isEditingUserInfo) this.clickEditUserInfo()
      this.loading = true
      try {
        this.userModified.disabled = !this.userModified.disabled
        await disableUser({
          id: this.userModified.id,
          disabled: this.userModified.disabled,
        })

        const message = this.$t('updatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        this.userModified.disabled = !this.userModified.disabled

        const message = this.$t('updatingUserError')
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.user = { ...this.userModified }
    },
    clickEditUserInfo() {
      if (this.isEditingUserInfo) {
        this.userModified = { ...this.user }
        this.assignUserModified()
      }
      this.isEditingUserInfo = !this.isEditingUserInfo
    },
  },
}
</script>
