var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"inset":""},on:{"click:outside":_vm.closeSheet},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-top-right-bold-outline ")]),_vm._v(" "+_vm._s(_vm.$t('share'))+" ")],1)]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-stepper',{staticClass:"rounded-b-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-row',[_c('v-spacer'),(_vm.step === 1)?_c('v-text-field',{staticClass:"mt-2",attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":_vm.$t('searchClient'),"single-line":"","hide-details":""},on:{"input":function($event){_vm.selectedItem = ''},"click:clear":function($event){_vm.selectedItem = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ma-5",attrs:{"icon":""},on:{"click":_vm.closeSheet}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"1"}},[_c('v-container',{staticClass:"text-center"},[_c('v-slide-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_c('v-slide-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && _vm.search.length !== 0),expression:"search && search.length !== 0"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-2 pa-2",attrs:{"flat":"","color":active ? 'secondary' : undefined,"width":"100"},on:{"click":toggle}},[_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"secondary--text"},[_vm._v(" mdi-account-outline ")])],1),_c('v-list-item-subtitle',{class:active ? 'white--text' : undefined},[_vm._v(" "+_vm._s(_vm.$t('sentToUser'))+" ")]),_c('v-list-item-subtitle',{class:active ? 'white--text' : undefined},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]}}])}),_vm._l((_vm.filteredItems),function(item,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-2 pa-2",attrs:{"flat":"","color":active ? 'primary' : undefined,"width":"100"},on:{"click":toggle}},[_c('v-list-item-icon',{staticClass:"mr-0 mt-0"},[_c('AppAvatar',{attrs:{"isGroup":item.type !== 'contact',"userAvatar":item.user ? item.user.avatarURL : '',"userName":item.customName,"userRole":item.user ? item.user.roleRef.id : 'group',"userCollegiateNumber":item.user ? item.user.collegiateNumber : undefined,"avatarSize":"40","iconSize":"24"}})],1),_c('v-list-item-subtitle',{class:active ? 'white--text' : undefined},[_vm._v(" "+_vm._s(item.customName)+" ")]),_c('v-list-item-subtitle',{class:active ? 'white--text' : undefined},[_vm._v(" "+_vm._s(item.user ? item.user.collegiateNumber || item.user.idNumber : _vm.$tc('contactCount', item.contacts.length))+" ")])],1)]}}],null,true)})})],2)],1)],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"2"}},[_c('v-container',[_c('AppUserSummary',{attrs:{"clientName":_vm.itemInfo.name,"clientAvatar":_vm.itemInfo.user ? _vm.itemInfo.user.avatarURL : '',"clientIdNumber":_vm.itemInfo.id || _vm.$tc('contactCount', _vm.itemInfo.contacts.length),"clientRole":_vm.itemInfo.user ? _vm.itemInfo.user.roleRef.id : 'group',"clientCollegiateNumber":_vm.itemInfo.user ? _vm.itemInfo.user.collegiateNumber : undefined}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.step !== 1)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.step--}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.selectedItem === undefined || _vm.selectedItem === '' || _vm.loading,"loading":_vm.loading},on:{"click":_vm.nextStep},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('creatingNotification')))])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t(_vm.stepsInfo[_vm.step - 1].nextAction))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }