<template>
  <v-form ref="certificationForm">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6">
        <v-row align="center" justify="center">
          <v-col cols="12" v-if="disabled">
            <v-text-field
              :value="`${client.idNumber} - ${client.name}`"
              :label="$t('client')"
              prepend-icon="mdi-account-outline"
              disabled
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              :value="certificationName"
              @input="$emit('update:certificationName', $event)"
              :label="$t('certificationName')"
              prepend-icon="mdi-certificate-outline"
              :rules="nameRules"
              :disabled="disabled"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              ref="certificationTypeInput"
              :value="certificationType"
              @input="$emit('update:certificationType', $event)"
              :items="certificationTypes"
              :label="$t('certificationType')"
              prepend-icon="mdi-file-document-outline"
              :rules="certifierRules"
              :disabled="disabled"
              @keyup="fixCertificationTypeInput"
              :search-input.sync="searchCertType"
            >
              <template v-slot:no-data>
                <div style="display: none" />
              </template>
              <template v-slot:append-item>
                <v-list-item
                  v-if="certificationTypeAvailable"
                  @click="addNewCertificationType"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon left> mdi-plus </v-icon>
                      {{ $t('certTypeNotFound') }}
                      "{{ createCertTypeButtonText }}"
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-textarea
              :value="certificationDescription"
              @input="$emit('update:certificationDescription', $event)"
              :rules="descriptionRules"
              :label="$t('descriptionInput')"
              prepend-icon="mdi-information-outline"
              rows="3"
              :disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <AppMedia
          v-if="certificationFile"
          :fileURL="fileURL"
          :fileContentType="certificationFile.type"
          contain
        />
        <AppFilePicker
          ref="certificationFilePicker"
          :value="certificationFile"
          @input="$emit('update:certificationFile', $event)"
          :fileType="$t('certification')"
          :disabled="disabled"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import rules from '@/utils/rules'
import {
  listCertificationTypes,
  createCustomCertificationType,
} from '@/services/certification-type-service'
import AppMedia from '@/components/commons/AppMedia'
import AppFilePicker from '@/components/commons/AppFilePicker'

export default {
  components: {
    AppFilePicker,
    AppMedia,
  },
  props: {
    certificationFile: File,
    certificationName: String,
    certificationType: String,
    certificationDescription: String,
    client: Object,
    disabled: Boolean,
  },
  data() {
    return {
      searchCertType: '',
      certificationTypes: [],
      nameRules: [rules.required],
      certifierRules: [rules.required],
      fileRules: [rules.file],
      descriptionRules: [rules.required],
    }
  },
  async mounted() {
    this.certificationTypes = await listCertificationTypes()
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.certificationForm.resetValidation()
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    fileURL() {
      return URL.createObjectURL(this.certificationFile)
    },
    createCertTypeButtonText() {
      const maxLength = this.isMobile ? 10 : 25
      if (this.searchCertType) {
        return this.searchCertType.length > maxLength
          ? this.searchCertType.slice(0, maxLength) + '...'
          : this.searchCertType
      } else {
        return ''
      }
    },
    certificationTypeAvailable() {
      if (!this.searchCertType) return false
      const index = this.certificationTypes.findIndex((element) => {
        return element.toLowerCase() === this.searchCertType.toLowerCase()
      })
      return index === -1
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    fixCertificationTypeInput() {
      if (!this.searchCertType) return
      this.searchCertType = this.searchCertType.replace(/\s+/g, ' ')
    },
    async addNewCertificationType() {
      if (this.certificationTypeAvailable) {
        try {
          await createCustomCertificationType({ name: this.searchCertType })
          this.certificationTypes = await listCertificationTypes()
          this.$emit('update:certificationType', this.searchCertType)
          this.$refs.certificationTypeInput.blur()
        } catch (err) {
          const message = this.$t('creatingCertificationTypeError')
          this.setSnackbar({ position: 'top', message })
        }
      } else {
        const message = this.$t('certTypeExists')
        this.setSnackbar({ position: 'bottom', message })
      }
    },
  },
}
</script>
