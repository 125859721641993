import {
  Firestore,
  ProceduresCollection,
  ProceduresDoc,
  UsersDoc,
  GetDocFromReference,
} from '@/firebase-exports'
import store from '@/store'

export function newProcedureId() {
  return Firestore.doc(ProceduresCollection).id
}

export async function createProcedure(id, procedure) {
  const procedureInput = {
    ...procedure,
    createdAt: Firestore.serverTimestamp(),
    ownerRef: UsersDoc(procedure.owner),
    creatorRef: UsersDoc(procedure.creator),
    phonePrefix: procedure.prefix.prefix,
    phoneNumber: procedure.phone,
    status: 'created',
  }
  delete procedureInput.creator
  delete procedureInput.owner
  delete procedureInput.prefix
  delete procedureInput.phone

  return await Firestore.setDoc(ProceduresDoc(id), procedureInput)
}

export async function listProcedures(filter) {
  var procedureUserField
  if (filter === 'my') {
    procedureUserField = 'creatorRef'
  } else if (filter === 'assigned') {
    procedureUserField = 'ownerRef'
  } else {
    throw new Error('procedure filter param invalid')
  }

  const querySnapshot = await Firestore.getDocs(
    Firestore.query(
      ProceduresCollection,
      Firestore.where(procedureUserField, '==', UsersDoc(store.state.user.id)),
      Firestore.orderBy('createdAt', 'desc')
    )
  )
  return await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.createdAt = new Date(document.createdAt.toDate())
      document.owner = await GetDocFromReference(document.ownerRef)
      document.creator = await GetDocFromReference(document.creatorRef)
      if (document.isLegalEntity) {
        document.name = document.legalName
      } else {
        document.name = document.firstName + ' ' + document.lastName
      }
      return { id: doc.id, ...document }
    })
  )
}

export async function getProcedure(id) {
  var procedure = undefined
  const docSnap = await Firestore.getDoc(ProceduresDoc(id))
  const document = docSnap.data()
  document.createdAt = new Date(document.createdAt.toDate())
  document.owner = await GetDocFromReference(document.ownerRef)
  document.creator = await GetDocFromReference(document.creatorRef)
  document.creator.association = await GetDocFromReference(
    document.creator.associationRef
  )
  document.name = document.firstName + ' ' + document.lastName
  if (document) procedure = { id, ...document }
  return procedure
}

export async function updateProcedure(id, data) {
  if (data.status === 'rejected' && !data.rejectReason) {
    throw new Error('reject status requires rejectReason')
  }
  return await Firestore.updateDoc(ProceduresDoc(id), data)
}
