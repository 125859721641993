import {
  Firestore,
  RecordsCollection,
  RecordsDoc,
  RecordsWhitelistDoc,
  UsersDoc,
  RecordFilesDoc,
  RecordFilesCollection,
  RecordFilesWhitelistCollection,
  RecordFilesHistoryCollection,
  GetDocFromReference,
} from '@/firebase-exports'
import store from '@/store'

export function newRecordFileId(recordId) {
  return Firestore.doc(RecordFilesCollection(recordId)).id
}

export async function createRecord(record) {
  const recordInput = {
    ...record,
    createdAt: Firestore.serverTimestamp(),
    clientRef: UsersDoc(record.client),
    creatorRef: UsersDoc(record.creator),
  }
  delete recordInput.client
  delete recordInput.creator

  var response = await Firestore.addDoc(RecordsCollection, recordInput)

  return response
}

export async function listRecords() {
  const querySnapshot = await Firestore.getDocs(
    Firestore.query(
      RecordsCollection,
      Firestore.where('creatorRef', '==', UsersDoc(store.state.user.id)),
      Firestore.orderBy('createdAt', 'desc')
    )
  )
  return await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.createdAt = new Date(document.createdAt.toDate())
      if (document.trialDate) {
        document.trialDate = new Date(document.trialDate.toDate())
      }
      document.client = await GetDocFromReference(document.clientRef)
      document.creator = await GetDocFromReference(document.creatorRef)
      return { id: doc.id, ...document }
    })
  )
}

export async function getRecord(id) {
  var record = undefined

  const docSnap = await Firestore.getDoc(RecordsDoc(id))
  const document = docSnap.data()
  document.createdAt = new Date(document.createdAt.toDate())
  if (document.trialDate) {
    document.trialDate = new Date(document.trialDate.toDate())
  }
  document.client = await GetDocFromReference(document.clientRef)
  document.creator = await GetDocFromReference(document.creatorRef)
  document.creator.association = await GetDocFromReference(
    document.creator.associationRef
  )
  document.files = []
  document.files = await getRecordFiles(
    id,
    document.creatorRef.id === store.state.user.id
  )

  if (document) record = { id, ...document }

  return record
}

export async function getRecordToUpdate(id) {
  var record = await getRecord(id)

  if (record) {
    delete record.id
    delete record.createdAt
    delete record.clientRef
    delete record.client
    delete record.creatorRef
    delete record.creator
    delete record.files
  }

  return record
}

export async function updateRecord(id, record) {
  return await Firestore.updateDoc(RecordsDoc(id), record)
}

async function getRecordFiles(id, isCreator) {
  if (isCreator) {
    const querySnapshot = await Firestore.getDocs(
      Firestore.query(
        RecordFilesCollection(id),
        Firestore.orderBy('createdAt', 'desc')
      )
    )

    return await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const document = doc.data()
        document.createdAt = new Date(document.createdAt.toDate())
        document.client = await GetDocFromReference(document.clientRef)
        document.creator = await GetDocFromReference(document.creatorRef)
        document.whitelist = await getRecordFileWhitelist(id, doc.id)
        return { id: doc.id, ...document }
      })
    )
  } else {
    const docSnap = await Firestore.getDoc(
      RecordsWhitelistDoc(id, store.state.user.id)
    )

    const allowedFiles = docSnap.data().sharedFiles

    return (
      await Promise.all(
        allowedFiles.map(async (doc) => {
          const document = await GetDocFromReference(doc)
          document.createdAt = new Date(document.createdAt.toDate())
          document.client = await GetDocFromReference(document.clientRef)
          document.creator = await GetDocFromReference(document.creatorRef)
          document.whitelist = () => {}
          return { id: doc.id, ...document }
        })
      )
    ).sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
  }
}
export async function createRecordFile(recordId, fileId, file) {
  const recordFileInput = {
    ...file,
    createdAt: Firestore.serverTimestamp(),
    clientRef: UsersDoc(file.client),
    creatorRef: UsersDoc(file.creator),
    recordRef: RecordsDoc(file.record),
  }

  delete recordFileInput.client
  delete recordFileInput.creator

  var response = await Firestore.setDoc(
    RecordFilesDoc(recordId, fileId),
    recordFileInput
  )
  return response
}

export async function getRecordFile(recordId, fileId, context) {
  var file = undefined

  const docSnap = await Firestore.getDoc(RecordFilesDoc(recordId, fileId))
  const document = docSnap.data()
  document.createdAt = new Date(document.createdAt.toDate())
  document.client = await GetDocFromReference(document.clientRef)
  document.creator = await GetDocFromReference(document.creatorRef)
  document.creator.association = await GetDocFromReference(
    document.creator.associationRef
  )
  document.history = []
  document.historyUnsubscribe = await getRecordFileHistorySubscription(
    recordId,
    fileId,
    context,
    document.creatorRef.id === store.state.user.id
  )
  document.whitelist = []
  document.whitelistUnsubscribe =
    document.creatorRef.id === store.state.user.id
      ? await getRecordFileWhitelistSubscription(recordId, fileId, context)
      : () => {}
  if (document) file = { fileId, ...document }

  return file
}

async function getRecordFileHistorySubscription(
  recordId,
  fileId,
  context,
  isCreator
) {
  const q = isCreator
    ? Firestore.query(
        RecordFilesHistoryCollection(recordId, fileId),
        Firestore.orderBy('createdAt', 'desc')
      )
    : Firestore.query(
        RecordFilesHistoryCollection(recordId, fileId),
        Firestore.orderBy('createdAt', 'desc'),
        Firestore.where('userRef', '==', UsersDoc(store.state.user.id))
      )
  var historyUnsubscribe = Firestore.onSnapshot(
    q,
    async (querySnapshot) => {
      context.recordFile.history = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          document.createdAt = new Date(document.createdAt.toDate())
          if (document.blockchainRef) {
            document.blockchain = await GetDocFromReference(
              document.blockchainRef
            )
          }
          if (isCreator) {
            document.user = await GetDocFromReference(document.userRef)
            document.user.association = await GetDocFromReference(
              document.user.associationRef
            )
          }
          return { id: doc.id, ...document }
        })
      )
    },
    (error) => {
      throw error
    }
  )
  return historyUnsubscribe
}

async function getRecordFileWhitelistSubscription(recordId, fileId, context) {
  const q = Firestore.query(
    RecordFilesWhitelistCollection(recordId, fileId)
    //,Firestore.orderBy('read', 'desc')
  )

  var whitelistUnsubscribe = Firestore.onSnapshot(
    q,
    async (querySnapshot) => {
      context.recordFile.whitelist = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const document = doc.data()
          document.user = await GetDocFromReference(document.userRef)
          var finalDoc = { id: doc.id, ...document }
          return finalDoc
        })
      )
    },
    (error) => {
      throw error
    }
  )
  return whitelistUnsubscribe
}
export async function getRecordFileWhitelist(recordId, fileId) {
  var whitelist = []
  const q = Firestore.query(
    RecordFilesWhitelistCollection(recordId, fileId)
    //,Firestore.orderBy('read', 'desc')
  )

  const querySnapshot = await Firestore.getDocs(q)
  whitelist = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.user = await GetDocFromReference(document.userRef)
      return { id: doc.id, ...document }
    })
  )
  return whitelist
}
