<template>
  <v-card class="mx-auto" outlined height="425">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline mb-1">
          {{ $t('credentials') }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ $t('managePassword') }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-form
      ref="changePasswordForm"
      v-model="validChangePassword"
      lazy-validation
    >
      <v-list-item>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="currentPassword"
                :rules="passwordRules"
                :label="$t('currentPassword')"
                :type="showCurrentPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                prepend-icon="mdi-form-textbox-password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="newPassword"
                :rules="newPassRules"
                :label="$t('newPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="$t('passRequirements')"
                @click:append="showNewPassword = !showNewPassword"
                prepend-icon="mdi-form-textbox-password"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                v-model="confirmPassword"
                :rules="repeatNewPassRules"
                :label="$t('confirmPassword')"
                :type="showRepeatNewPassword ? 'text' : 'password'"
                autocomplete="on"
                :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="$t('passRequirements')"
                @click:append="showRepeatNewPassword = !showRepeatNewPassword"
                prepend-icon="mdi-form-textbox-password"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                block
                :disabled="loading"
                :loading="loading"
                class="primary darkColor--text"
                @click="updatePassword"
              >
                {{ $t('changePassword') }}
                <template v-slot:loader>
                  <v-progress-circular indeterminate size="20" width="2" />
                  <span class="ml-2">{{ $t('updatingPassword') }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-form>
  </v-card>
</template>

<script>
import { Auth } from '@/firebase-exports'
import rules from '@/utils/rules'
import getErrorText from '@/utils/get-error-text'
import { mapMutations } from 'vuex'

export default {
  props: {},
  data() {
    return {
      loading: false,
      showCurrentPassword: false,
      passwordRules: [rules.required],
      showNewPassword: false,
      newPassRules: [rules.required, rules.passwordLength],
      showRepeatNewPassword: false,

      validChangePassword: true,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async updatePassword() {
      if (!this.$refs.changePasswordForm.validate()) return

      this.loading = true
      try {
        const user = Auth.getAuth().currentUser

        await Auth.reauthenticateWithCredential(
          user,
          Auth.EmailAuthProvider.credential(
            this.$store.state.user.email,
            this.currentPassword
          )
        )

        await Auth.updatePassword(user, this.newPassword)

        const message = this.$t('updatingPasswordSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })

        this.$refs.changePasswordForm.reset()
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })
      }

      this.loading = false
    },
  },
  computed: {
    repeatNewPassRules() {
      return [
        rules.required,
        rules.passwordLength,
        rules.passwordNotRepeated(this.newPassword),
      ]
    },
  },
}
</script>
