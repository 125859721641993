<template>
  <v-form ref="dataForm">
    <v-row>
      <v-col cols="12" lg="12">
        <v-text-field
          :disabled="disabled"
          :value="userEmail"
          @input="$emit('update:userEmail', $event)"
          :rules="emailRules"
          :label="$t('email')"
          prepend-icon="mdi-email-outline"
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-text-field
          :disabled="disabled"
          :value="userName"
          @input="$emit('update:userName', $event)"
          :label="$t('name')"
          :rules="nameRules"
          prepend-icon="mdi-account-outline"
          dense
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          :disabled="disabled"
          :value="userTaxNumber"
          @input="$emit('update:userTaxNumber', $event)"
          @keyup="fixAndUppercase"
          :rules="taxNumberRules"
          :label="$t('taxNumber')"
          prepend-icon="mdi-card-account-details-outline"
          dense
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '@/utils/rules'

export default {
  props: {
    disabled: Boolean,
    userEmail: String,
    userName: String,
    userTaxNumber: String,
  },
  data() {
    return {
      emailRules: [rules.required, rules.email],
      nameRules: [rules.required],
      taxNumberRules: [rules.required, rules.idNumber],
    }
  },
  methods: {
    fixAndUppercase() {
      const regex = /[^a-zA-Z0-9]/g
      const fixedTaxNumber = this.userTaxNumber.replace(regex, '').toUpperCase()
      this.$emit('update:userTaxNumber', fixedTaxNumber)
    },
  },
}
</script>
