<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card flat>
          <v-card-subtitle class="pa-0">
            <AppSkeleton :condition="!description" type="paragraph">
              <template v-slot:skeleton-content>
                <p class="text-justify">{{ description }}</p>
              </template>
            </AppSkeleton>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    description: String,
  },
}
</script>

<style lang="scss" scoped></style>
