<template>
  <v-card style="overflow: hidden" flat>
    <AppSkeleton :condition="!certificationFile" type="certificationFile">
      <template v-slot:skeleton-content>
        <AppMedia
          :fileURL="certificationFile.url"
          :fileContentType="certificationFile.metadata.contentType"
          :maxHeight="height ? height : '800px'"
          :contain="true"
        >
          <template v-slot:img-content>
            <v-dialog v-model="mediaDialog" fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="width: 100%; height: 100%"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-toolbar elevation="0">
                  <v-spacer />
                  <v-btn icon @click="mediaDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <AppMedia
                  style="height: 90vh"
                  :fileURL="certificationFile.url"
                  :fileContentType="certificationFile.metadata.contentType"
                  maxHeight="800px"
                  :contain="true"
                />
              </v-card>
            </v-dialog>
          </template>
        </AppMedia>
      </template>
    </AppSkeleton>
  </v-card>
</template>

<script>
import AppMedia from '@/components/commons/AppMedia'

export default {
  components: {
    AppMedia,
  },
  props: {
    certificationFile: Object,
    height: String,
  },
  data() {
    return {
      mediaDialog: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
