<template>
  <v-card class="mx-auto" max-width="750" outlined elevation="20">
    <v-stepper v-model="step">
      <v-stepper-header>
        <template v-for="n in stepsInfo.length">
          <v-stepper-step
            :step="n"
            :key="`${n}-step`"
            :complete="step > n"
            editable
            edit-icon="mdi-check"
          >
            <template v-if="n === 1">
              {{ $t(stepsInfo[n - 1].name, [$t($route.params.userRole)]) }}
            </template>
            <template v-else>
              {{ $tc(stepsInfo[n - 1].name) }}
            </template>
          </v-stepper-step>
          <v-divider v-if="n !== stepsInfo.length" :key="n" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <UserCreateInformation
              :userEmail.sync="userData.email"
              :userTaxNumber.sync="userData.taxNumber"
              :userName.sync="userData.name"
              :disabled="false"
              ref="UserCreateInformation"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <UserCreateRole
              v-if="!this.$route.params.skipRoles"
              :userRoleRef.sync="userData.roleRef"
              :userAssociationRef.sync="userData.associationRef"
              :userCollegiateNumber.sync="userData.collegiateNumber"
              ref="UserCreateRole"
            />
            <UserCreateInformation
              v-else
              :userEmail.sync="userData.email"
              :userTaxNumber.sync="userData.taxNumber"
              :userName.sync="userData.name"
              disabled
              ref="UserCreateInformationConfirm"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container>
            <UserCreateInformation
              :userEmail.sync="userData.email"
              :userTaxNumber.sync="userData.taxNumber"
              :userName.sync="userData.name"
              disabled
              ref="UserCreateInformationConfirm"
            />
            <UserCreateRole
              :userRoleRef.sync="userData.roleRef"
              :userAssociationRef.sync="userData.associationRef"
              :userCollegiateNumber.sync="userData.collegiateNumber"
              disabled
              ref="UserCreateInformationConfirm"
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text :disabled="loading" @click="previousStep">
          {{ $t(stepsInfo[step - 1].backAction) }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="loading"
          :loading="loading"
          @click="nextStep"
        >
          {{ $t(stepsInfo[step - 1].nextAction) }}
          <template v-slot:loader>
            <span>{{ $t('creatingUser') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import UserCreateInformation from '@/components/user/create/UserCreateInformation'
import UserCreateRole from '@/components/user/create/UserCreateRole'
import { createUser } from '@/services/user-service'
import { createUserIdNumber } from '@/services/association-service'
import { createContact } from '@/services/contact-service'
import getErrorText from '@/utils/get-error-text'

export default {
  components: {
    UserCreateInformation,
    UserCreateRole,
  },
  data() {
    return {
      step: 1,
      stepsInfo: [
        {
          name: 'userData',
          optional: false,
          backAction: 'exit',
          nextAction: 'next',
        },
        {
          name: 'confirm',
          optional: false,
          backAction: 'back',
          nextAction: 'confirm',
        },
      ],
      userCreateRoleStep: {
        name: 'roles',
        optional: false,
        backAction: 'back',
        nextAction: 'next',
      },
      loading: false,
      userData: {
        name: '',
        email: '',
        taxNumber: '',
        roleRef: 'client',
      },
      returnPath: {
        name: !this.$route.params.skipRoles ? 'UserListView' : 'ContactsView',
      },
    }
  },
  mounted() {
    if (!this.$route.params.skipRoles) {
      this.stepsInfo.splice(1, 0, this.userCreateRoleStep)
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.UserCreateInformation.$refs.dataForm.resetValidation()
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async previousStep() {
      if (this.step === 1) this.$router.push(this.returnPath)
      else this.step--
    },
    async nextStep() {
      switch (this.step) {
        case 1:
          if (!this.$refs.UserCreateInformation.$refs.dataForm.validate())
            return
          break

        case 2:
          if (this.$route.params.skipRoles) {
            return await this.userSignup()
          } else {
            if (!this.$refs.UserCreateRole.$refs.roleForm.validate()) return
          }
          break

        case 3:
          await this.userSignup()
          return

        default:
          break
      }
      this.step++
    },
    async userSignup() {
      this.loading = true
      try {
        this.userData.idNumber = await createUserIdNumber(this.userData)

        const newUser = await createUser(this.userData)

        if (this.isUserLawyer || this.isUserSolicitor) {
          await createContact({
            customName: this.userData.name,
            contact: newUser.id,
          })
        }

        const message = this.$t('creatingUserSuccess')
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })

        this.loading = false
        return
      }

      this.loading = false
      this.$router.push(this.returnPath)
    },
  },
  computed: {
    ...mapGetters(['isUserLawyer', 'isUserSolicitor']),
  },
}
</script>
