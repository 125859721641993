import {
  Firestore,
  GetDocFromReference,
  RolesDoc,
  UsersCollection,
  UsersCreateFunction,
  UsersDisableFunction,
  UsersDoc,
  UsersRolesFinalDoc,
  UsersUpdateEmailFunction,
  UsersUpdateInfoFunction,
} from '@/firebase-exports'

export async function createUser(user) {
  const result = await UsersCreateFunction(user)
  return result.data
}

export async function listUsers() {
  const q = Firestore.query(
    UsersCollection,
    Firestore.where('roleRef', '!=', RolesDoc('admin')),
    Firestore.orderBy('roleRef', 'asc')
  )

  const querySnapshot = await Firestore.getDocs(q)
  return querySnapshot.docs.map((doc) => {
    const document = doc.data()
    return { id: doc.id, ...document }
  })
}

export async function getUser(id) {
  const docSnap = await Firestore.getDoc(UsersDoc(id))
  if (!docSnap.exists()) return
  const document = docSnap.data()
  if (document.associationRef) {
    document.associationRef = document.associationRef.id
  }
  document.roleRef = document.roleRef.id
  return { id: docSnap.id, ...document }
}

export async function getUserByIdNumber(idNumber) {
  const q = Firestore.query(
    UsersCollection,
    Firestore.where('idNumber', '==', idNumber.toUpperCase())
  )
  const querySnapshot = await Firestore.getDocs(q)
  if (!querySnapshot.empty) {
    const id = querySnapshot.docs[0].id
    const document = querySnapshot.docs[0].data()
    document.association = await GetDocFromReference(document.associationRef)
    return { id: id, ...document }
  } else {
    return undefined
  }
}

export async function updateUserEmail(newEmail) {
  const result = await UsersUpdateEmailFunction(newEmail)
  return result.data
}

export async function updateUser(user) {
  const result = await UsersUpdateInfoFunction(user)
  return result.data
}

export async function updateAvatar(id, data) {
  return await Firestore.updateDoc(UsersDoc(id), data)
}

export async function updateUserRolesFinal(id, data) {
  const docSnap = await Firestore.getDoc(UsersRolesFinalDoc(id))
  if (docSnap.exists()) {
    return await Firestore.updateDoc(UsersRolesFinalDoc(id), data)
  } else {
    return await Firestore.setDoc(UsersRolesFinalDoc(id), data)
  }
}

export async function removeAvatar(id) {
  var data = {
    avatarRef: Firestore.deleteField(),
    avatarURL: Firestore.deleteField(),
  }
  return await Firestore.updateDoc(UsersDoc(id), data)
}

export async function disableUser({ id, disabled }) {
  const result = await UsersDisableFunction({ id, disabled })
  return result.data
}
