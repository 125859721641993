<template>
  <v-snackbar
    v-model="snackbarModel"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    :top="snackbar.position === 'top'"
    :bottom="snackbar.position === 'bottom'"
    rounded
  >
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <span class="titleSnackbar" v-if="snackbar.color == 'red'">
            <v-icon>mdi-alert-circle-outline</v-icon> {{ $t('error') }}
          </span>
          <span class="titleSnackbar" v-else-if="snackbar.color == 'green'">
            <v-icon>mdi-check-circle-outline</v-icon> {{ $t('success') }}
          </span>
        </v-col>
        <v-col cols="2">
          <v-btn icon top @click="closeSnackbar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="textSnackbar">{{ snackbar.message }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    snackbar: Object,
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    closeSnackbar() {
      this.setSnackbar({ ...this.snackbar, isDisplayed: false })
    },
  },
  computed: {
    snackbarModel: {
      get: function () {
        return this.snackbar.isDisplayed
      },
      set: function () {
        this.closeSnackbar()
      },
    },
  },
}
</script>

<style lang="scss">
.titleSnackbar {
  font-size: 25px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 700;
}
.textSnackbar {
  font-size: 17px;
}
</style>
