<template>
  <v-app-bar app color="primary" dark clipped-right>
    <v-img
      contain
      src="@/assets/app-logo.png"
      transition="fade-transition"
      max-width="175"
    />
    <v-spacer />

    <v-fade-transition mode="out-in">
      <v-toolbar-items
        style="width: 100%"
        v-if="isUserSignedIn && $route.name != 'LoginView'"
      >
        <v-spacer />

        <template v-if="isMobile">
          <AppBarItemButton includeBadge :item="notificationItem" />
          <AppBarItemButton
            v-if="isMobile"
            :item="{ icon: navigationDrawer ? 'mdi-close' : 'mdi-menu' }"
            :clickButton="() => setNavigationDrawer(!navigationDrawer)"
          />
        </template>
        <template v-else>
          <template v-for="item in $store.state.navigationPages">
            <v-fade-transition mode="out-in" :key="item.title">
              <AppBarItemMenu
                v-if="!!item.subPages && item.available"
                :title="item.title"
                :items="item.subPages"
                :isUserMenu="item.title === 'profile'"
              />
            </v-fade-transition>
            <v-fade-transition mode="out-in" :key="item.title">
              <AppBarItemButton
                v-if="!item.subPages && item.available"
                :item="item"
                :includeBadge="item.includeBadge"
                :includeTitle="item.includeTitle"
              />
            </v-fade-transition>
          </template>
        </template>
      </v-toolbar-items>
    </v-fade-transition>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AppBarItemMenu from '@/components/app-bar/AppBarItemMenu'
import AppBarItemButton from '@/components/app-bar/AppBarItemButton'

export default {
  components: {
    AppBarItemMenu,
    AppBarItemButton,
  },
  data() {
    return {
      notificationItem: {
        icon: 'mdi-bell-outline',
        routerData: {
          name: 'NotificationListView',
          params: { filter: 'received' },
        },
      },
    }
  },
  methods: {
    ...mapMutations(['setNavigationDrawer']),
  },
  computed: {
    ...mapState(['navigationDrawer']),
    ...mapGetters(['isMobile', 'isUserSignedIn']),
  },
}
</script>

<style lang="scss" scoped></style>
