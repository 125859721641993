<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <AppSkeleton
          :condition="!certification || !certificationFile"
          type="button"
        >
          <template v-slot:skeleton-content>
            <v-row>
              <v-col cols="12" :lg="shareAllowed ? 4 : 6">
                <v-btn block outlined @click="downloadFile">
                  <v-icon left> mdi-download </v-icon>
                  {{ $t('download') }}
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4" v-if="shareAllowed">
                <NotificationCreate
                  :attachmentAsset="certification"
                  :subAttachmentAssets="[]"
                  attachmentType="certification"
                />
              </v-col>
              <v-col cols="12" :lg="shareAllowed ? 4 : 6">
                <CertificationDetailPrint
                  :certification="certification"
                  :certificationFile="certificationFile"
                  :showHistory="
                    certification.creator.idNumber ===
                    $store.state.user.idNumber
                  "
                />
              </v-col>
            </v-row>
          </template>
        </AppSkeleton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver'
import { getBlob } from '@/services/storage-service'
import NotificationCreate from '@/components/notification/NotificationCreate'
import CertificationDetailPrint from '@/components/certification/detail/CertificationDetailPrint'
import { mapMutations } from 'vuex'

export default {
  components: {
    NotificationCreate,
    CertificationDetailPrint,
  },
  props: {
    certification: Object,
    certificationFile: Object,
    shareAllowed: Boolean,
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async downloadFile() {
      try {
        const blob = await getBlob(this.certification.file)
        const fileName = this.certification.file.substring(
          this.certification.file.indexOf('/') + 1
        )
        saveAs(blob, fileName)
      } catch (err) {
        const message = this.$t('downloadingFileError')
        this.setSnackbar({ position: 'top', message })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
