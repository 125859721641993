<template>
  <v-container fill-height fluid class="pa-0">
    <v-row no-gutters style="height: 100%">
      <v-col :cols="isMobile ? 12 : 3">
        <v-img
          gradient="to top right, rgba(25,32,72,.9), rgba(25,32,72,.9)"
          height="100%"
          width="100%"
          src="@/assets/login-wallpaper.jpeg"
          content-class="loginImage"
        >
          <v-container fill-height fluid class="pa-5">
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center">
                <v-img
                  contain
                  src="@/assets/app-logo.png"
                  transition="fade-transition"
                  max-width="175"
                />
              </v-col>
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="font-weight-light white--text text-h5"
              >
                {{ $t('loginTitle') }}
              </v-col>
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="font-weight-light font-italic white--text text-body-2"
              >
                {{ $t('loginSubtitle1') }} <br />
                {{ $t('loginSubtitle2') }}
              </v-col>

              <LoginUser v-if="isMobile" />

              <v-col
                v-for="socialNetwork in socials"
                :key="socialNetwork.icon"
                cols="3"
                sm="3"
                md="6"
                lg="3"
                xl="3"
                align="center"
                justify="center"
              >
                <v-btn
                  class="mx-4"
                  icon
                  :href="socialNetwork.url"
                  target="_blank"
                >
                  <v-icon class="white--text">
                    {{ socialNetwork.icon }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-col>
      <v-col v-if="!isMobile" cols="9" align="center" justify="center">
        <LoginUser />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginUser from '@/components/login/LoginUser'
import blockiureSocials from '@/utils/socials.json'
import { mapGetters } from 'vuex'

export default {
  components: {
    LoginUser,
  },
  data() {
    return {
      socials: blockiureSocials,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>

<style lang="scss">
.loginImage {
  width: 100% !important;
}
</style>
