import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import DatetimePicker from 'vuetify-datetime-picker'
import i18n from '@/i18n'

Vue.use(Vuetify)
Vue.use(DatetimePicker)

export default new Vuetify({
  lang: { t: (key, ...params) => i18n.t(key, params) },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#1e355e',
        secondary: '#f26c4f',
        darkColor: '#f26c4f',
      },
    },
  },
})
