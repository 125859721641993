<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <UserCreate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserCreate from '@/components/user/UserCreate'

export default {
  components: {
    UserCreate,
  },
}
</script>
