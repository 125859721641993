<template>
  <v-row align="center" justify="center">
    <v-col cols="12" :lg="7">
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                {{ $t('selectClientTitle') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t('selectClientSubtitle') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="headline mb-1">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                clearable
                @input="$emit('update:certificationClient', '')"
                @click:clear="$emit('update:certificationClient', '')"
                :label="$t('searchClient')"
                single-line
                hide-details
                :disabled="loading"
              />
            </v-list-item-title>
          </v-list-item>

          <AppSkeleton
            :condition="loading"
            type="createCertificationClientList"
          >
            <template v-slot:skeleton-content>
              <v-list flat max-height="272" class="overflow-y-auto">
                <v-list-item-group color="secondary">
                  <v-list-item
                    v-if="search && search.length !== 0"
                    @click="
                      $emit(
                        'update:certificationClient',
                        search === certificationClient ? '' : search
                      )
                    "
                    :class="certificationClient === search ? 'secondary' : ''"
                  >
                    <v-list-item-avatar>
                      <v-avatar color="primary">
                        <v-icon class="secondary--text">
                          mdi-account-outline
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content
                      :class="
                        certificationClient === search ? 'white--text' : ''
                      "
                    >
                      <v-list-item-title>
                        {{ $t('sentToUser') }}
                      </v-list-item-title>
                      <v-list-item-title>
                        {{ search }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!search"
                    @click="
                      $emit(
                        'update:certificationClient',
                        $store.state.user.idNumber === certificationClient
                          ? ''
                          : $store.state.user.idNumber
                      )
                    "
                    :class="
                      certificationClient === $store.state.user.idNumber
                        ? 'secondary'
                        : ''
                    "
                  >
                    <v-list-item-icon class="my-3 mr-4">
                      <AppAvatar
                        :userAvatar="$store.state.user.avatarURL"
                        :userName="$store.state.user.name"
                        :userRole="$store.state.user.roleRef.id"
                        :userCollegiateNumber="
                          $store.state.user.collegiateNumber
                        "
                        avatarColor="primary"
                        initialsColor="secondary"
                        avatarSize="40"
                        iconSize="24"
                      />
                    </v-list-item-icon>
                    <v-list-item-content
                      :class="
                        certificationClient === $store.state.user.idNumber
                          ? 'primary--text'
                          : ''
                      "
                    >
                      <v-list-item-title>
                        {{ $store.state.user.name }} ({{ $t('me') }})
                      </v-list-item-title>
                      <v-list-item-title class="subtitle">
                        {{ $store.state.user.idNumber }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-for="(item, i) in filteredContacts"
                    :key="i"
                    @click="
                      $emit(
                        'update:certificationClient',
                        item.user.idNumber === certificationClient
                          ? ''
                          : item.user.idNumber
                      )
                    "
                    :class="
                      certificationClient === item.user.idNumber
                        ? 'primary'
                        : ''
                    "
                  >
                    <v-list-item-icon class="my-3 mr-4">
                      <AppAvatar
                        :userAvatar="item.user.avatarURL"
                        :userName="item.customName"
                        :userRole="item.user.roleRef.id"
                        :userCollegiateNumber="item.user.collegiateNumber"
                        avatarSize="40"
                        iconSize="24"
                      />
                    </v-list-item-icon>
                    <v-list-item-content
                      :class="
                        certificationClient === item.user.idNumber
                          ? 'white--text'
                          : ''
                      "
                    >
                      <v-list-item-title>
                        {{ item.customName }}
                      </v-list-item-title>
                      <v-list-item-title class="subtitle">
                        {{ item.user.idNumber }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </AppSkeleton>
          <v-row
            v-if="!filteredContacts.length && !search && !loading"
            align="center"
            justify="center"
          >
            <v-col cols="12" align="center" justify="center">
              {{ $t('noContactsAssigned') }}
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { listContacts } from '@/services/contact-service'

export default {
  props: {
    certificationClient: String,
  },
  data() {
    return {
      loading: true,
      contacts: [],
      search: '',
    }
  },
  async mounted() {
    const contactsLisst = await listContacts()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.contacts = contactsLisst
    this.loading = false
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter((item) => {
        if (!this.search) return this.contacts
        return (
          item.customName.toLowerCase().includes(this.search.toLowerCase()) ||
          item.user.idNumber.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
  },
}
</script>
