import i18n from '@/i18n'

export default function (msg) {
  var error
  if (msg.includes('Not signed in')) {
    error = i18n.t('unathenticatedError')
  } else if (msg.includes('Not granted')) {
    error = i18n.t('permissionDeniedError')
  } else if (msg.includes('Some required field not present')) {
    error = i18n.t('missingRequiredFieldsError')
  } else if (msg.includes('You cannot be lawyer and solicitor')) {
    error = i18n.t('notValidRole')
  } else if (msg.includes('Not a valid CIF/NIF')) {
    error = i18n.t('notValidTaxNumberError')
  } else if (msg.includes('Other user exists for that CIF/NIF')) {
    error = i18n.t('inUseTaxNumberError')
  } else if (msg.includes('Other user exists for that ID')) {
    error = i18n.t('inUseIdNumberError')
  } else if (
    msg.includes('The email address is already in use by another account')
  ) {
    error = i18n.t('inUseEmailError')
  } else if (msg.includes('The email address is improperly formatted')) {
    error = i18n.t('invalidEmailError')
  } else if (msg.includes('invalid-email')) {
    error = i18n.t('invalidEmail')
  } else if (msg.includes('user-not-found') || msg.includes('wrong-password')) {
    error = i18n.t('errorLogin')
  } else if (msg.includes('user-disabled')) {
    error = i18n.t('userDisabled')
  } else if (msg.includes('reject status requires rejectReason')) {
    error = i18n.t('rejectProcedureReasonRequired')
  } else {
    error = i18n.t('genericError')
  }

  return error
}
