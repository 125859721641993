const state = {
  redirectPath: '',
  notFound: false,
  navigationDrawer: false,
  snackbar: {},
  dialog: {},
  user: null,
  navigationPages: {},
  receivedNotifications: undefined,
  receivedNotificationsUnsubscribe: () => {},
  issuedNotifications: undefined,
  issuedNotificationsUnsubscribe: () => {},
  localAppVersion: process.env.VUE_APP_VERSION,
  remoteAppVersion: '',
}

export default state
