<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <NotificationList :notifications="notificationsFiltered" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotificationList from '@/components/notification/NotificationList'

export default {
  components: {
    NotificationList,
  },
  computed: {
    notificationsFiltered() {
      var notifications
      if (this.$route.params.filter == 'received') {
        notifications = this.$store.state.receivedNotifications
      } else if (this.$route.params.filter == 'issued') {
        notifications = this.$store.state.issuedNotifications
      } else {
        notifications = []
      }
      return notifications || [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    },
  },
}
</script>
