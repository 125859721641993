<template>
  <v-card class="mx-auto" outlined elevation="20">
    <v-stepper v-model="step">
      <v-stepper-header>
        <template v-for="n in stepsInfo.length">
          <v-spacer :key="`${n}-initial-spacer`" />
          <v-stepper-step
            :step="n"
            :key="`${n}-step`"
            :complete="step > n"
            :editable="step > n"
            edit-icon="mdi-check"
          >
            {{ $t(stepsInfo[n - 1].name) }}
          </v-stepper-step>
          <v-spacer :key="`${n}-final-spacer`" />
          <v-divider v-if="n !== stepsInfo.length" :key="n" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <RecordCreateClientList
              :recordClient.sync="record.client"
              ref="RecordCreateClientList"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <AppUserSummary
              :clientName="client.name"
              :clientAvatar="client.avatarURL"
              :clientIdNumber="client.idNumber"
              :clientRole="client.roleRef.id"
              :clientCollegiateNumber="client.collegiateNumber"
              ref="AppUserSummary"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container>
            <RecordCreateInformation
              :recordSubject.sync="record.subject"
              :recordSubjectType.sync="record.subjectType"
              :recordProcedureNumber.sync="record.procedureNumber"
              :recordJurisdictionType.sync="record.jurisdictionType"
              :recordTrialDate.sync="record.trialDate"
              :recordSolicitor.sync="record.solicitor"
              :recordOpposite.sync="record.opposite"
              :recordOppositeLawyer.sync="record.oppositeLawyer"
              :recordOppositeSolicitor.sync="record.oppositeSolicitor"
              :recordComments.sync="record.comments"
              :client="client"
              ref="RecordCreateInformationEnabled"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-container>
            <RecordCreateInformation
              :recordSubject.sync="record.subject"
              :recordSubjectType.sync="record.subjectType"
              :recordProcedureNumber.sync="record.procedureNumber"
              :recordJurisdictionType.sync="record.jurisdictionType"
              :recordTrialDate.sync="record.trialDate"
              :recordSolicitor.sync="record.solicitor"
              :recordOpposite.sync="record.opposite"
              :recordOppositeLawyer.sync="record.oppositeLawyer"
              :recordOppositeSolicitor.sync="record.oppositeSolicitor"
              :recordComments.sync="record.comments"
              :client="client"
              disabled
              ref="RecordCreateInformationDisabled"
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="step !== 1"
          color="primary"
          text
          :disabled="loading"
          @click="step--"
        >
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="loading || !record.client"
          :loading="loading"
          @click="nextStep"
        >
          {{ $t(stepsInfo[step - 1].nextAction) }}
          <template v-slot:loader>
            <span>{{ $t('creatingRecord') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-card>
</template>

<script>
import { getUserByIdNumber } from '@/services/user-service'
import { createRecord } from '@/services/record-service'
import RecordCreateClientList from '@/components/record/create/RecordCreateClientList'
import AppUserSummary from '@/components/commons/AppUserSummary'
import RecordCreateInformation from '@/components/record/create/RecordCreateInformation'
import { mapMutations } from 'vuex'

export default {
  components: {
    RecordCreateClientList,
    AppUserSummary,
    RecordCreateInformation,
  },
  data() {
    return {
      client: {
        name: '',
        avatarURL: '',
        idNumber: '',
        id: '',
        roleRef: {},
      },
      dialog: { show: false, card: '', method: '', params: undefined },
      stepsInfo: [
        { name: 'client', optional: false, nextAction: 'next' },
        { name: 'confirmClient', optional: false, nextAction: 'next' },
        { name: 'recordData', optional: false, nextAction: 'next' },
        { name: 'confirm', optional: false, nextAction: 'confirm' },
      ],
      step: 1,
      existingUserFlag: true,
      record: {
        subject: '',
        subjectType: '',
        procedureNumber: '',
        jurisdictionType: '',
        trialDate: null,
        solicitor: '',
        opposite: '',
        oppositeLawyer: '',
        oppositeSolicitor: '',
        comments: '',
        client: '',
        creator: this.$store.state.user.id,
      },
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async nextStep() {
      switch (this.step) {
        case 1:
          var client = await getUserByIdNumber(this.record.client)
          if (!client) {
            const message = this.$t('userNotFound')
            this.setSnackbar({ position: 'top', message })
            return
          }

          this.client.id = client.id
          this.client.name = this.getNameHidden(client.name)
          this.client.avatarURL = client.avatarURL
          this.client.idNumber = client.idNumber
          this.client.roleRef = client.roleRef
          this.client.collegiateNumber = client.collegiateNumber
          break

        case 2:
          break

        case 3:
          if (
            !this.$refs.RecordCreateInformationEnabled.$refs.recordForm.validate()
          )
            return
          break

        case 4:
          this.loading = true
          try {
            this.record.client = this.client.id
            var responseCreate = await createRecord(this.record)
            var id = responseCreate.id
            const message = this.$t('creatingRecordSuccess')
            this.setSnackbar({ position: 'top', message, color: 'green' })
            this.loading = false
            this.$router.push({
              name: 'RecordDetailView',
              params: { id },
            })
            return
          } catch (err) {
            this.record.client = this.client.idNumber
            this.loading = false
            const message = this.$t('creatingRecordError')
            this.setSnackbar({ position: 'top', message })
            return
          }

        default:
          break
      }
      this.step++
    },
    getNameHidden(fullName) {
      const splittedName = fullName.split(' ').filter((e) => e)
      var name = splittedName[0]
      var surnames = splittedName.slice(1, splittedName.length)
      return name + ' ' + surnames.map((n) => n[0] + '. ').join('')
    },
  },
}
</script>
