<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <ProcedureCreate v-if="$store.getters.userRole('procedures.create')" />
        <template v-else>
          <h1 class="text-center">{{ $t('procedureNoAccessTitle') }}</h1>
          <div class="text-center text-h6">
            {{ $t('procedureNoAccessMessage') }}
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcedureCreate from '@/components/procedure/ProcedureCreate'

export default {
  components: {
    ProcedureCreate,
  },
}
</script>
