<template>
  <v-card flat>
    <v-container class="py-0">
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-list subheader two-line>
              <v-list-item v-if="type === 'Client'">
                <v-list-item-icon size="60" class="mr-4 my-4">
                  <AppSkeleton
                    :condition="!user.name"
                    type="procedureIssuerAvatar"
                  >
                    <template v-slot:skeleton-content>
                      <AppAvatar
                        :userAvatar="user.avatarURL"
                        :userName="user.name"
                        :userRole="user.roleRef.id"
                        :userCollegiateNumber="user.collegiateNumber"
                        avatarSize="60"
                        iconSize="30"
                      />
                    </template>
                  </AppSkeleton>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    style="white-space: unset"
                    class="primary--text"
                  >
                    <AppSkeleton
                      :condition="!user.association"
                      type="procedureIssuerName"
                    >
                      <template v-slot:skeleton-content>
                        <b> {{ user.name }} </b>
                      </template>
                    </AppSkeleton>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <AppSkeleton
                      :condition="!user.association"
                      type="procedureIssuerShortname"
                    >
                      <template v-slot:skeleton-content>
                        <span>
                          <b>{{ $t('collegiateNumberShort') }}:</b>
                          {{ user.collegiateNumber }}
                        </span>
                      </template>
                    </AppSkeleton>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="type === 'Lawyer'">
                <v-list-item-content class="text-right">
                  <v-list-item-title
                    style="white-space: unset"
                    class="primary--text"
                  >
                    <AppSkeleton
                      :condition="!user.association"
                      type="procedureIssuerName"
                    >
                      <template v-slot:skeleton-content>
                        <b> {{ user.association.name }} </b>
                      </template>
                    </AppSkeleton>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <AppSkeleton
                      :condition="!user.association"
                      type="procedureIssuerShortname"
                    >
                      <template v-slot:skeleton-content>
                        <span> {{ user.association.shortName }} </span>
                      </template>
                    </AppSkeleton>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon size="60" class="ml-4 my-4">
                  <AppSkeleton
                    :condition="!user.name"
                    type="procedureIssuerAvatar"
                  >
                    <template v-slot:skeleton-content>
                      <AppAvatar
                        :userAvatar="user.avatarURL"
                        :userName="user.name"
                        :userRole="user.roleRef.id"
                        :userCollegiateNumber="user.collegiateNumber"
                        avatarSize="60"
                        iconSize="30"
                        isAssociationAvatar
                      />
                    </template>
                  </AppSkeleton>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
    type: String,
  },
}
</script>

<style lang="scss" scoped></style>
