<template>
  <v-card class="mx-auto" outlined elevation="20">
    <v-stepper v-model="step">
      <v-stepper-header>
        <template v-for="n in stepsInfo.length">
          <v-spacer :key="`${n}-initial-spacer`" />
          <v-stepper-step
            :step="n"
            :key="`${n}-step`"
            :complete="step > n"
            :editable="step > n"
            edit-icon="mdi-check"
          >
            {{ $t(stepsInfo[n - 1].name) }}
          </v-stepper-step>
          <v-spacer :key="`${n}-final-spacer`" />
          <v-divider v-if="n !== stepsInfo.length" :key="n" />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <ProcedureCreateSelector
              :procedureType.sync="procedure.type"
              :procedureLegalEntity.sync="procedure.isLegalEntity"
              ref="ProcedureCreateSelector"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <ProcedureCreateInformation
              :procedureFile.sync="procedureFile"
              :procedureLegalName.sync="procedure.legalName"
              :procedureType.sync="procedure.type"
              :procedureEmail.sync="procedure.email"
              :procedureFirstName.sync="procedure.firstName"
              :procedureLastName.sync="procedure.lastName"
              :procedureIdNumber.sync="procedure.idNumber"
              :procedureLegalIdNumber.sync="procedure.legalIdNumber"
              :procedurePrefix.sync="procedure.prefix"
              :procedurePhone.sync="procedure.phone"
              :procedureLegalEntity.sync="procedure.isLegalEntity"
              ref="ProcedureCreateInformationEnabled"
            />
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container>
            <ProcedureCreateSelector
              :procedureType.sync="procedure.type"
              :procedureLegalEntity.sync="procedure.isLegalEntity"
              disabled
              ref="ProcedureCreateSelectorDisabled"
            />
            <ProcedureCreateInformation
              :procedureFile.sync="procedureFile"
              :procedureLegalName.sync="procedure.legalName"
              :procedureType.sync="procedure.type"
              :procedureEmail.sync="procedure.email"
              :procedureFirstName.sync="procedure.firstName"
              :procedureLastName.sync="procedure.lastName"
              :procedureIdNumber.sync="procedure.idNumber"
              :procedureLegalIdNumber.sync="procedure.legalIdNumber"
              :procedurePrefix.sync="procedure.prefix"
              :procedurePhone.sync="procedure.phone"
              :procedureLegalEntity.sync="procedure.isLegalEntity"
              disabled
              ref="ProcedureCreateInformationDisabled"
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          v-if="step !== 1"
          color="primary"
          text
          :disabled="loading"
          @click="step--"
        >
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="loading || !procedure.type"
          :loading="loading"
          @click="nextStep"
        >
          {{ $t(stepsInfo[step - 1].nextAction) }}
          <template v-slot:loader>
            <span>{{ $t('creatingProcedure') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-stepper>
  </v-card>
</template>

<script>
import { uploadProcedure } from '@/services/storage-service'
import { createProcedure, newProcedureId } from '@/services/procedure-service'
import ProcedureCreateSelector from '@/components/procedure/create/ProcedureCreateSelector'
import ProcedureCreateInformation from '@/components/procedure/create/ProcedureCreateInformation'
import { mapMutations } from 'vuex'

export default {
  components: {
    ProcedureCreateSelector,
    ProcedureCreateInformation,
  },
  data() {
    return {
      stepsInfo: [
        { name: 'procedureType', optional: false, nextAction: 'next' },
        { name: 'procedureData', optional: false, nextAction: 'next' },
        { name: 'confirm', optional: false, nextAction: 'confirm' },
      ],
      step: 1,
      existingUserFlag: true,
      procedure: {
        creator: this.$store.state.user.id,
        owner: this.$store.state.user.associationRef.id,
        isLegalEntity: false,
      },
      procedureFile: undefined,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async nextStep() {
      var procedureCreateFormRef = this.$refs.ProcedureCreateInformationEnabled

      var procedureInformationForm =
        procedureCreateFormRef.$refs.procedureInformationForm
      var procedureFileForm = procedureCreateFormRef.$refs.procedureFilePicker

      switch (this.step) {
        case 1:
          if (this.procedure.type !== 'apudActa') {
            this.procedure.isLegalEntity = false
          }
          if (!this.procedure.isLegalEntity) {
            delete this.procedure.legalIdNumber
            delete this.procedure.legalName
            this.procedureFile = undefined
          }
          procedureInformationForm.resetValidation()
          if (procedureFileForm)
            procedureFileForm.$refs.procedureFilePickerInput.resetValidation()
          break

        case 2:
          if (!procedureInformationForm.validate()) return

          if (procedureFileForm)
            if (!procedureFileForm.$refs.procedureFilePickerInput.validate())
              return
          break

        case 3:
          this.loading = true
          try {
            var id = newProcedureId()
            if (this.procedure.isLegalEntity) {
              var responseUpload = await uploadProcedure(this.procedureFile, id)
              this.procedure.file = responseUpload.metadata.fullPath
            }
            await createProcedure(id, this.procedure)
            const message = this.$t('creatingProcedureSuccess')
            this.setSnackbar({ position: 'top', message, color: 'green' })
            this.loading = false
            this.$router.push({
              name: 'ProcedureDetailView',
              params: { id },
            })
            return
          } catch (err) {
            this.loading = false
            const message = this.$t('creatingProcedureError')
            this.setSnackbar({ position: 'top', message })
            return
          }

        default:
          break
      }
      this.step++
    },
  },
}
</script>
