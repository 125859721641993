<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col sm="5" md="5" cols="12">
        <v-row>
          <v-col cols="12">
            <v-card style="overflow: hidden" elevation="2">
              <CertificationDetailFile :certificationFile="certificationFile" />
            </v-card>
          </v-col>
          <v-col v-if="!isMobile" cols="12">
            <CertificationDetailAttributes
              :certificationHash="certificationHash"
              :certificationDate="certification.createdAt"
              :certificationBlockchain="certification.blockchain"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="7" md="7" cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <CertificationDetailTitle :certification="certification" />
          </v-col>
          <v-col v-if="isMobile" cols="12">
            <CertificationDetailAttributes
              :certificationHash="certificationHash"
              :certificationDate="certification.createdAt"
              :certificationBlockchain="certification.blockchain"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="6">
            <CertificationDetailIssuer
              :user="certification.creator"
              type="Client"
            />
          </v-col>
          <v-col cols="12" lg="6">
            <CertificationDetailIssuer
              :user="certification.creator"
              type="Lawyer"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <CertificationDetailActions
              :certification="certification"
              :certificationFile="certificationFile"
              :shareAllowed="isCreator"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <CertificationDetailDescription
              :description="certification.description"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <AssetDetailWhitelist
              :users="certification.whitelist"
              :showUsers="isCreator"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <AssetDetailHistory :history="certification.history" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getFileURL, getFileMetadata } from '@/services/storage-service'
import { getCertification } from '@/services/certification-service'
import CertificationDetailFile from '@/components/certification/detail/CertificationDetailFile'
import CertificationDetailTitle from '@/components/certification/detail/CertificationDetailTitle'
import CertificationDetailAttributes from '@/components/certification/detail/CertificationDetailAttributes'
import CertificationDetailActions from '@/components/certification/detail/CertificationDetailActions'
import CertificationDetailDescription from '@/components/certification/detail/CertificationDetailDescription'
import CertificationDetailIssuer from '@/components/certification/detail/CertificationDetailIssuer'
import AssetDetailWhitelist from '@/components/commons/detail/AssetDetailWhitelist'
import AssetDetailHistory from '@/components/commons/detail/AssetDetailHistory'

export default {
  components: {
    CertificationDetailFile,
    CertificationDetailTitle,
    CertificationDetailAttributes,
    CertificationDetailActions,
    CertificationDetailDescription,
    CertificationDetailIssuer,
    AssetDetailWhitelist,
    AssetDetailHistory,
  },
  async mounted() {
    const certificationGet = await getCertification(this.$route.params.id, this)

    if (!certificationGet) return this.setNotFound(true)

    const url = await getFileURL(certificationGet.file)
    const metadata = await getFileMetadata(certificationGet.file)
    var metadataFile = {
      fullPath: metadata.fullPath,
      contentType: metadata.contentType,
    }

    await new Promise((resolve) => setTimeout(resolve, 1000))
    if (!certificationGet.blockchain) certificationGet.blockchain = {}
    certificationGet.history = this.certification.history
    certificationGet.whitelist = this.certification.whitelist
    this.certification = certificationGet
    this.certificationFile = { url, metadata: metadataFile }
    this.certificationHash = this.certification.hash
  },
  destroyed() {
    this.certification.historyUnsubscribe()
    this.certification.whitelistUnsubscribe()
  },
  data() {
    return {
      certification: {},
      certificationFile: undefined,
      certificationHash: '',
    }
  },
  methods: {
    ...mapMutations(['setNotFound']),
  },
  computed: {
    ...mapGetters(['isMobile']),
    isCreator() {
      if (!this.certification.creator) return
      return (
        this.certification.creator.idNumber === this.$store.state.user.idNumber
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
