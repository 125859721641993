<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container>
          <v-row align="center" justify="center">
            <h1>{{ $t('navigationPage.contacts') }}</h1>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6">
              <ContactList
                :contacts="contacts"
                :loading="loading"
                @reloadElements="loadElements"
              />
            </v-col>
            <v-col cols="12" md="6">
              <GroupList
                :contacts="contacts"
                :groups="groups"
                :loading="loading"
                @reloadElements="loadElements"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listContacts } from '@/services/contact-service'
import { listGroups } from '@/services/group-service'
import ContactList from '@/components/contact/ContactList'
import GroupList from '@/components/group/GroupList'

export default {
  components: {
    ContactList,
    GroupList,
  },
  data() {
    return {
      contacts: [],
      groups: [],
      loading: true,
    }
  },
  async mounted() {
    this.loadElements()
  },
  methods: {
    async loadElements() {
      this.loading = true
      const groupsList = await listGroups()
      const contactsList = await listContacts()
      await new Promise((resolve) => setTimeout(resolve, 500))
      this.groups = groupsList
      this.contacts = contactsList
      this.loading = false
    },
  },
}
</script>
