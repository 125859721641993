<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <CertificationList
          :certifications="certifications"
          :loadingTableData="loadingTableData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { listCertifications } from '@/services/certification-service'
import CertificationList from '@/components/certification/CertificationList'

export default {
  components: {
    CertificationList,
  },
  data() {
    return {
      loadingTableData: true,
      certifications: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    }
  },
  async mounted() {
    const certificationsList = await listCertifications()
    await new Promise((resolve) => setTimeout(resolve, 500))
    this.certifications = certificationsList
    this.loadingTableData = false
  },
}
</script>
