<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <AppSkeleton :condition="!procedure || !procedureFile" type="button">
          <template v-slot:skeleton-content>
            <v-row>
              <v-col
                v-if="!isRejected && isOwner"
                cols="12"
                :lg="isLegalEntity ? 4 : 12"
              >
                <ProcedureDetailChangeStatus
                  :procedure="procedure"
                  :procedureFile="procedureFile"
                  :changeStatusAllowed="changeStatusAllowed"
                  @updateProcedureStatus="
                    (value) => $emit('updateProcedureStatus', value)
                  "
                  @updateProcedureRejectReason="
                    (value) => $emit('updateProcedureRejectReason', value)
                  "
                />
              </v-col>
              <v-col
                v-if="isLegalEntity"
                cols="12"
                :lg="!isRejected && isOwner ? 4 : 6"
              >
                <ProcedureDetailFile :procedureFile="procedureFile" />
              </v-col>
              <v-col
                v-if="isLegalEntity"
                cols="12"
                :lg="!isRejected && isOwner ? 4 : 6"
              >
                <v-btn block outlined @click="downloadFile">
                  <v-icon left> mdi-download </v-icon>
                  {{ $t('download') }}
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="isRejected">
                <v-alert outlined color="red">
                  <b>{{ $t('rejectReasonInput') }} - </b>
                  {{ procedure.rejectReason }}
                </v-alert>
              </v-col>
            </v-row>
          </template>
        </AppSkeleton>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ProcedureDetailChangeStatus from '@/components/procedure/detail/ProcedureDetailChangeStatus'
import ProcedureDetailFile from '@/components/procedure/detail/ProcedureDetailFile'
import { saveAs } from 'file-saver'
import { getBlob } from '@/services/storage-service'

export default {
  props: {
    procedure: Object,
    procedureFile: Object,
    changeStatusAllowed: Boolean,
  },
  components: {
    ProcedureDetailChangeStatus,
    ProcedureDetailFile,
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async downloadFile() {
      try {
        const blob = await getBlob(this.procedure.file)
        const fileName = this.procedure.file.substring(
          this.procedure.file.indexOf('/') + 1
        )
        saveAs(blob, fileName)
      } catch (err) {
        const message = this.$t('downloadingFileError')
        this.setSnackbar({ position: 'top', message })
      }
    },
  },
  computed: {
    isRejected() {
      return this.procedure.status === 'rejected'
    },
    isLegalEntity() {
      return this.procedure.isLegalEntity
    },
    isOwner() {
      if (!this.procedure.ownerRef) return false
      return this.procedure.ownerRef.id === this.$store.state.user.id
    },
  },
}
</script>

<style lang="scss" scoped></style>
