<template>
  <v-card>
    <v-data-table
      :items-per-page="!isMobile ? 10 : visible * 5"
      :headers="headers"
      :items="notifications"
      :search="search"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
      }"
      :hide-default-footer="isMobile"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-card-title>
            {{ $t(`navigationPage.${$route.params.filter}Notifications`) }}
          </v-card-title>
          <template v-if="!isMobile">
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
          </template>
        </v-toolbar>
        <template v-if="isMobile">
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
          </v-toolbar>
        </template>
      </template>
      <template v-slot:item="{ item: notification, isMobile: mobileVersion }">
        <tr
          v-if="!mobileVersion"
          @click="openDocumentDetail(notification)"
          :class="notification.read ? undefined : 'font-weight-bold'"
        >
          <td>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="heading"
            >
              <template v-slot:skeleton-content>
                <v-icon small color="secondary">
                  {{ notification.read ? undefined : 'mdi-circle' }}
                </v-icon>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="heading"
            >
              <template v-slot:skeleton-content>
                <span v-if="notification.attachmentType == 'certification'">
                  {{ notification.attachment.name }}
                </span>
                <span v-else-if="notification.attachmentType == 'record'">
                  {{ notification.attachment.subject }}
                </span>
                <span v-else> </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="heading"
            >
              <template v-slot:skeleton-content>
                <span v-if="notification.attachmentType == 'certification'">
                  {{ $t(notification.attachmentType) }} →
                  {{ notification.attachment.type }}
                </span>
                <span v-else-if="notification.attachmentType == 'record'">
                  {{ $t(notification.attachmentType) }} →
                  {{ notification.attachment.subjectType }}
                </span>
                <span v-else> </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="heading"
            >
              <template v-slot:skeleton-content>
                <span>
                  {{ notification[notificationField].idNumber }}
                  -
                  {{ notification[notificationField].name }}
                </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="heading"
            >
              <template v-slot:skeleton-content>
                <span>
                  {{ $d(notification.createdAt, 'i18nDateAndHourSeconds') }}
                </span>
              </template>
            </AppSkeleton>
          </td>
        </tr>
        <tr v-else :class="notification.read ? undefined : 'font-weight-bold'">
          <v-list subheader two-line>
            <AppSkeleton
              :condition="!Object.keys(notification).length"
              type="notificationListMobile"
            >
              <template v-slot:skeleton-content>
                <v-list-item @click="openDocumentDetail(notification)">
                  <v-list-item-avatar>
                    <v-icon class="primary lighten-1" dark>
                      mdi-bell-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="
                        notification.attachmentType == 'certification' &&
                        notification.attachment.name.length > 35
                      "
                    >
                      {{
                        notification.attachment.name.substring(0, 32) + '...'
                      }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-else-if="
                        notification.attachmentType == 'certification' &&
                        notification.attachment.name.length <= 35
                      "
                    >
                      {{ notification.attachment.name }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-else-if="
                        notification.attachmentType == 'record' &&
                        notification.attachment.subject.length > 35
                      "
                    >
                      {{
                        notification.attachment.subject.substring(0, 32) + '...'
                      }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-else-if="
                        notification.attachmentType == 'record' &&
                        notification.attachment.subject.length <= 35
                      "
                    >
                      {{ notification.attachment.subject }}
                    </v-list-item-title>
                    <v-list-item-title v-else> </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="notification.attachmentType == 'certification'"
                    >
                      {{ $t(notification.attachmentType) }} →
                      {{ notification.attachment.type }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-else-if="notification.attachmentType == 'record'"
                    >
                      {{ $t(notification.attachmentType) }} →
                      {{ notification.attachment.subjectType }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else> </v-list-item-subtitle>
                    <v-list-item-title class="primary--text lighten-3">
                      {{ $t(notificationField) }}:
                      {{ notification[notificationField].idNumber }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $d(notification.createdAt, 'i18nDateAndHourSeconds') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon small color="secondary">
                      {{ notification.read ? undefined : 'mdi-circle' }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </AppSkeleton>
          </v-list>
        </tr>
      </template>
      <template v-slot:footer v-if="isMobile">
        <v-list-item v-if="visible * 5 <= notifications.length">
          <v-btn
            block
            rounded
            @click="visible++"
            color="primary"
            :disabled="loadingTableData"
          >
            <v-icon left dark>mdi-chevron-down</v-icon>
            {{ $t('showMore') }}
          </v-btn>
        </v-list-item>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { readNotification } from '@/services/notification-service'

export default {
  props: {
    notifications: Array,
  },
  data() {
    return {
      visible: 1,
      search: '',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async openDocumentDetail(value) {
      if (!Object.keys(value).length) return

      const isReceived = this.$route.params?.filter === 'received'
      const isIssued = this.$route.params?.filter === 'issued'
      const destinationPath =
        value.attachmentType == 'certification'
          ? {
              name: 'CertificationDetailView',
              params: { id: value.attachment.id },
            }
          : value.attachmentType == 'record'
          ? {
              name: 'RecordDetailView',
              params: { id: value.attachment.id },
            }
          : undefined
      if (isIssued) {
        this.$router.push(destinationPath)
      } else if (isReceived && value.read) {
        this.$router.push(destinationPath)
      } else {
        try {
          await readNotification(value)
          this.$router.push(destinationPath)
        } catch (err) {
          const message = this.$t('readingNotificationError')
          this.setSnackbar({ position: 'bottom', message })
        }
      }
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    loadingTableData() {
      return this.notifications.some((value) => !Object.keys(value).length)
    },
    headers() {
      return [
        {
          text: this.$t('read'),
          value: 'read',
        },
        {
          text: this.$t('subject'),
          value:
            this.attachmentType == 'certification'
              ? 'attachment.name'
              : this.attachmentType == 'record'
              ? 'attachment.subject'
              : '',
        },
        {
          text: this.$t('type'),
          value:
            this.attachmentType == 'certification'
              ? 'attachment.type'
              : this.attachmentType == 'record'
              ? 'attachment.subjectType'
              : '',
        },
        {
          text: this.$t(this.notificationField),
          value: this.notificationField + '.idNumber',
        },
        {
          text: this.$t('notificationCreatedAt'),
          value: 'createdAt',
        },
      ]
    },
    notificationField() {
      return { issued: 'receiver', received: 'issuer' }[
        this.$route.params.filter
      ]
    },
  },
}
</script>

<style lang="scss"></style>
