<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <RecordDetail />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecordDetail from '@/components/record/RecordDetail'

export default {
  components: {
    RecordDetail,
  },
}
</script>

<style lang="scss" scoped></style>
