import store from '@/store/index'
import {
  Firestore,
  GetDocFromReference,
  UsersContactsDoc,
  UsersGroupsCollection,
  UsersGroupsDoc,
} from '@/firebase-exports'

export async function createGroup(group) {
  const contactsRef = group.contactsRef.map((contactId) =>
    UsersContactsDoc(store.state.user.id, contactId)
  )
  const groupInput = { ...group, contactsRef }
  delete groupInput.contacts
  return await Firestore.addDoc(
    UsersGroupsCollection(store.state.user.id),
    groupInput
  )
}

export async function listGroups() {
  const q = Firestore.query(
    UsersGroupsCollection(store.state.user.id),
    Firestore.orderBy('customName', 'asc')
  )
  const querySnapshot = await Firestore.getDocs(q)
  return await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const document = doc.data()
      document.contacts = await Promise.all(
        document.contactsRef.map(async (contactRef) => {
          const contact = await GetDocFromReference(contactRef)
          return { id: contact.id, contactRef, contact }
        })
      )
      return { id: doc.id, ...document }
    })
  )
}

export async function updateGroup(id, data) {
  data.contactsRef = data.contactsRef.map((contactId) =>
    UsersContactsDoc(store.state.user.id, contactId)
  )
  return await Firestore.updateDoc(
    UsersGroupsDoc(store.state.user.id, id),
    data
  )
}

export async function deleteGroup(id) {
  return await Firestore.deleteDoc(UsersGroupsDoc(store.state.user.id, id))
}
