<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!isMobile"
        class="ma-3"
        fab
        small
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <v-fab-transition v-else>
        <v-btn
          fab
          fixed
          right
          bottom
          color="secondary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('newRecordFile') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="fileRecordForm" v-model="validForm" lazy-validation>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-autocomplete
                ref="fileTypeInput"
                v-model="fileToCreate.type"
                :items="fileTypes"
                :label="$t('recordFileType')"
                prepend-icon="mdi-file-document-outline"
                :rules="typesRules"
                :disabled="loading"
                @keyup="fixFileTypeInput"
                :search-input.sync="searchFileType"
              >
                <template v-slot:no-data>
                  <div style="display: none" />
                </template>
                <template v-slot:append-item>
                  <v-list-item v-if="fileTypeAvailable" @click="addNewFileType">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left> mdi-plus </v-icon>
                        {{ $t('recordFileTypeNotFound') }}
                        "{{ createFileTypeButtonText }}"
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="recordFile"
                :rules="fileRules"
                :label="$t('recordFileInput')"
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="fileToCreate.description"
                :rules="descriptionRules"
                :label="$t('descriptionInput')"
                prepend-icon="mdi-information-outline"
                rows="2"
                counter="140"
                :disabled="loading"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading"
          color="blue darken-1"
          text
          @click="
            dialog = false
            $refs.fileRecordForm.reset()
          "
          :disabled="loading"
        >
          {{ $t('close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="createRecordFile()"
          :loading="loading"
          :disabled="loading || !validForm"
        >
          {{ $t('add') }}
          <template v-slot:loader>
            <span>{{ $t('addingRecordFile') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapMutations, mapGetters } from 'vuex'
import {
  listRecordFileTypes,
  createCustomRecordFileType,
} from '@/services/record-type-service'
import { uploadRecordFile, recordFileExists } from '@/services/storage-service'
import { createRecordFile, newRecordFileId } from '@/services/record-service'
import file2md5 from 'file2md5'

export default {
  props: {
    client: Object,
    record: Object,
    disabled: Boolean,
  },
  data: () => ({
    dialog: false,
    loading: false,
    validForm: true,
    searchFileType: '',
    fileTypes: [],
    typesRules: [rules.required],
    fileRules: [rules.file],
    descriptionRules: [rules.required, rules.maxCharacters(140)],
    fileToCreate: {},
    recordFile: undefined,
  }),
  async mounted() {
    this.fileTypes = await listRecordFileTypes()
  },
  computed: {
    ...mapGetters(['isMobile']),
    createFileTypeButtonText() {
      const maxLength = this.isMobile ? 10 : 25
      if (this.searchFileType) {
        return this.searchFileType.length > maxLength
          ? this.searchFileType.slice(0, maxLength) + '...'
          : this.searchFileType
      } else {
        return ''
      }
    },
    fileTypeAvailable() {
      if (!this.searchFileType) return false
      const index = this.fileTypes.findIndex((element) => {
        return element.toLowerCase() === this.searchFileType.toLowerCase()
      })
      return index === -1
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    fixFileTypeInput() {
      if (!this.searchFileType) return
      this.searchFileType = this.searchFileType.replace(/\s+/g, ' ')
    },
    async addNewFileType() {
      if (this.fileTypeAvailable) {
        try {
          await createCustomRecordFileType({ name: this.searchFileType })
          this.fileTypes = await listRecordFileTypes()
          this.fileToCreate.type = this.searchFileType
          this.$refs.fileTypeInput.blur()
        } catch (err) {
          const message = this.$t('creatingRecordFileTypeError')
          this.setSnackbar({ position: 'top', message })
        }
      } else {
        const message = this.$t('recordFileTypeExists')
        this.setSnackbar({ position: 'bottom', message })
      }
    },
    async createRecordFile() {
      if (!this.$refs.fileRecordForm.validate()) return

      this.loading = true
      this.fileToCreate.record = this.record.id
      this.fileToCreate.client = this.client.id
      this.fileToCreate.creator = this.$store.state.user.id
      this.fileToCreate.name = this.recordFile.name
      try {
        if (await recordFileExists(this.record.id, this.fileToCreate.name)) {
          const message = this.$t('recordFileAlreadyExists')
          this.setSnackbar({ position: 'bottom', message })
          this.loading = false
          return
        }

        var responseUpload = await uploadRecordFile(
          this.recordFile,
          this.record.id
        )
        this.fileToCreate.file = responseUpload.metadata.fullPath
        this.fileToCreate.hash = await file2md5(this.recordFile)

        var fileId = newRecordFileId(this.record.id)
        await createRecordFile(this.record.id, fileId, this.fileToCreate)

        this.$emit('record-file-created', fileId)
        this.dialog = false
        this.loading = false
        this.$refs.fileRecordForm.reset()
      } catch (error) {
        const message = this.$t('recordFileCreatingError')
        this.setSnackbar({ position: 'top', message })
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
