<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col>
        <NotFound />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotFound from '@/components/not-found/NotFound'

export default {
  components: {
    NotFound,
  },
}
</script>

<style lang="scss" scoped></style>
