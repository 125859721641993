<template>
  <AppSplash :condition="waitingAuthUser">
    <template v-slot:app-content>
      <v-app>
        <AppDialog :dialog="$store.state.dialog" />
        <AppBar v-if="$route.name !== 'LoginView'" />
        <AppNavigationDrawer />
        <v-main :style="{ 'padding-right': isMobile ? '0px' : undefined }">
          <NotFoundView v-if="$store.state.notFound" />
          <router-view v-else :key="$route.fullPath" />
        </v-main>
        <AppFooter v-if="$route.name !== 'LoginView'" />
        <AppSnackbar :snackbar="$store.state.snackbar" />
      </v-app>
    </template>
  </AppSplash>
</template>

<script>
import AppBar from '@/components/app-bar/AppBar'
import AppNavigationDrawer from '@/components/app-navigation-drawer/AppNavigationDrawer'
import NotFoundView from '@/views/NotFoundView'
import AppFooter from '@/components/app-footer/AppFooter'
import AppSplash from '@/components/commons/AppSplash'
import AppSnackbar from '@/components/commons/AppSnackbar'
import AppDialog from '@/components/commons/AppDialog'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Auth } from '@/firebase-exports'

export default {
  components: {
    AppBar,
    AppNavigationDrawer,
    NotFoundView,
    AppFooter,
    AppSplash,
    AppSnackbar,
    AppDialog,
  },
  watch: {
    waitingAuthUser: function () {
      this.setTabTitle()
    },
    '$i18n.locale': function () {
      this.setTabTitle()
    },
    '$route.fullPath': function () {
      this.setTabTitle()
    },
    '$store.state.notFound': function () {
      this.setTabTitle()
    },
    '$store.state.redirectPath': function (newValue) {
      this.$router.push(newValue)
    },
  },
  data() {
    return {
      waitingAuthUser: true,
      timeOut: null,
    }
  },
  methods: {
    ...mapMutations(['setDialog', 'resetNotifications']),
    ...mapActions(['logout', 'fetchUser']),
    userUnsubscribe() {},
    setTabTitle() {
      var tabTitle = this.$store.state.notFound
        ? this.$t('notFound')
        : this.$t(`tabTitle.${this.$route.meta.tabTitle}`)
      if (tabTitle && !this.waitingAuthUser) tabTitle += ' - ' + 'Blockiure'
      else tabTitle = 'Blockiure'
      document.title = tabTitle
    },
    setSessionTimeOut(time) {
      this.clearSessionTimeOut()
      this.timeOut = setTimeout(async () => {
        const title = this.$t('sessionExpired')
        const message = this.$t('sessionExpiredMessage')
        this.setDialog({ title, message })
        await this.expireSession()
      }, time)
    },
    clearSessionTimeOut() {
      if (this.timeOut) {
        clearInterval(this.timeOut)
        this.timeOut = null
      }
    },
    async expireSession() {
      await this.logout()
      this.$router.push({ name: 'LoginView' })
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
  async beforeCreate() {
    Auth.onAuthStateChanged(Auth.getAuth(), async (user) => {
      if (user) {
        const authTime = (await user.getIdTokenResult()).claims.auth_time * 1000
        const sessionDuration = 1000 * 60 * 60 * 12 // 12 hour session
        if (Date.now() > authTime + sessionDuration) await this.expireSession()
        else {
          const remaining = authTime + sessionDuration - Date.now()
          const twoHours = 1000 * 60 * 60 * 2
          this.setSessionTimeOut(remaining > twoHours ? remaining : twoHours)

          this.userUnsubscribe = await this.fetchUser()
          await new Promise((resolve) => setTimeout(resolve, 2000))

          if (
            this.$route.name === 'LoginView' ||
            this.$route.fullPath === '/'
          ) {
            const rolePath = this.$store.state.user.role.loginRedirect
            const finalRoute = this.$route.query.redirect || { path: rolePath }
            this.$router.replace(finalRoute)
          }
        }
      } else {
        this.clearSessionTimeOut()
        this.resetNotifications()
        if (this.$store.state.user && this.$store.state.user.roleUnsubscribe) {
          this.$store.state.user.roleUnsubscribe()
        }
        if (
          this.$store.state.user &&
          this.$store.state.user.parentUnsubscribe
        ) {
          this.$store.state.user.parentUnsubscribe()
        }
        if (
          this.$store.state.user &&
          this.$store.state.user.parentRoleUnsubscribe
        ) {
          this.$store.state.user.parentRoleUnsubscribe()
        }
        if (this.$store.state.receivedNotificationsUnsubscribe) {
          this.$store.state.receivedNotificationsUnsubscribe()
        }
        if (this.$store.state.issuedNotificationsUnsubscribe) {
          this.$store.state.issuedNotificationsUnsubscribe()
        }
        this.userUnsubscribe()
        await new Promise((resolve) => setTimeout(resolve, 2000))

        if (
          this.$route.name !== 'LoginView' &&
          this.$route.name !== 'BlockchainVerifierView'
        ) {
          const currentPath = this.$route.fullPath
          const redirect = currentPath !== '/' ? currentPath : undefined
          this.$router.push({ name: 'LoginView', query: { redirect } })
        }
      }
      this.waitingAuthUser = false
    })
  },
  async created() {
    this.setTabTitle()
  },
}
</script>

<style lang="scss">
.subtitle {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0333333333em;
}
</style>
