<template>
  <v-card>
    <v-data-table
      :items-per-page="!isMobile ? 10 : visible * 5"
      :headers="headers"
      :items="procedures"
      :search="search"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
      }"
      :hide-default-footer="isMobile"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-card-title>
            {{ $t('navigationPage.procedures') }}
          </v-card-title>
          <template v-if="!isMobile">
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="$router.push({ name: 'ProcedureCreateView' })"
            >
              {{ $t('procedureCreate') }}
            </v-btn>
          </template>
        </v-toolbar>
        <template v-if="isMobile">
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              :disabled="loadingTableData"
            />
          </v-toolbar>
        </template>
      </template>
      <template v-slot:item="{ item: procedure, isMobile: mobileVersion }">
        <tr v-if="!mobileVersion" @click="openDocumentDetail(procedure)">
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ procedure.name }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ procedure.idNumber }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span> {{ $t(`procedureTypes.${procedure.type}`) }} </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <v-chip :color="statusColor[procedure.status] + ' lighten-4'">
                  {{ $t(`procedureStatus.${procedure.status}`) }}
                </v-chip>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  {{ procedure.creator.name }}
                </span>
              </template>
            </AppSkeleton>
          </td>
          <td>
            <AppSkeleton :condition="loadingTableData" type="heading">
              <template v-slot:skeleton-content>
                <span>
                  {{ $d(procedure.createdAt, 'i18nDateAndHourSeconds') }}
                </span>
              </template>
            </AppSkeleton>
          </td>
        </tr>
        <tr v-else>
          <v-list subheader two-line>
            <AppSkeleton
              :condition="loadingTableData"
              type="procedureListMobile"
            >
              <template v-slot:skeleton-content>
                <v-list-item @click="openDocumentDetail(procedure)">
                  <v-list-item-avatar>
                    <v-icon class="primary lighten-1" dark>
                      mdi-file-document-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="primary--text lighten-3">
                        {{ procedure.idNumber }}
                      </span>
                      -
                      <template v-if="procedure.name.length > 20">
                        {{ procedure.name.substring(0, 20) + '...' }}
                      </template>
                      <template v-else>
                        {{ procedure.name }}
                      </template>
                    </v-list-item-title>
                    <v-list-item-title class="primary--text lighten-3">
                      {{ $t(`procedureTypes.${procedure.type}`) }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :class="
                        statusColor[procedure.status] + '--text lighten-4'
                      "
                    >
                      {{ $t(`procedureStatus.${procedure.status}`) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ $d(procedure.createdAt, 'i18nDateAndHourSeconds') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AppSkeleton>
          </v-list>
        </tr>
      </template>
      <template v-slot:footer v-if="isMobile">
        <v-list-item v-if="visible * 5 <= procedures.length">
          <v-btn
            block
            rounded
            @click="visible++"
            color="primary"
            :disabled="loadingTableData"
          >
            <v-icon left dark>mdi-chevron-down</v-icon>
            {{ $t('showMore') }}
          </v-btn>
        </v-list-item>
      </template>
    </v-data-table>

    <v-fab-transition>
      <v-btn
        v-if="isMobile && !$store.state.navigationDrawer"
        @click="$router.push({ name: 'ProcedureCreateView' })"
        color="secondary"
        fab
        fixed
        right
        bottom
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import procedureStatusColor from '@/utils/procedureStatus.json'

export default {
  props: {
    loadingTableData: Boolean,
    procedures: Array,
  },
  data() {
    return {
      visible: 1,
      search: '',
      statusColor: procedureStatusColor,
    }
  },
  methods: {
    openDocumentDetail(value) {
      if (!Object.keys(value).length) return

      this.$router.push({
        name: 'ProcedureDetailView',
        params: { id: value.id },
      })
    },
  },
  computed: {
    ...mapGetters(['isMobile']),
    headers() {
      return [
        {
          text: this.$t('procedureName'),
          value: 'name',
        },
        {
          text: this.$t('procedureIdNumber'),
          value: 'name',
        },
        {
          text: this.$t('procedureType'),
          value: 'type',
        },
        {
          text: this.$t('status'),
          value: 'status',
        },
        {
          text: this.$t('creator'),
          value: 'creator.idNumber',
        },
        {
          text: this.$t('procedureCreatedAt'),
          value: 'createdAt',
        },
      ]
    },
  },
}
</script>

<style lang="scss"></style>
