<template>
  <v-dialog v-model="showDialog" persistent max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-btn block outlined v-bind="attrs" v-on="on">
        <v-icon left> mdi-arrow-left-right-bold-outline </v-icon>
        {{ $t('changeProcedureStatus') }}
      </v-btn>
    </template>
    <v-card v-if="$store.getters.userRole('procedures.update')">
      <v-card-title class="justify-center">
        {{ $t('changeProcedureStatus') }}
      </v-card-title>
      <v-card-text class="text-center">
        <v-form ref="procedureDialogForm" v-model="validProcedureStatusForm">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :value="$t(`procedureStatus.${procedure.status}`)"
                  :label="$t('sourceProcedureStatus')"
                  disabled
                />
              </v-col>
              <v-col cols="12">
                <v-icon> mdi-arrow-down-bold-outline </v-icon>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="destinationStatus"
                  :items="filteredDestinations"
                  :item-text="(type) => $t(`procedureStatus.${type.value}`)"
                  :label="$t('destinationProcedureStatus')"
                  :rules="procedureStatusRules"
                  :menu-props="{ bottom: true, offsetY: true }"
                />
              </v-col>
              <v-fade-transition mode="out-in">
                <v-col cols="12" v-if="destinationStatus === 'rejected'">
                  <v-textarea
                    v-model="rejectReason"
                    :rules="rejectReasonRules"
                    :label="$t('rejectReasonInput')"
                  />
                </v-col>
              </v-fade-transition>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          class="red lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading"
          @click="closeDialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="green lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading || !validProcedureStatusForm"
          :loading="loading"
          @click="changeStatus"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="justify-center text-h5">
        {{ $t('procedureNoAccessMessage') }}
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" class="text-left mb-0">
          {{ $t('procedureNoAccessTitle') }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false">
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { mapMutations } from 'vuex'
import { updateProcedure } from '@/services/procedure-service'
import getErrorText from '@/utils/get-error-text'

export default {
  props: {
    procedure: Object,
    procedureFile: Object,
    changeStatusAllowed: Boolean,
  },
  data() {
    return {
      procedureStatus: [
        { value: 'created' },
        { value: 'inProgress' },
        { value: 'accepted' },
        { value: 'rejected' },
      ],
      showDialog: false,
      loading: false,
      procedureStatusRules: [rules.required],
      rejectReasonRules: [rules.required],
      validProcedureStatusForm: true,
      destinationStatus: '',
      rejectReason: '',
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    async changeStatus() {
      if (!this.$refs.procedureDialogForm.validate()) return

      this.loading = true
      try {
        await updateProcedure(this.procedure.id, {
          status: this.destinationStatus,
          rejectReason: this.rejectReason,
        })
        this.$emit('updateProcedureStatus', this.destinationStatus)
        if (this.destinationStatus === 'rejected') {
          this.$emit('updateProcedureRejectReason', this.rejectReason)
        }
      } catch (err) {
        const message = getErrorText(err.message)
        this.setSnackbar({ position: 'top', message })
      }
      this.closeDialog()
      this.loading = false
    },
    closeDialog() {
      this.showDialog = false
      this.$refs.procedureDialogForm.resetValidation()
      this.destinationStatus = ''
      this.rejectReason = ''
    },
  },
  computed: {
    filteredDestinations() {
      return this.procedureStatus.filter(
        (item) => item.value !== this.procedure.status
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
