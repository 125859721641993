<template>
  <v-dialog :value="showDialog" persistent max-width="450">
    <v-card>
      <v-card-title class="justify-center">
        {{ $t(actionMessages.title[actionDialog]) }}
      </v-card-title>
      <v-card-text class="text-center">
        <template v-if="actionDialog === 'delete'">
          <h2>{{ groupData.customName }}</h2>
          <h3 v-if="groupData.contacts">
            {{ $tc('contactCount', groupData.contacts.length) }}
          </h3>
        </template>
        <v-form
          v-else
          ref="groupDialogForm"
          v-model="validGroup"
          lazy-validation
        >
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  v-model="groupData.customName"
                  :label="$t('groupName')"
                  :rules="nameRules"
                  prepend-icon="mdi-folder-text-outline"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="groupData.contacts"
                  :items="contacts"
                  chips
                  color="primary"
                  :label="$t('contacts')"
                  item-value="id"
                  :rules="contactsRules"
                  prepend-icon="mdi-account-group-outline"
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeContactGroup(data.item.id)"
                    >
                      {{ data.item.user.idNumber }} -
                      {{ data.item.customName }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-icon class="my-3 mr-4">
                        <AppAvatar
                          :userAvatar="data.item.user.avatarURL"
                          :userName="data.item.customName"
                          :userRole="data.item.user.roleRef.id"
                          :userCollegiateNumber="
                            data.item.user.collegiateNumber
                          "
                          avatarSize="40"
                          iconSize="24"
                        />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.customName }}
                        </v-list-item-title>
                        <v-list-item-title class="subtitle">
                          {{ data.item.user.idNumber }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="headline justify-center">
        <v-btn
          class="red lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="loading"
          @click="
            if ($refs.groupDialogForm) $refs.groupDialogForm.reset()
            $emit('closeGroupDialog')
          "
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="green lighten-4"
          outlined
          rounded
          text
          width="150px"
          :disabled="!validGroup || loading"
          :loading="loading"
          @click="performAction"
        >
          {{ $t('confirm') }}
          <template v-slot:loader>
            <v-progress-circular indeterminate size="20" width="2" />
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules'
import { createGroup, deleteGroup, updateGroup } from '@/services/group-service'
import { mapMutations } from 'vuex'

export default {
  props: {
    actionDialog: String,
    contacts: Array,
    showDialog: Boolean,
    groups: Array,
    groupProp: Object,
  },
  data() {
    return {
      validGroup: true,
      loading: false,
      contactsRules: [rules.required, rules.groupContactsNotEmpty],
      actionMessages: {
        title: {
          create: 'addGroup',
          update: 'editGroup',
          delete: 'removementGroupConfirmation',
        },
        success: {
          create: 'creatingGroupSuccess',
          update: 'editingGroupSuccess',
          delete: 'deletingGroupSuccess',
        },
        error: {
          create: 'creatingGroupError',
          update: 'editingGroupError',
          delete: 'deletingGroupError',
        },
      },
      groupData: {},
    }
  },
  watch: {
    groupProp: function (newValue) {
      this.groupData = { ...newValue }
    },
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    removeContactGroup(itemId) {
      const index = this.groupData.contacts.findIndex(
        (contactId) => contactId === itemId
      )
      if (index >= 0) this.groupData.contacts.splice(index, 1)
    },
    async performAction() {
      const groupDialogForm = this.$refs.groupDialogForm
      if (groupDialogForm && !groupDialogForm.validate()) return

      this.loading = true
      try {
        const groupId = this.groupProp.id
        const groupInput = {
          customName: this.groupData.customName,
          contactsRef: this.groupData.contacts,
        }

        if (this.actionDialog === 'create') {
          await createGroup(groupInput)
        } else if (this.actionDialog === 'update') {
          await updateGroup(groupId, groupInput)
        } else if (this.actionDialog === 'delete') {
          await deleteGroup(groupId)
        }

        this.$emit('confirmGroupDialog')
        if (groupDialogForm) groupDialogForm.reset()

        const message = this.$t(this.actionMessages.success[this.actionDialog])
        this.setSnackbar({ position: 'top', message, color: 'green' })
      } catch (err) {
        const message = this.$t(this.actionMessages.error[this.actionDialog])
        this.setSnackbar({ position: 'top', message })
      }
      this.loading = false
    },
  },
  computed: {
    nameRules() {
      return [
        rules.required,
        rules.groupNameNotExists(
          this.groups,
          this.actionDialog === 'update' ? this.groupProp.customName : undefined
        ),
      ]
    },
  },
}
</script>
