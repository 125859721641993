<template>
  <v-footer dark padless>
    <v-card class="flex primary white--text text-center" flat tile>
      <AppFooterVersion v-if="false" />
      <template v-if="isMobile">
        <v-card-text>
          <strong> {{ $t('blockiureFooter') }} </strong>
          <v-spacer />
          <AppFooterSocials />
        </v-card-text>
        <v-divider class="ma-0" />
        <v-card-text class="py-2 white--text text-center" v-html="company" />
      </template>
      <v-container v-else fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="4" align="start">
            <v-card-text class="pa-0" v-html="company" />
          </v-col>
          <v-col cols="12" md="4" align="center">
            <v-card class="flex primary white--text" flat tile>
              <v-card-text class="pa-0">
                <strong> {{ $t('blockiureFooter') }} </strong>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" align="end">
            <AppFooterSocials />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import AppFooterSocials from '@/components/app-footer/AppFooterSocials'
import AppFooterVersion from '@/components/app-footer/AppFooterVersion'
import blockiureSocials from '@/utils/socials.json'
import { mapGetters } from 'vuex'

export default {
  components: {
    AppFooterSocials,
    AppFooterVersion,
  },
  data() {
    return {
      company: `
        ${new Date().getFullYear()} —
        <strong>Blockiure ©</strong> —
        v${this.$store.state.localAppVersion}`,
      socials: blockiureSocials,
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
  },
}
</script>
