<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-container>
          <v-row align="center" justify="center">
            <h1>{{ $t('settings') }}</h1>
          </v-row>
          <v-row align="center" justify="center">
            <h3 class="headline mb-1">{{ $t('manageProfileInfo') }}</h3>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" :md="canManageCustomCertificationTypes ? 6 : 4">
              <SettingsAvatar ref="SettingsAvatar" />
            </v-col>
            <v-col cols="12" :md="canManageCustomCertificationTypes ? 6 : 4">
              <SettingsInformation ref="SettingsInformation" />
            </v-col>
            <v-col cols="12" :md="canManageCustomCertificationTypes ? 6 : 4">
              <SettingsPassword ref="SettingsPassword" />
            </v-col>
            <v-col cols="12" md="6" v-if="canManageCustomCertificationTypes">
              <SettingsCustomCertificationTypes
                ref="SettingsCustomCertificationTypes"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="canManageCustomRecordSubjectTypes">
              <SettingsCustomRecordSubjectTypes
                ref="SettingsCustomRecordSubjectTypes"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="canManageCustomRecordFileTypes">
              <SettingsCustomRecordFileTypes
                ref="SettingsCustomRecordFileTypes"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsPassword from '@/components/settings/SettingsPassword'
import SettingsInformation from '@/components/settings/SettingsInformation'
import SettingsAvatar from '@/components/settings/SettingsAvatar'
import SettingsCustomCertificationTypes from '@/components/settings/SettingsCustomCertificationTypes'
import SettingsCustomRecordSubjectTypes from '@/components/settings/SettingsCustomRecordSubjectTypes'
import SettingsCustomRecordFileTypes from '@/components/settings/SettingsCustomRecordFileTypes'

export default {
  components: {
    SettingsPassword,
    SettingsInformation,
    SettingsAvatar,
    SettingsCustomCertificationTypes,
    SettingsCustomRecordSubjectTypes,
    SettingsCustomRecordFileTypes,
  },
  watch: {
    '$i18n.locale': function () {
      this.$refs.SettingsPassword.$refs.changePasswordForm.resetValidation()
      this.$refs.SettingsInformation.$refs.informationForm.resetValidation()
      this.$refs.SettingsCustomCertificationTypes.$refs.typesForm.resetValidation()
      this.$refs.SettingsCustomRecordSubjectTypes.$refs.typesForm.resetValidation()
      this.$refs.SettingsCustomRecordFileTypes.$refs.typesForm.resetValidation()
    },
  },
  computed: {
    canManageCustomCertificationTypes() {
      return this.$store.state.user.role.users.certificationTypes.create
    },
    canManageCustomRecordSubjectTypes() {
      return this.$store.state.user.role.users.recordSubjectTypes.create
    },
    canManageCustomRecordFileTypes() {
      return this.$store.state.user.role.users.recordFileTypes.create
    },
  },
}
</script>
