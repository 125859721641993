<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <CertificationCreate />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CertificationCreate from '@/components/certification/CertificationCreate'

export default {
  components: {
    CertificationCreate,
  },
}
</script>
