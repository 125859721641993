// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import * as Firebase from 'firebase/app'
import * as Analytics from 'firebase/analytics'
import * as Auth from 'firebase/auth'
import * as Firestore from 'firebase/firestore'
import * as Storage from 'firebase/storage'
import * as Functions from 'firebase/functions'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const App = Firebase.initializeApp(firebaseConfig)
const Database = Firestore.getFirestore(App)

// Initialize Functions References
const functions = Functions.getFunctions(App, 'europe-west3')
const HelpFormFunction = Functions.httpsCallable(functions, 'help-form')
const UsersCreateFunction = Functions.httpsCallable(functions, 'users-create')
const UsersUpdateEmailFunction = Functions.httpsCallable(
  functions,
  'users-update-email'
)
const UsersUpdateInfoFunction = Functions.httpsCallable(
  functions,
  'users-update-info'
)
const UsersDisableFunction = Functions.httpsCallable(functions, 'users-disable')

// Initialize Firestore References
const CertificationTypesCollection = Firestore.collection(
  Database,
  'certificationTypes'
)

const CertificationsCollection = Firestore.collection(
  Database,
  'certifications'
)
const CertificationsDoc = (certificationId) =>
  Firestore.doc(Database, 'certifications', certificationId)

const RecordSubjectTypesCollection = Firestore.collection(
  Database,
  'recordSubjectTypes'
)

const RecordJurisdictionTypesCollection = Firestore.collection(
  Database,
  'recordJurisdictionTypes'
)

const RecordFileTypesCollection = Firestore.collection(
  Database,
  'recordFileTypes'
)

const RecordFilesDoc = (recordId, fileId) =>
  Firestore.doc(Database, 'records', recordId, 'files', fileId)

const RecordsCollection = Firestore.collection(Database, 'records')
const RecordsDoc = (recordId) => Firestore.doc(Database, 'records', recordId)
const CertificationsWhitelistCollection = (certificationId) =>
  Firestore.collection(Database, 'certifications', certificationId, 'whitelist')
const RecordFilesCollection = (recordId) =>
  Firestore.collection(Database, 'records', recordId, 'files')
const RecordFilesWhitelistCollection = (recordId, fileId) =>
  Firestore.collection(
    Database,
    'records',
    recordId,
    'files',
    fileId,
    'whitelist'
  )
const RecordFilesHistoryCollection = (recordId, fileId) =>
  Firestore.collection(
    Database,
    'records',
    recordId,
    'files',
    fileId,
    'history'
  )

const CertificationsWhitelistDoc = (certificationId, whitelistId) =>
  Firestore.doc(
    Database,
    'certifications',
    certificationId,
    'whitelist',
    whitelistId
  )

const RecordsWhitelistDoc = (recordId, whitelistId) =>
  Firestore.doc(Database, 'records', recordId, 'whitelist', whitelistId)

const RecordFilesWhitelistDoc = (recordId, fileId, whitelistId) =>
  Firestore.doc(
    Database,
    'records',
    recordId,
    'files',
    fileId,
    'whitelist',
    whitelistId
  )

const CertificationsHistoryCollection = (certificationId) =>
  Firestore.collection(Database, 'certifications', certificationId, 'history')
const CertificationsHistoryDoc = (certificationId, historyId) =>
  Firestore.doc(
    Database,
    'certifications',
    certificationId,
    'history',
    historyId
  )

const AssociationsCollection = (filter) =>
  Firestore.collection(Database, `${filter}Associations`)
const AssociationsDoc = (filter, associationId) =>
  Firestore.doc(Database, `${filter}Associations`, associationId)

const SettingsCollection = Firestore.collection(Database, 'settings')
const MetadataVersion = Firestore.doc(Database, 'settings', 'webAppMetadata')

const NotificationsCollection = Firestore.collection(Database, 'notifications')
const NotificationsDoc = (notificationId) =>
  Firestore.doc(Database, 'notifications', notificationId)

const ProceduresCollection = Firestore.collection(Database, 'procedures')
const ProceduresDoc = (procedureId) =>
  Firestore.doc(Database, 'procedures', procedureId)

const RolesCollection = Firestore.collection(Database, 'roles')
const RolesDoc = (roleId) => Firestore.doc(Database, 'roles', roleId)

const UsersCollection = Firestore.collection(Database, 'users')
const UsersDoc = (userId) => Firestore.doc(Database, 'users', userId)

const UsersContactsCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'contacts')
const UsersContactsDoc = (userId, contactId) =>
  Firestore.doc(Database, 'users', userId, 'contacts', contactId)

const UsersGroupsCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'groups')
const UsersGroupsDoc = (userId, groupId) =>
  Firestore.doc(Database, 'users', userId, 'groups', groupId)

const UsersCertificationTypesCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'certificationTypes')
const UsersCertificationTypesDoc = (userId, certificationTypeId) =>
  Firestore.doc(
    Database,
    'users',
    userId,
    'certificationTypes',
    certificationTypeId
  )

const UsersRecordSubjectTypesCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'recordSubjectTypes')
const UsersRecordSubjectTypesDoc = (userId, recordSubjectTypeId) =>
  Firestore.doc(
    Database,
    'users',
    userId,
    'recordSubjectTypes',
    recordSubjectTypeId
  )

const UsersRecordFileTypesCollection = (userId) =>
  Firestore.collection(Database, 'users', userId, 'recordFileTypes')
const UsersRecordFileTypesDoc = (userId, recordFilesTypeId) =>
  Firestore.doc(Database, 'users', userId, 'recordFileTypes', recordFilesTypeId)
const UsersRolesFinalDoc = (userId) =>
  Firestore.doc(Database, 'users', userId, 'rolesFinal', 'value')

const GetDocFromReference = async (reference) => {
  if (!reference) return
  const doc = await Firestore.getDoc(reference)
  return { id: doc.id, ...doc.data() }
}

export {
  Firebase,
  App,
  Analytics,
  Auth,
  Firestore,
  Database,
  Storage,
  Functions,
  HelpFormFunction,
  UsersCreateFunction,
  UsersUpdateEmailFunction,
  UsersUpdateInfoFunction,
  UsersDisableFunction,
  // Collections
  CertificationTypesCollection,
  CertificationsCollection,
  RecordsCollection,
  CertificationsDoc,
  RecordSubjectTypesCollection,
  RecordJurisdictionTypesCollection,
  RecordFileTypesCollection,
  RecordsDoc,
  RecordsWhitelistDoc,
  RecordFilesDoc,
  RecordFilesWhitelistDoc,
  RecordFilesWhitelistCollection,
  RecordFilesHistoryCollection,
  RecordFilesCollection,
  CertificationsWhitelistCollection,
  CertificationsWhitelistDoc,
  CertificationsHistoryCollection,
  CertificationsHistoryDoc,
  AssociationsCollection,
  AssociationsDoc,
  SettingsCollection,
  MetadataVersion,
  NotificationsCollection,
  NotificationsDoc,
  ProceduresCollection,
  ProceduresDoc,
  RolesCollection,
  RolesDoc,
  UsersCollection,
  UsersDoc,
  UsersContactsCollection,
  UsersContactsDoc,
  UsersGroupsCollection,
  UsersGroupsDoc,
  UsersCertificationTypesCollection,
  UsersCertificationTypesDoc,
  UsersRecordSubjectTypesCollection,
  UsersRecordSubjectTypesDoc,
  UsersRecordFileTypesCollection,
  UsersRecordFileTypesDoc,
  UsersRolesFinalDoc,
  GetDocFromReference,
}
